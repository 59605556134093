import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TokenvalidatorService {

  constructor() { }

  IsTokenExpired() {
    const token = this.getValueFromLocalStorage('AuthToken');

    const jwtHelper = new JwtHelperService();

    if (token !== null && token !== '' && token !== undefined) {
      const isExpired = jwtHelper.isTokenExpired(token);

      if (isExpired === true) {
        this.clearToken();
      }

      return isExpired;
    } else {
      return true;
    }
  }

  getValueFromLocalStorage(data: any) {
    return (localStorage.getItem(data) || '');
  }

  clearToken() {
    localStorage.removeItem("AuthToken")
  }
}
