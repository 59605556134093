import { Injectable } from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';


@Injectable({
  providedIn: 'root'
})
export class NetworkProvider {
  networkStatus: any;
  networkListener: PluginListenerHandle | any;

  async ngOnInit() {
    this.defaultNetworkStatus();
  }

  defaultNetworkStatus(){
    localStorage.setItem("NetworkStatus", "true");
    this.getNetworkStatus();
  }

  async getNetworkStatus() {
    this.networkListener = Network.addListener('networkStatusChange', status => {      
      this.networkStatus = status;
      localStorage.setItem("NetworkStatus", JSON.stringify(this.networkStatus.connected));
    });    
    
    this.networkStatus = await Network.getStatus();
    localStorage.setItem("NetworkStatus", JSON.stringify(this.networkStatus.connected));
  }

  ngOnDestroy() {
    this.networkListener.remove();
  }
}