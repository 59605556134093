import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AddAthletePage } from "./add-athlete.page";
import { AuthguardGuard } from "../providers/authguard.guard";

const routes: Routes = [
  {
    path: "",
    component: AddAthletePage,
    canActivate: [AuthguardGuard],
  },
  {
    path: "addathlete",
    component: AddAthletePage,
    canActivate: [AuthguardGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddAthletePageRoutingModule { }
