import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FeaturePackagePageRoutingModule } from './feature-package-routing.module';

import { FeaturePackagePage } from './feature-package.page';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule, DateTimePickerModule, DateRangePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { MultiSelectModule, AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { HomeFooterModule } from '../home-footer/home-footer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FeaturePackagePageRoutingModule,
    DatePickerModule,
    MultiSelectModule,
    CheckBoxModule,
    GridAllModule,
    TabModule,
    NumericTextBoxModule,
    DateTimePickerModule,
    ScheduleAllModule,
    AutoCompleteModule,
    ChartAllModule,
    DateRangePickerAllModule,
    HomeFooterModule
  ],
  declarations: [FeaturePackagePage]
})
export class FeaturePackagePageModule {}
