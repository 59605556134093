import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthguardGuard } from "../providers/authguard.guard";

import { AccountSettingsPage } from "./account-settings.page";

const routes: Routes = [
  {
    path: "",
    component: AccountSettingsPage,
    canActivate: [AuthguardGuard],
  },
  {
    path: "settings",
    component: AccountSettingsPage,
    canActivate: [AuthguardGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountSettingsPageRoutingModule { }
