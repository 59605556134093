import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { PrivacyPolicyPage } from '../privacy-policy/privacy-policy.page';
import { TermsAndConditionsPage } from '../terms-and-conditions/terms-and-conditions.page';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.css']
})
export class CommonFooterComponent {
  
  isMobileView: boolean = false;
  webSize: boolean = false;
  
  constructor(public modalCtrl: ModalController, private platform : Platform){
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      this.isMobileView = false;
      this.webSize = true;
    }
    else {
      this.isMobileView = true;
    }
    if (this.webSize == true) {
      if (window.matchMedia('(max-width: 800px)').matches) {
        this.isMobileView = true;
      }
    }
  }

  async clickTermsandconditions() {
    let modal = await this.modalCtrl.create({ component: TermsAndConditionsPage });
    modal.present();
  }

  async clickPrivacyPolicy() {
    let modal = await this.modalCtrl.create({ component: PrivacyPolicyPage });
    modal.present();
  }
}
