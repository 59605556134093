import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { VideoJSComponent } from './video-js/video-js.component';
import { VideoJSPlayerComponent } from './video-jsplayer/video-jsplayer.component';

@NgModule({
    declarations: [
        VideoJSComponent,
        VideoJSPlayerComponent
    ],
    imports: [IonicModule, CommonModule],
    exports: [
        VideoJSComponent,
        VideoJSPlayerComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
