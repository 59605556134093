import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  public isNotificationUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setHeaderTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isSubscribed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAutoSync: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isProfileImageUpdated: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public isGalleryRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isGalleryReload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCloseUpdateProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCloseUpdateAthleteInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isImageNotChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
  public isGoBackToLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isGoToTap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  public setCroppedImageSource: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public closeImageCropper: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  public isShowImageFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUpdatingVideos : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
