// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model {
  background: white !important;
  --background: white;
  padding: 16px;
}

p {
  font-size: 13px;
  line-height: 20px;
  color: black;
}

.toolbar {
  background: white;
  --background: white;
  position: fixed;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/terms-and-conditions/terms-and-conditions.page.scss","webpack://./../../../IPostSports%20Website/git/Ipostsports-web/src/app/terms-and-conditions/terms-and-conditions.page.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,mBAAA;EACA,aAAA;ACCJ;;ADEA;EACI,eAAA;EACA,iBAAA;EACA,YAAA;ACCJ;;ADEA;EACI,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;ACCJ","sourcesContent":[".model {\n    background: white !important;\n    --background: white;\n    padding: 16px;\n}\n\np{\n    font-size: 13px;\n    line-height: 20px;\n    color: black;\n}\n\n.toolbar{\n    background: white;\n    --background: white;\n    position: fixed;\n    color: black;\n}",".model {\n  background: white !important;\n  --background: white;\n  padding: 16px;\n}\n\np {\n  font-size: 13px;\n  line-height: 20px;\n  color: black;\n}\n\n.toolbar {\n  background: white;\n  --background: white;\n  position: fixed;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
