import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BirthDateProvider {

  constructor() { }

  ValidateBirthDate(birthdate: any, isParent: any) {
    if (birthdate != null) {
      let today = new Date();
      let currentYear: number = today.getFullYear();
      let currentMonth: number = today.getMonth();
      let currentDay: number = today.getDate();
      let diff;
      if (isParent) {
        diff = currentYear - 5;
      }
      else {
        diff = currentYear - 13;
      }
      let maxDate = new Date(diff, currentMonth, currentDay);
      let selectedDate = birthdate;
      if (selectedDate.getTime() < maxDate.getTime()) {
        //selectedDate is lesser than maxDate
        return false;
      }
      else if (selectedDate.getTime() > maxDate.getTime()) {
        //selectedDate is greater than maxDate
        return true;
      }
      else {
        //Both are equal
        return false;
      }
    }
    else {
      return true;
    }
  }
}
