import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { DataSharingService } from 'src/app/providers/data-sharing.service';

import videojs from 'video.js'
@Component({
  selector: 'videojsplayer',
  templateUrl: './video-jsplayer.component.html',
  styleUrls: ['./video-jsplayer.component.scss'],
})
export class VideoJSPlayerComponent implements OnInit {

  // reference to the element itself, we use this to access events and methods
  private _elementRef: ElementRef | any;

  // index to create unique ID for component
  @Input() idx: string = '';

  // video asset url
  @Input() url: any;

  // declare player var
  private player: any;

  // constructor initializes our declared vars
  constructor(elementRef: ElementRef, public nav: NavController,
    private dataSharingService : DataSharingService, private router : Router) {
    this.url = false;
    this.player = false;
  }

  ngOnInit() { }

  // use ngAfterViewInit to make sure we initialize the videojs element
  // after the component template itself has been rendered
  ngAfterViewInit() {

    let zoom = 1;
    let properties = ['transform', 'WebkitTransform', 'MozTransform',
      'msTransform', 'OTransform'];
    let zoomproperty = properties[0];
    let stage: any = document.getElementsByClassName('videoPlacementContainer')[0];
    for (var i = 0, j = properties.length; i < j; i++) {
      if (typeof stage.style[properties[i]] !== 'undefined') {
        zoomproperty = properties[i];
        break;
      }
    }
    var _thisEvent = this;
    // ID with which to access the template's video element
    let el = 'video_' + this.idx;

    // setup the player via the unique element ID
    this.player = videojs((document.getElementById(this.idx) as any), {
      controlBar: {
        fullscreenToggle: false // to hide full screen button
      }
    }, function (this: any) {

      // Store the video object
      var myPlayer = this as any, id = myPlayer.id() as any;
      // Make up an aspect ratio
      var aspectRatio = 264 / 640;
      myPlayer.player_.autoplay = true;
      // internal method to handle a window resize event to adjust the video player
      function resizeVideoJS() {
        var parentElementObject = document.getElementById(id);
        if (parentElementObject != null && parentElementObject != undefined) {
          var width = (document.getElementById(id) as any).parentElement.offsetWidth;
          myPlayer.width(width);
          myPlayer.height(width * aspectRatio);
        }
      }

      var Button = videojs.getComponent('Button') as any;
      var MyButton = videojs.extend(Button, {
        constructor: function (this : any) {
          Button.apply(this as any, arguments as any);
          this.controlText("Exit");
          this.addClass("vjs-close-button");
          /* initialize your button */
        },
        handleClick: function (this: any) {
          var videoFullScreen = document.getElementsByClassName("vjs-fullscreen");
          if (videoFullScreen && videoFullScreen.length) {
            document.exitFullscreen();
          }
          else {
            this.url = window.location.href;
            if(this.url != undefined && this.url.split('/').length == 4)
            if(this.url.split('/')[3] == "player"){
              _thisEvent.dataSharingService.isGalleryRefresh.next(true);
              _thisEvent.router.navigate(['gallery'], { replaceUrl: true });
            }
          }
        }
      });

      videojs.registerComponent('MyButton', MyButton);
      (myPlayer.getChild('controlBar') as any).addChild('myButton', {});
      var Zoomin = videojs.extend(Button, {
        constructor: function (this: any) {
          Button.apply(this as any, arguments as any);
          this.controlText("Zoomin");
          this.addClass("vjs-add-button");
          /* initialize your button */
        },
        handleClick: function () {
          if (zoom < 2) {
            zoom = zoom + 0.2;
            let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
            element.style[zoomproperty] = 'scale(' + zoom + ') rotate(0deg)';
          }
        }
      });

      videojs.registerComponent('Zoomin', Zoomin);
      myPlayer.getChild('controlBar').addChild('Zoomin', {});

      var Zoomout = videojs.extend(Button, {
        constructor: function (this : any) {
          Button.apply(this as any, arguments as any);
          this.controlText("Zoomout");
          this.addClass("vjs-zoomout-button");
          /* initialize your button */
        },
        handleClick: function () {
          if (zoom > 1) {
            zoom = zoom - 0.2;
            let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
            element.style[zoomproperty] = 'scale(' + zoom + ') rotate(0deg)';
          }
        }
      });

      videojs.registerComponent('Zoomout', Zoomout);
      (myPlayer.getChild('controlBar') as any).addChild('Zoomout', {});

      myPlayer.on("play", function (this:any)  //function to play the video again//
      {
        let lengthBar = myPlayer.controlBar.children_.length;
        for (var i = 0; i < lengthBar; i++) {
          let myButtonValue = myPlayer.controlBar.children_[i] as any;
          if (myButtonValue.name_ == "MyButton" || myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
            myButtonValue.el_.style.display = "block";
          }
        }
        if (myPlayer.isFullscreen()) {
          this.controlBar.el_.style.bottom = '0%';
        }
        else {
          if (window.matchMedia('(min-width: 768px)').matches && !((window.innerWidth == 1366 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 1366) || (window.innerWidth == 768 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 768))) {
            this.controlBar.el_.style.bottom = '0%'; // For tabs
          }
          else {
            this.controlBar.el_.style.bottom = '0%';
          }
        }
        myPlayer.controlBar.show(); //shows your controls//
        myPlayer.bigPlayButton.hide();
      });

      myPlayer.on('pause', function () {
      });

      myPlayer.on("ended", function () {
        myPlayer.currentTime(0);
        myPlayer.controlBar.show(); //shows your controls//
        myPlayer.bigPlayButton.show(); //shows your play button//        
      });

      myPlayer.on('fullscreenchange', function (this : any) {
        zoom = 1;
        // do stuff...
        if (myPlayer.isFullscreen()) {
          this.controlBar.el_.style.bottom = '0%';
          let lengthBar = myPlayer.controlBar.children_.length;
          for (var i = 0; i < lengthBar; i++) {
            let myButtonValue: any = myPlayer.controlBar.children_[i];
            if (myButtonValue.name_ == "MyButton") {
              myButtonValue.el_.style.display = "none";
            }
            if (myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
              myButtonValue.el_.style.display = "block";
            }
          }
        }
        else {
          let positionFound: any = document.getElementById(myPlayer.id_) as any;
          if (positionFound.style.position == "fixed") {
            if (window.matchMedia('(min-width: 768px)').matches && !((window.innerWidth == 1366 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 1366) || (window.innerWidth == 768 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 768))) {
              this.controlBar.el_.style.bottom = '0%'; // For tabs
            }
            else {
              this.controlBar.el_.style.bottom = '0%';
            }
            let lengthBar = myPlayer.controlBar.children_.length;
            for (var i = 0; i < lengthBar; i++) {
              let myButtonValue: any = myPlayer.controlBar.children_[i];
              if (myButtonValue.name_ == "MyButton" || myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
                myButtonValue.el_.style.display = "block";
              }
            }
          }
          else {
            this.controlBar.el_.style.bottom = '0%';
            let lengthBar = myPlayer.controlBar.children_.length;
            for (var i = 0; i < lengthBar; i++) {
              let myButtonValue: any = myPlayer.controlBar.children_[i];
              if (myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
                myButtonValue.el_.style.display = "none";
                let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
                element.style.transform = null;
                // zoom = 0;
              }
            }
          }
          // }
        }
      });
      // Initialize resizeVideoJS()
      resizeVideoJS();

      // Then on resize call resizeVideoJS()
      window.onresize = resizeVideoJS;
      document.addEventListener("pause", () => {
        myPlayer.pause();
      }, false);
    });
  }

}