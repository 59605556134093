import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  HostListener,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import {
  AlertController,
  ModalController,
  NavParams,
  Platform,
} from "@ionic/angular";
import { NumericTextBoxComponent } from "@syncfusion/ej2-angular-inputs";
import {
  IAthlete,
  IBbSbDetails,
  IHeightWeight,
} from "../interfaces/account-interface";
import { AccountService } from "../providers/account-service.service";
import { HttpProvider } from "../providers/http-provider.service";
import { AlertService } from "../providers/alert-service.service";
import { SpinnerService } from "../providers/spinner-service.service";
import { DataSharingService } from "../providers/data-sharing.service";
import { ToasterService } from "../providers/toaster-service.service";
import { BirthDateProvider } from "../providers/birth-date.service";
import {
  CheckBoxSelectionService,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-angular-calendars";
declare var window: any;

@Component({
  selector: "app-update-athlete-info",
  templateUrl: "./update-athlete-info.page.html",
  styleUrls: ["./update-athlete-info.page.scss"],
  providers: [CheckBoxSelectionService],
})
export class UpdateAthleteInfoPage implements OnInit {
  public sportsPositionCodesFields: Object = {
    text: "position_name",
    value: "sports_position_code",
  };
  public popHeight: string = "350px";
  public popupWidth: string = "590px";
  public mode?: string;
  public maxSelection: number = 2;
  public isMobileView: boolean = false;
  public webSize: boolean = false;
  heightWeight: IHeightWeight = {
    athlete_id: 0,
    entry_date: new Date(),
    height_feet: 0,
    height_inches: 0,
    weight_pounds: 0,
    height: "",
    weight: "",
  };
  bbSbDetails: IBbSbDetails = {
    athlete_id: 0,
    bats: "",
    throws: "",
    league1_sports_position_code: 0,
    league1_sports_focus_code: 0,
    league2_sports_position_code: 0,
    league2_sports_focus_code: 0,
    league1_type: "",
    league1_name: "",
    league2_type: "",
    league2_name: "",
    grade_level: "",
    affiliate_id: 0,
    name: "",
    sports_postion_name: "",
    positionCode: "",
    sports_position_club_name: "",
    TrainingCenter: "",
    league1PositionName: "",
    league2PositionName: "",
    league3PositionName: "",
    league4PositionName: "",
    league5PositionName: "",
    league6PositionName: "",
    league7PositionName: "",
    league8PositionName: "",
    league9PositionName: "",
    league1PositionShortName: "",
    league2PositionShortName: "",
    league3PositionShortName: "",
    league4PositionShortName: "",
    league5PositionShortName: "",
    league6PositionShortName: "",
    league7PositionShortName: "",
    league8PositionShortName: "",
    league9PositionShortName: "",
    league3_sports_position_code: 0,
    league4_sports_position_code: 0,
    league5_sports_position_code: 0,
    league6_sports_position_code: 0,
    league7_sports_position_code: 0,
    league8_sports_position_code: 0,
    league9_sports_position_code: 0,
  };
  athleteProfile: IAthlete = {
    athlete_id: 0,
    account_id: 0,
    first_name: "",
    last_name: "",
    birthdate: new Date(),
    gender: "",
    address1: "",
    address2: "",
    city: "",
    athlete_state_code: "",
    zipcode: 0,
    zip4: 0,
    area_code_phone: 0,
    phone: 0,
    email: "",
    parent_first_name: "",
    parent_last_name: "",
    parent_address1: "",
    parent_address2: "",
    parent_city: "",
    parent_state_code: "",
    parent_zipcode: 0,
    parent_zip4: 0,
    parent_email: "",
    parent_area_code_phone: 0,
    parent_phone: 0,
    school_team_name: "",
    graduation_year: null,
    school_name: "",
    grade_level: "",
    parentzipCode: "",
    athletezipCode: "",
    parentPhoneNumber: "",
    athletePhoneNumber: "",
    isparent: false,
    birthDate: "",
  };
  heightWeightCopy: IHeightWeight = {
    athlete_id: 0,
    entry_date: new Date(),
    height_feet: 0,
    height_inches: 0,
    weight_pounds: 0,
    height: "",
    weight: "",
  };
  isMinWeightValid: Boolean = false;
  isMaxWeightValid: Boolean = false;
  isleague2typevalid: Boolean = false;
  isleague2namevalid: Boolean = false;
  isEmailValid: Boolean = false;
  maximumLength: number = 40;
  maximumSelectionLength: number;
  sportspositioncode: any = [];
  affiliate_id: any;
  club_position_code: any;
  graduation_year: any;
  maxDate: Date = new Date();
  isPositionValid: Boolean = false;
  positionCode: string = "";
  overallPositionList: any;
  positionList: any;
  profileImageSource: string = "";
  isProfileImageChanged: Boolean;
  removeButtonStyle: string;
  profileImageCopy: string = "";
  profileImage: FormData = new FormData();
  loading: any;
  accountId: any;
  accountType: any;
  athleteProfileList: any;
  submitted = false;
  height: any;
  isSelectionExceeds: boolean = false;
  invalidBirthDate: boolean = false;
  public heightMask = [/[2-7]/, "'", " ", /[0-9]/, /\d/, '"'];
  isMobile: Boolean = false;
  isFirstChange: Boolean = true;
  @ViewChild("fileinput") fileInput: any;
  @ViewChild("sports_position_codes")
  public sportsPositionObj: any;
  setGridPaddingBottom: any;
  @ViewChild("weights")
  public weightObj: NumericTextBoxComponent | any;
  imageData: any;
  isShowImageCropper: boolean = false;
  isParent: boolean = false;
  isValidData: boolean = false;
  isFirstNameValid: boolean = false;
  isLastNameValid: boolean = false;
  isSelectedPositionInValid: boolean = false;
  athleteId: any;
  selectAllText: string | any;
  @ViewChild("graduationyear")
  public graduationYear: DatePickerComponent | any;
  @ViewChild("birthDate")
  public birthDate: DatePickerComponent | any;
  @ViewChild("sports_position_codes")
  public sportsPositionCodes: MultiSelectComponent | any;

  constructor(
    public accountService: AccountService,
    public httpProvider: HttpProvider,
    public spinner: SpinnerService,
    public alertService: AlertService,
    private alertCtrl: AlertController,
    public platform: Platform,
    private router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private toaster: ToasterService,
    private cdRef: ChangeDetectorRef,
    private birthDateProvider: BirthDateProvider,
    public navParams: NavParams,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.route.queryParams.subscribe((params) => {
      if ((this.router.getCurrentNavigation() as any).extras.state) {
        let data = (this.router.getCurrentNavigation() as any).extras.state;
        console.log(data);

        if (data) {
          if (data.pageName == "view-profile") {
            this.accountId = data.accountId;
            this.athleteId = data.athelteId;
            this.profileImageSource = data.profileImageSource;
            this.profileImageCopy = this.profileImageSource;
          }
        }
      }
    });
    // if (navParams != null) {
    //   this.athleteId =
    //     navParams.data[0] != undefined ? navParams.data[0] : null;
    //   this.profileImageSource =
    //     navParams.data[1] != undefined ? navParams.data[1] : null;
    //   this.profileImageCopy = this.profileImageSource;
    // }
    this.dataSharingService.setCroppedImageSource.subscribe((value) => {
      if (value) {
        this.profileImageSource = value;
        var fileBlob = this.dataURItoBlob(value);
        var fileId = this.blobToFile(fileBlob, "my-image.png");
        if (fileId) {
          let formData: FormData = new FormData();
          formData.append("uploadFile", fileId, "modifiedCanvas.png");
          this.profileImage = formData;
        }
        this.isShowImageCropper = false;
        this.cdRef.detectChanges();
      }
    });

    this.dataSharingService.closeImageCropper.subscribe((value) => {
      if (value) {
        this.isShowImageCropper = false;
        this.cdRef.detectChanges();
      }
    });

    if (
      (this.platform.is("android") || this.platform.is("ios")) &&
      !this.platform.is("tablet")
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    if (this.platform.is("android")) {
      this.setGridPaddingBottom = "10px";
    } else {
      this.setGridPaddingBottom = "0px";
    }

    if (this.webSize == true) {
      if (window.matchMedia("(max-width: 800px)").matches) {
        this.isMobileView = true;
      }
    }

    this.maximumSelectionLength = 2;
    this.removeButtonStyle = "none";
    this.maximumLength = 40;
    this.GetAllLevels();
    this.GetAllSportsPositions();
    this.GetAllAffiliates();
    this.isProfileImageChanged = false;
    this.overallPositionList = [];
    this.sportspositioncode = [];
    var accountType = localStorage.getItem("accounttype") || "";
    let today = new Date();
    let currentYear: number = today.getFullYear();
    let currentMonth: number = today.getMonth();
    let currentDay: number = today.getDate();
    if (accountType == "5") {
      let diff: any = currentYear - 5;
      this.maxDate = new Date(diff, currentMonth, currentDay);
      this.isParent = true;
    } else if (accountType == "6") {
      let diff: any = currentYear - 13;
      this.maxDate = new Date(diff, currentMonth, currentDay);
      this.isParent = false;
    }
  }
  public positionfields: Object = {
    text: "position_name",
    value: "sports_position_code",
    sportscode: "sports_focus_code",
  };
  public batsList: Object[] = [
    { Id: "R", Text: "Right" },
    { Id: "L", Text: "Left" },
    { Id: "B", Text: "Both" },
  ];
  public batsFields: Object = { text: "Text", value: "Id" };

  public throwsList: Object[] = [
    { Id: "R", Text: "Right" },
    { Id: "L", Text: "Left" },
  ];
  public throwsFields: Object = { text: "Text", value: "Id" };

  public heightFeetList: Object[] = [
    { Id: 2, Text: "2" },
    { Id: 3, Text: "3" },
    { Id: 4, Text: "4" },
    { Id: 5, Text: "5" },
    { Id: 6, Text: "6" },
    { Id: 7, Text: "7" },
  ];

  public heightFields: Object = { text: "Text", value: "Id" };

  public heightInchesList: Object[] = [
    { Id: 0, Text: "00" },
    { Id: 1, Text: "01" },
    { Id: 2, Text: "02" },
    { Id: 3, Text: "03" },
    { Id: 4, Text: "04" },
    { Id: 5, Text: "05" },
    { Id: 6, Text: "06" },
    { Id: 7, Text: "07" },
    { Id: 8, Text: "08" },
    { Id: 9, Text: "09" },
    { Id: 10, Text: "10" },
    { Id: 11, Text: "11" },
  ];

  public levelList: string[] | any; // = ['Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', '7th Grade', '8th Grade', 'Freshman', 'Sophomore', 'Junior', 'Senior'];
  public levelWaterMark: string = "Select a Level";
  public heightInchesFields: Object = { text: "Text", value: "Id" };
  public positionWaterMark: string = "Select a Position";
  public batsWaterMark: string = "Select a Bats";
  public throwsWaterMark: string = "Select a Throws";

  public leagueList: string[] = ["City", "Club", "School", "Hitting Network"];
  public leagueWatermark: string = "Select a League";

  public trainingCenterList: any;
  public trainingfields: Object = { text: "name", value: "affiliate_id" };
  public trainingWaterMark: string = "Select a Sports Complex";

  @ViewChild("weightpound") firstNameElement: ElementRef | any;
  ngOnInit() {
    this.mode = "CheckBox";
    this.selectAllText = "Select All";
    this.sportspositioncode = [];
    let isFirstAlert: boolean = true;
    let selectionLimit = this.isSelectionExceeds;
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  OnOpenPosition(e: any) {
    let select: any = window.getSelection();
    select.removeAllRanges();
  }

  OnchangePosition(e: any) {
    let select: any = window.getSelection();
    select.removeAllRanges();
    if (this.isFirstChange) {
      this.sportsPositionObj.inputElement.setAttribute("readonly", "true");
      this.isFirstChange = false;
    }
  }

  OnSelectPosition(e: any) {
    setTimeout(function () {
      let select: any = window.getSelection();
      select.removeAllRanges();
    }, 100);
  }

  weightfocus(e: any) {
    setTimeout(() => {
      let len = this.weightObj.element.value
        ? this.weightObj.element.value.length
        : 0;
      this.weightObj.element.setSelectionRange(len, len);
    }, 1);
  }

  dismiss() {
    this.modalCtrl.dismiss();
    let navigationExtras: NavigationExtras = {
      state: {
        athlete_id: this.athleteProfile.athlete_id,
      },
      replaceUrl: true,
    };

    this.router.navigate(["features"], navigationExtras);
  }

  SaveProfileInfo(form: NgForm) {
    this.submitted = true;
    let weight = +this.heightWeight.weight_pounds;
    if (weight < 50 && weight != 0) {
      this.isMinWeightValid = true;
      this.isMaxWeightValid = false;
      var weightPound = document.getElementById("weightpound") as any;
      weightPound.scrollIntoView({ behavior: "smooth" });
    } else if (weight > 400 && weight != 0) {
      this.isMinWeightValid = false;
      this.isMaxWeightValid = true;
      var weightPoundValue = document.getElementById("weightpound") as any;
      weightPoundValue.scrollIntoView({ behavior: "smooth" });
    } else {
      this.isMinWeightValid = false;
      this.isMaxWeightValid = false;
      if (
        form.valid &&
        !this.isEmailValid &&
        this.isFirstNameValid == false &&
        this.isLastNameValid == false
      ) {
        this.heightWeight.height_feet = this.heightWeight.height_feet;
        this.heightWeight.height_inches = this.heightWeight.height_inches;
        if (
          this.graduation_year != null &&
          this.graduation_year != undefined &&
          this.graduation_year != 0
        ) {
          this.athleteProfile.graduation_year = new Date(
            this.graduation_year
          ).getFullYear();
        } else {
          this.athleteProfile.graduation_year = null;
        }
        this.bbSbDetails.league1_sports_position_code =
          this.sportspositioncode[0] != null
            ? this.sportspositioncode[0]
            : null;
        this.bbSbDetails.league2_sports_position_code =
          this.sportspositioncode[1] != null
            ? this.sportspositioncode[1]
            : null;
        this.bbSbDetails.league3_sports_position_code =
          this.sportspositioncode[2] != null
            ? this.sportspositioncode[2]
            : null;
        this.bbSbDetails.league4_sports_position_code =
          this.sportspositioncode[3] != null
            ? this.sportspositioncode[3]
            : null;
        this.bbSbDetails.league5_sports_position_code =
          this.sportspositioncode[4] != null
            ? this.sportspositioncode[4]
            : null;
        this.bbSbDetails.league6_sports_position_code =
          this.sportspositioncode[5] != null
            ? this.sportspositioncode[5]
            : null;
        this.bbSbDetails.league7_sports_position_code =
          this.sportspositioncode[6] != null
            ? this.sportspositioncode[6]
            : null;
        this.bbSbDetails.league8_sports_position_code =
          this.sportspositioncode[7] != null
            ? this.sportspositioncode[7]
            : null;
        this.bbSbDetails.league9_sports_position_code =
          this.sportspositioncode[8] != null
            ? this.sportspositioncode[8]
            : null;

        var accountType = localStorage.getItem("accounttype") || "";
        let isParent = accountType == "5" ? true : false;

        if (!this.invalidBirthDate) {
          this.spinner.show("Please wait...");
          this.athleteProfile.birthDate =
            this.athleteProfile.birthdate.toDateString();
          this.httpProvider
            .UpdateProfileInfo(
              this.athleteProfile,
              this.heightWeight,
              this.bbSbDetails
            )
            .subscribe(
              async (data) => {
                if (data != null && data.body != null) {
                  this.dismiss();
                  var resultData = data.body;
                  if (resultData.IsSuccess) {
                    if (this.isProfileImageChanged) {
                      this.updateProfileImage();
                    } else {
                      this.spinner.hide();
                      let alert = this.alertCtrl.create({
                        header: "Message",
                        message: "Player Profile Updated Successfully",
                        buttons: ["Ok"],
                      });
                      (await alert).present();
                      (await alert).onDidDismiss().then((e) => {});
                      let navigationExtras: NavigationExtras = {
                        state: {
                          athlete_id: this.athleteProfile.athlete_id,
                        },
                        replaceUrl: true,
                      };

                      this.router.navigate(["features"], navigationExtras);
                      this.dataSharingService.isImageNotChanged.next(true);
                      this.dataSharingService.isCloseUpdateAthleteInfo.next(
                        true
                      );
                    }
                  } else {
                    if (this.isProfileImageChanged) {
                      this.updateProfileImage();
                    } else {
                      this.spinner.hide();
                      let navigationExtras: NavigationExtras = {
                        state: {
                          athlete_id: this.athleteProfile.athlete_id,
                        },
                        replaceUrl: true,
                      };

                      this.router.navigate(["features"], navigationExtras);
                    }
                  }
                }
              },
              (error: any) => {
                this.modalCtrl.dismiss();
                this.spinner.hide();
                if (error.status == 401) {
                  localStorage.removeItem("accountId");
                  localStorage.removeItem("accounIdStore");
                  localStorage.removeItem("storedURL");
                  this.accountService.logout();
                  this.dismiss();
                } else if (error && error.message) {
                  this.toaster.showFailureToast(error.message);
                } else {
                  this.alertService.showAlertWithOkBtn(
                    "Error",
                    error.statusText != "" && error.statusText != null
                      ? error.statusText
                      : "Unknown Error"
                  );
                }
              }
            );
        }
      } else {
        if (!form.controls.first_name.valid) {
          var firstName = document.getElementById("firstName") as any;
          firstName.scrollIntoView({ behavior: "smooth" });
        } else if (this.isFirstNameValid == true) {
          var firstName = document.getElementById("firstName") as any;
          firstName.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.last_name.valid) {
          var lastName = document.getElementById("lastName") as any;
          lastName.scrollIntoView({ behavior: "smooth" });
        } else if (this.isLastNameValid == true) {
          var lastName = document.getElementById("lastName") as any;
          lastName.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.school_name.valid) {
          var schoolName = document.getElementById("school-name") as any;
          schoolName.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.birthdate.valid) {
          var birthDate = document.getElementById("birthDate") as any;
          birthDate.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.league1_type.valid) {
          var league1Type = document.getElementById("league1Type") as any;
          league1Type.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.school_team_name.valid) {
          var league1Name = document.getElementById("league1Name") as any;
          league1Name.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.email.valid) {
          var email = document.getElementById("email") as any;
          email.scrollIntoView({ behavior: "smooth" });
        } else if (!form.controls.affiliate_id.valid) {
          var trainingcenter = document.getElementById("trainingcenter") as any;
          trainingcenter.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  updateProfileImage() {
    var url = localStorage.getItem("imageUrl") || "";
    url =
      url != "null"
        ? url.includes("?")
          ? url.substring(0, url.indexOf("?"))
          : url
        : "";
    this.httpProvider
      .UpdateProfileImage(
        this.athleteProfile.athlete_id.toString(),
        this.profileImage,
        url
      )
      .subscribe(
        async (data) => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData[0].IsSuccess) {
              this.spinner.hide();
              let alert = this.alertCtrl.create({
                header: "Message",
                message: "Player Profile Updated Successfully",
                buttons: ["OK"],
              });
              (await alert).onDidDismiss().then((e) => {
                let navigationExtras: NavigationExtras = {
                  state: {
                    athlete_id: this.athleteProfile.athlete_id,
                  },
                  replaceUrl: true,
                };

                this.router.navigate(["features"], navigationExtras);
                let profileImageDetail = {
                  athleteId: this.athleteProfile.athlete_id,
                  imageDetail: resultData[1],
                };
                this.dataSharingService.isProfileImageUpdated.next(
                  profileImageDetail
                );
                this.dataSharingService.isCloseUpdateAthleteInfo.next(true);
              });
              (await alert).present();
            } else {
              this.alertService.showAlertWithOkBtn(
                "Alert",
                resultData[0].Message
              );
            }
          }
        },
        (error: any) => {
          this.spinner.hide();
          if (error.status == 401) {
            localStorage.removeItem("accountId");
            localStorage.removeItem("accounIdStore");
            localStorage.removeItem("storedURL");
            this.accountService.logout();
            this.dismiss();
          } else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          } else {
            this.alertService.showAlertWithOkBtn(
              "Error",
              error.statusText != "" && error.statusText != null
                ? error.statusText
                : "Unknown Error"
            );
          }
        }
      );
  }

  LoadAthleteDetailsByAthleteId(athleteId: any) {
    console.log("ATHELETE ID:", athleteId);
    if (athleteId) {
      this.httpProvider.GetAthleteDetailsByAthleteId(athleteId).subscribe(
        (data: any) => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData != null) {
              if (resultData[0] != null) {
                this.profileImageCopy = this.profileImageSource =
                  localStorage.getItem("imageUrl") ??
                  "assets/imgs/Athlete Image.png";
                this.heightWeight = resultData[0];
              } else {
                this.heightWeight.entry_date = new Date();
                this.heightWeight.height_feet = 0;
                this.heightWeight.height_inches = 0;
                this.heightWeight.weight_pounds = 0;
                this.heightWeight.height = "";
                this.heightWeight.weight = "";
              }
              if (resultData[1] != null) {
                this.positionList = [];
                for (let i = 0; i < this.overallPositionList.length; i++) {
                  if (
                    this.overallPositionList[i].sports_focus_code ==
                    resultData[1].league1_sports_focus_code
                  ) {
                    this.positionList.push(this.overallPositionList[i]);
                  }
                }
                resultData[1].affiliate_id =
                  resultData[1].affiliate_id.toString();
                this.bbSbDetails = resultData[1];
                var values = [];
                //this.affiliate_id = this.bbSbDetails.affiliate_id != null ? this.bbSbDetails.affiliate_id.toString() : null;
                this.bbSbDetails.positionCode =
                  resultData[1].sports_position_code != null
                    ? resultData[1].sports_position_code.toString()
                    : null;
                if (
                  this.bbSbDetails.league1_sports_position_code != null &&
                  this.bbSbDetails.league1_sports_position_code != 0
                ) {
                  let PositionField1 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league1_sports_position_code
                  );
                  values.push(PositionField1[0]);
                }
                if (
                  this.bbSbDetails.league2_sports_position_code != null &&
                  this.bbSbDetails.league2_sports_position_code != 0
                ) {
                  let PositionField2 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league2_sports_position_code
                  );
                  values.push(PositionField2[0]);
                }
                if (
                  this.bbSbDetails.league3_sports_position_code != null &&
                  this.bbSbDetails.league3_sports_position_code != 0
                ) {
                  let PositionField3 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league3_sports_position_code
                  );
                  values.push(PositionField3[0]);
                }
                if (
                  this.bbSbDetails.league4_sports_position_code != null &&
                  this.bbSbDetails.league4_sports_position_code != 0
                ) {
                  let PositionField4 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league4_sports_position_code
                  );
                  values.push(PositionField4[0]);
                }
                if (
                  this.bbSbDetails.league5_sports_position_code != null &&
                  this.bbSbDetails.league5_sports_position_code != 0
                ) {
                  let PositionField5 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league5_sports_position_code
                  );
                  values.push(PositionField5[0]);
                }
                if (
                  this.bbSbDetails.league6_sports_position_code != null &&
                  this.bbSbDetails.league6_sports_position_code != 0
                ) {
                  let PositionField6 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league6_sports_position_code
                  );
                  values.push(PositionField6[0]);
                }
                if (
                  this.bbSbDetails.league7_sports_position_code != null &&
                  this.bbSbDetails.league7_sports_position_code != 0
                ) {
                  let PositionField7 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league7_sports_position_code
                  );
                  values.push(PositionField7[0]);
                }
                if (
                  this.bbSbDetails.league8_sports_position_code != null &&
                  this.bbSbDetails.league8_sports_position_code != 0
                ) {
                  let PositionField8 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league8_sports_position_code
                  );
                  values.push(PositionField8[0]);
                }
                if (
                  this.bbSbDetails.league9_sports_position_code != null &&
                  this.bbSbDetails.league9_sports_position_code != 0
                ) {
                  let PositionField9 = this.positionList.filter(
                    (a: any) =>
                      a.sports_position_code ==
                      this.bbSbDetails.league9_sports_position_code
                  );
                  values.push(PositionField9[0]);
                }
                values.forEach((element) => {
                  this.sportspositioncode.push(element.sports_position_code);
                });
                this.club_position_code =
                  resultData[1].sports_position_code_club != null
                    ? resultData[1].sports_position_code_club.toString()
                    : null;
              } else {
                (this.bbSbDetails.athlete_id = 0),
                  (this.bbSbDetails.bats = ""),
                  (this.bbSbDetails.throws = ""),
                  (this.bbSbDetails.league1_name = ""),
                  (this.bbSbDetails.league2_name = ""),
                  (this.bbSbDetails.grade_level = ""),
                  (this.bbSbDetails.affiliate_id = 0),
                  (this.bbSbDetails.name = "");
              }
              this.athleteProfile = resultData[2];
              this.graduation_year =
                this.athleteProfile.graduation_year != null
                  ? new Date(
                      "02/01/" + this.athleteProfile.graduation_year.toString()
                    )
                  : null;
              this.athleteProfile.birthdate = new Date(
                this.athleteProfile.birthdate
              );
              if (this.heightWeight != null) {
                this.height =
                  this.heightWeight.height_feet +
                  "'" +
                  " " +
                  this.heightWeight.height_inches +
                  '"';
              }
            }
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          if (error.status == 401) {
            localStorage.removeItem("accountId");
            localStorage.removeItem("accounIdStore");
            localStorage.removeItem("storedURL");
            this.accountService.logout();
            this.dismiss();
          } else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          } else {
            this.alertService.showAlertWithOkBtn(
              "Error",
              error.statusText != "" && error.statusText != null
                ? error.statusText
                : "Unknown Error"
            );
          }
        }
      );
    }
  }

  profileImageChanged(event: any) {
    //event.preventDefault();
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let formData: FormData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        if (
          fileList[i].type != "image/png" &&
          fileList[i].type != "image/jpg" &&
          fileList[i].type != "image/jpeg" &&
          fileList[i].type != "image/gif"
        ) {
          this.alertService.showAlertWithOkBtn(
            "Alert",
            "Image Format is invalid"
          );
          return;
        }
        formData.append("uploadFile", fileList[i], fileList[i].name);
        this.isProfileImageChanged = true;
        this.removeButtonStyle = "block";
      }
      this.profileImage = formData;
      let FileReader: new () => FileReader = ((window as any).FileReader as any)
        .__zone_symbol__OriginalDelegate;
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.profileImageSource = event.target.result;
        this.editProfileImageCopy = event.target.result;
        if (this.isProfileImageChanged) {
          this.editProfileImage();
        }
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  editProfileImageCopy: any;

  editProfileImage() {
    var _this = this;

    //Convert image datauri to blob file
    var dataURItoBlob = function (dataURI: any) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    };

    // Convert blob to file format
    var blobToFile = function (theBlob: any, fileName: any) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    };

    // Moved image data image cropper view
    this.spinner.show("Please wait...");
    this.imageData = this.editProfileImageCopy;
    setTimeout(() => {
      this.isShowImageCropper = true;
      this.dataSharingService.isShowImageFooter.next(false);
      this.cdRef.detectChanges();
    }, 100);
    setTimeout(() => {
      this.InitializeImageCropper();
    }, 200);
  }

  RemoveFiles() {
    this.profileImage = new FormData();
    this.profileImageSource = this.profileImageCopy;
    this.isProfileImageChanged = false;
    this.removeButtonStyle = "none";
    this.fileInput.nativeElement.value = "";
    this.cdRef.detectChanges();
  }
  GetAllLevels() {
    this.httpProvider.GetAllLevels().subscribe(
      (data: any) => {
        if (data != null && data.body != null) {
          this.levelList = [];
          var resultData = data.body;
          for (let i = 0; i < resultData.length; i++) {
            this.levelList.push(resultData[i].grade.toString());
          }
        }
      },
      (error: any) => {
        if (error.status == 401) {
          localStorage.removeItem("accountId");
          localStorage.removeItem("accounIdStore");
          localStorage.removeItem("storedURL");
          this.accountService.logout();
          this.dismiss();
        } else {
          this.alertService.showAlertWithOkBtn(
            "Error",
            error.statusText != "" && error.statusText != null
              ? error.statusText
              : "Unknown Error"
          );
        }
      }
    );
  }
  GetAllSportsPositions() {
    this.spinner.show("Please wait...");
    this.httpProvider.GetAllSportsPositions().subscribe(
      (data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          for (let i = 0; i < resultData.length; i++) {
            resultData[i].sports_position_code =
              resultData[i].sports_position_code.toString();
          }
          this.overallPositionList = resultData;
        }
        this.LoadAthleteDetailsByAthleteId(this.athleteId);
      },
      (error: any) => {
        this.spinner.hide();
        if (error.status == 401) {
          localStorage.removeItem("accountId");
          localStorage.removeItem("accounIdStore");
          localStorage.removeItem("storedURL");
          this.accountService.logout();
          this.dismiss();
        } else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        } else {
          this.alertService.showAlertWithOkBtn(
            "Error",
            error.statusText != "" && error.statusText != null
              ? error.statusText
              : "Unknown Error"
          );
        }
      }
    );
  }

  GetAllAffiliates() {
    this.httpProvider.GetAllAffiliates().subscribe(
      (data: any) => {
        if (data != null && data.body != null) {
          this.trainingCenterList = [];
          var resultData = data.body;
          for (let i = 0; i < resultData.length; i++) {
            resultData[i].affiliate_id = resultData[i].affiliate_id.toString();
          }
          resultData.sort((a: { name: string }, b: { name: string }) =>
            a.name.localeCompare(b.name)
          );
          this.trainingCenterList = resultData;
        }
      },
      (error: any) => {
        this.spinner.hide();
        if (error.status == 401) {
          localStorage.removeItem("accountId");
          localStorage.removeItem("accounIdStore");
          localStorage.removeItem("storedURL");
          this.accountService.logout();
          this.dismiss();
        } else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        } else {
          this.alertService.showAlertWithOkBtn(
            "Error",
            error.statusText != "" && error.statusText != null
              ? error.statusText
              : "Unknown Error"
          );
        }
      }
    );
  }
  restrictNumeric(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  weightValidation() {
    if (this.heightWeight.weight_pounds < 50) {
      this.heightWeight.weight_pounds = 50;
    } else if (this.heightWeight.weight_pounds > 400) {
      this.heightWeight.weight_pounds = 400;
    }
  }

  focusData(event: any) {
    if (event != null && event != undefined) {
      if (event.srcElement != null && event.srcElement != undefined) {
        if (event.srcElement.id != null && event.srcElement.id != undefined) {
          if (event.srcElement.id == "weightpound") {
            (document.getElementById("weightIdPound") as any).scrollIntoView({
              behavior: "smooth",
            });
          } else if (event.srcElement.id == "graduationyear") {
            (document.getElementById("graduationIdYear") as any).scrollIntoView(
              { behavior: "smooth" }
            );
          } else if (event.srcElement.id == "birthDate") {
            (document.getElementById("birthDateId") as any).scrollIntoView({
              behavior: "smooth",
            });
          } else {
            (
              document.getElementById(event.srcElement.id) as any
            ).scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  }

  backButtonAction() {
    let navigationExtras: NavigationExtras = {
      state: {
        athlete_id: this.athleteProfile.athlete_id,
      },
      replaceUrl: true,
    };

    this.router.navigate(["features"], navigationExtras);
  }

  dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  // Convert blob to file format
  blobToFile(theBlob: any, fileName: any) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  calculateBirthdate(args: any) {
    let birthdate = document.getElementById("birthDate") as HTMLInputElement;
    if (args.value != null && args.value != undefined) {
      let dob: any = new Date(args.value);
      this.invalidBirthDate = this.birthDateProvider.ValidateBirthDate(
        dob,
        this.isParent
      );
    }
  }

  onEmailChange(event: any) {
    this.isEmailValid = this.accountService.emailValidationFunction(event);
  }

  onFirstNameChange(event: any) {
    this.isFirstNameValid = this.accountService.validateEnteredData(event);
  }

  onLastNameChange(event: any) {
    this.isLastNameValid = this.accountService.validateEnteredData(event);
  }

  sportsPositionSelection(event: any) {
    if (event.name == "close") {
      let data = (document.getElementById("sports_position_codes") as any)
        .ej2_instances[0];
      if (
        data.value.length == 0 &&
        data != null &&
        data != undefined &&
        data.value != null &&
        data.value != undefined
      ) {
        this.isSelectedPositionInValid = true;
      } else {
        this.isSelectedPositionInValid = false;
      }
    }
  }

  onPositionChange(event: any) {
    if (this.platform.is("android")) {
      console.log(event);
      setTimeout(() => {
        let data: any = document.querySelectorAll(
          "#sports_position_codes_popup .e-list-parent .e-list-item"
        );
        let dropDownValue = (
          document.getElementById("sports_position_codes") as any
        ).ej2_instances[0];
        if (
          dropDownValue.value.length < 2 &&
          data != null &&
          data != undefined &&
          data.length > 0 &&
          dropDownValue != null &&
          dropDownValue != undefined
        )
          for (let i = 0; i < data.length; i++) {
            if (data[i].classList.contains("e-disable")) {
              data[i].classList.remove("e-disable");
            }
          }
        else {
          for (let i = 0; i < data.length; i++) {
            if (!data[i].classList.contains("e-active")) {
              data[i].classList.add("e-disable");
            } else {
              data[i].classList.remove("e-disable");
            }
          }
          console.log(data);
        }
      }, 500);
    }
  }

  imagecropper: any;

  InitializeImageCropper() {
    var Cropper = window.Cropper;
    var image: any = document.getElementById("CropperImage");
    var options = {
      viewMode: 1,
      preview: ".img-preview",
    };
    this.imagecropper = new Cropper(image, options);
    setTimeout(() => {
      this.moveImage();
      this.spinner.hide();
    }, 100);
  }

  refreshImage() {
    this.imagecropper.reset();
    this.moveImage();
  }

  moveImage() {
    this.imagecropper.setDragMode("move");
    var moveicon: any = document.getElementsByClassName("imageMove");
    if (moveicon && moveicon.length) {
      moveicon[0].style.color = "#BE1E2D";
    }
    var cropicon: any = document.getElementsByClassName("imageCrop");
    if (cropicon && cropicon.length) {
      cropicon[0].style.color = "#fff";
    }
  }

  cropImage() {
    this.imagecropper.setDragMode("crop");
    var moveicon: any = document.getElementsByClassName("imageMove");
    if (moveicon && moveicon.length) {
      moveicon[0].style.color = "#fff";
    }
    var cropicon: any = document.getElementsByClassName("imageCrop");
    if (cropicon && cropicon.length) {
      cropicon[0].style.color = "#BE1E2D";
    }
  }

  zoomInImage() {
    this.imagecropper.zoom(0.1);
  }

  zoomOutImage() {
    this.imagecropper.zoom(-0.1);
  }

  rotateLeftImage() {
    this.imagecropper.rotate(-45);
  }

  rotateRigntImage() {
    this.imagecropper.rotate(45);
  }

  scaleX: any = -1;

  scaleLeftRigntImage() {
    if (
      this.imagecropper &&
      this.imagecropper.imageData &&
      this.imagecropper.imageData.left == 0
    ) {
      this.imagecropper.scaleX(this.scaleX);
      this.scaleX = this.scaleX * -1;
    } else {
      this.imagecropper.scaleY(this.scaleY);
      this.scaleY = this.scaleY * -1;
    }
  }

  scaleY: any = -1;

  scaleTopBottomImage() {
    if (
      this.imagecropper &&
      this.imagecropper.imageData &&
      this.imagecropper.imageData.left == 0
    ) {
      this.imagecropper.scaleY(this.scaleY);
      this.scaleY = this.scaleY * -1;
    } else {
      this.imagecropper.scaleX(this.scaleX);
      this.scaleX = this.scaleX * -1;
    }
  }

  saveCropperImage() {
    this.spinner.show("Please wait...");
    var imageSrc = this.imagecropper.getCroppedCanvas();
    imageSrc = imageSrc.toDataURL("image/jpeg", 0.9);
    this.spinner.hide();
    this.dataSharingService.setCroppedImageSource.next(imageSrc);
    // Clear BehaviorSubject after emit value
    setTimeout(() => {
      this.dataSharingService.setCroppedImageSource.next("");
    }, 100);
  }

  closeView() {
    this.dataSharingService.closeImageCropper.next(true);
  }

  preventDefault(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener("wheel", ["$event"])
  onWheel(event: WheelEvent) {
    (this.graduationYear as any).hide();
    (this.birthDate as any).hide();
    (this.sportsPositionCodes as any).hidePopup();
  }
}
