import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CommonlayoutComponent } from "./_layout/commonlayout/commonlayout.component";
import { HomelayoutComponent } from "./_layout/homelayout/homelayout.component";
import { AuthguardGuard } from "./providers/authguard.guard";

const routes: Routes = [
  { path: "", redirectTo: "features", pathMatch: "full" },
  {
    path: "home",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "login",
    component: CommonlayoutComponent,
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "signup",
    component: CommonlayoutComponent,
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "resetpassword",
    component: CommonlayoutComponent,
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: "settings",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./account-settings/account-settings.module").then(
        (m) => m.AccountSettingsPageModule
      ),
  },
  {
    path: "profile/view",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./view-profile/view-profile.module").then(
        (m) => m.ViewProfilePageModule
      ),
  },
  {
    path: "profile/edit",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./update-athlete-info/update-athlete-info.module").then(
        (m) => m.UpdateAthleteInfoPageModule
      ),
  },
  {
    path: "package",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./feature-package/feature-package.module").then(
        (m) => m.FeaturePackagePageModule
      ),
  },
  {
    path: "type",
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./member-account/member-account.module").then(
        (m) => m.MemberAccountPageModule
      ),
  },
  {
    path: "update/athlete",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./update-athlete-info/update-athlete-info.module").then(
        (m) => m.UpdateAthleteInfoPageModule
      ),
  },
  {
    path: "addathlete",
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./add-athlete/add-athlete.module").then(
        (m) => m.AddAthletePageModule
      ),
  },
  {
    path: "waiver",
    loadChildren: () =>
      import("./waiver-model/waiver-model.module").then(
        (m) => m.WaiverModelPageModule
      ),
  },
  {
    path: "mailactivation",
    loadChildren: () =>
      import("./mail-activation/mail-activation.module").then(
        (m) => m.MailActivationPageModule
      ),
  },
  {
    path: "mailactivation/:activationCode",
    loadChildren: () =>
      import("./mail-activation/mail-activation.module").then(
        (m) => m.MailActivationPageModule
      ),
  },
  {
    path: "TAPmailactivation",
    loadChildren: () =>
      import("./tap-login/tap-login.module").then((m) => m.TapLoginModule),
  },
  {
    path: "TAPmailactivation/:applicationType/:accountType",
    loadChildren: () =>
      import("./tap-login/tap-login.module").then((m) => m.TapLoginModule),
  },
  {
    path: "adminsite",
    loadChildren: () =>
      import("./admin-site/admin-site.module").then((m) => m.AdminSiteModule),
  },
  {
    path: "publicShare",
    loadChildren: () =>
      import("./public-share/public-share.module").then(
        (m) => m.PublicSharePageModule
      ),
  },
  {
    path: "update-athlete-info",
    loadChildren: () =>
      import("./update-athlete-info/update-athlete-info.module").then(
        (m) => m.UpdateAthleteInfoPageModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "member-account-creation",
    component: HomelayoutComponent,
    loadChildren: () =>
      import(
        "./member-account-creation-updated/member-account-creation.module"
      ).then((m) => m.MemberAccountCreationPageModule),
  },
  {
    path: "public-share",
    loadChildren: () =>
      import("./public-share/public-share.module").then(
        (m) => m.PublicSharePageModule
      ),
  },
  {
    path: "title-model",
    loadChildren: () =>
      import("./title-model/title-model.module").then(
        (m) => m.TitleModelPageModule
      ),
  },
  {
    path: "category-model",
    loadChildren: () =>
      import("./category-model/category-model.module").then(
        (m) => m.CategoryModelPageModule
      ),
  },
  {
    path: "features",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./features/features.module").then((m) => m.FeaturesPageModule),
  },
  {
    path: "highlight-upload",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./highlight-upload/highlight-upload.module").then(
        (m) => m.HighlightUploadPageModule
      ),
  },
  {
    path: "edit-screen-one",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./edit-screen-one/edit-screen-one.module").then(
        (m) => m.EditScreenOnePageModule
      ),
  },
  {
    path: "gallery",
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./gallery/gallery.module").then((m) => m.GalleryPageModule),
  },
  {
    path: "player",
    canLoad: [AuthguardGuard],
    component: HomelayoutComponent,
    loadChildren: () =>
      import("./player/player.module").then((m) => m.PlayerPageModule),
  },

  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./privacy-policy-page/privacy-policy-page.module").then((m) => m.PrivacyPolicyPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
