import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toasterCtrl: ToastController) { }

  async showSuccessToast(message:any) {
    let toaster = this.toasterCtrl.create({
      message: message,
      duration: 1500,
      position: 'top',
      cssClass: "toaster-wrapper",
      color: 'success'
    });
    (await toaster).present();
  }

  async showFailureToast(message:any) {
    let toaster = this.toasterCtrl.create({
      message: message,
      duration: 1500,
      position: 'top',
      cssClass: "toaster-wrapper",
      color: 'danger'
    });
    (await toaster).present();
  }
}
