// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
  position: absolute;
  width: 100%;
  z-index: 100000;
  border-bottom: 5px solid #c01e2d;
}

ion-toolbar {
  background: black;
  --background: black;
}`, "",{"version":3,"sources":["webpack://./src/app/_layout/commonlayout/commonlayout.component.scss","webpack://./../../../IPostSports%20Website/git/Ipostsports-web/src/app/_layout/commonlayout/commonlayout.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,gCAAA;ACCJ;;ADCA;EACI,iBAAA;EACA,mBAAA;ACEJ","sourcesContent":[".app-header {\n    position: absolute;\n    width: 100%;\n    z-index: 100000;\n    border-bottom: 5px solid #c01e2d;\n}\nion-toolbar{\n    background: black;\n    --background: black;\n}",".app-header {\n  position: absolute;\n  width: 100%;\n  z-index: 100000;\n  border-bottom: 5px solid #c01e2d;\n}\n\nion-toolbar {\n  background: black;\n  --background: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
