import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DataSharingService } from 'src/app/providers/data-sharing.service';
import { SpinnerService } from 'src/app/providers/spinner-service.service';
declare var window: any;

@Component({
  selector: 'app-imagecropper',
  templateUrl: './imagecropper.component.html',
  styleUrls: ['./imagecropper.component.scss'],
})
export class ImagecropperComponent implements OnInit {

  @Input() public imageData: any;
  pageName: any;
  accountId: any;
  isShow : boolean = false;
  marginTopvalueForAddAthlete: any = `calc(60px + env(safe-area-inset-top))`;
  constructor(public navCtrl: NavController, public navParams: NavParams, private router: Router, private titleService: Title,
    private spinner: SpinnerService, private dataSharingService: DataSharingService) {
    this.titleService.setTitle('Image Cropper | iPost Sports');
    this.spinner.show('Please wait...');
    this.dataSharingService.isShowImageFooter.subscribe(value => {
      if (value) {
        this.isShow = value;
      }
    });
  }

   ngOnInit() {
    setTimeout(() => {
      this.InitializeImageCropper();
    }, 200);
  }

  ngAfterViewInit() {
    let lastSegment = '';
    let grid:any = document.getElementById('grid');
    let toolBarSave:any = document.getElementsByClassName('toolbar-save');
    const currentURL = window.location.href; 
    let segments= currentURL.split('/');
    if(segments.length > 0){
     lastSegment = segments[segments.length - 1];
    }
    if(grid && lastSegment == 'addathlete' && toolBarSave != null && toolBarSave[0] != null && toolBarSave[0] != undefined && toolBarSave.length > 0){
      grid.style.marginTop = this.marginTopvalueForAddAthlete;
      toolBarSave[0].setAttribute('style', 'height: 45px');
      toolBarSave[0].setAttribute('style', 'min-height: 45px');
    }
  }

  imagecropper: any;

  InitializeImageCropper() {
    var Cropper = window.Cropper;
    var image: any = document.getElementById('CropperImage');
    var options = {
      viewMode: 1,
      preview: '.img-preview',
    };
    this.imagecropper = new Cropper(image, options);
    setTimeout(() => {
      this.moveImage();
      this.spinner.hide();
    }, 100);
  }

  refreshImage() {
    this.imagecropper.reset();
    this.moveImage();
  }

  moveImage() {
    this.imagecropper.setDragMode("move");
    var moveicon: any = document.getElementsByClassName("imageMove");
    if (moveicon && moveicon.length) {
      moveicon[0].style.color = "#BE1E2D";
    }
    var cropicon: any = document.getElementsByClassName("imageCrop");
    if (cropicon && cropicon.length) {
      cropicon[0].style.color = "#fff";
    }
  }

  cropImage() {
    this.imagecropper.setDragMode("crop");
    var moveicon: any = document.getElementsByClassName("imageMove");
    if (moveicon && moveicon.length) {
      moveicon[0].style.color = "#fff";
    }
    var cropicon: any = document.getElementsByClassName("imageCrop");
    if (cropicon && cropicon.length) {
      cropicon[0].style.color = "#BE1E2D";
    }
  }

  zoomInImage() {
    this.imagecropper.zoom(0.1);
  }

  zoomOutImage() {
    this.imagecropper.zoom(-0.1);
  }

  rotateLeftImage() {
    this.imagecropper.rotate(-45);
  }

  rotateRigntImage() {
    this.imagecropper.rotate(45);
  }

  scaleX: any = -1;

  scaleLeftRigntImage() {
    if (this.imagecropper && this.imagecropper.imageData && this.imagecropper.imageData.left == 0) {
      this.imagecropper.scaleX(this.scaleX);
      this.scaleX = this.scaleX * (-1)
    }
    else {
      this.imagecropper.scaleY(this.scaleY);
      this.scaleY = this.scaleY * (-1)
    }
  }

  scaleY: any = -1;

  scaleTopBottomImage() {
    if (this.imagecropper && this.imagecropper.imageData && this.imagecropper.imageData.left == 0) {
      this.imagecropper.scaleY(this.scaleY);
      this.scaleY = this.scaleY * (-1)
    }
    else {
      this.imagecropper.scaleX(this.scaleX);
      this.scaleX = this.scaleX * (-1)
    }
  }

  saveCropperImage() {
    this.spinner.show('Please wait...');
    var imageSrc = this.imagecropper.getCroppedCanvas();
    imageSrc = imageSrc.toDataURL("image/jpeg", 0.9);    
    this.spinner.hide();
    this.dataSharingService.setCroppedImageSource.next(imageSrc);
    // Clear BehaviorSubject after emit value
    setTimeout(() => {
      this.dataSharingService.setCroppedImageSource.next('');
    }, 100);
  }

  closeView() {   
    this.dataSharingService.closeImageCropper.next(true);
  }
}