import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { BraintreeModelPage } from '../braintree-model/braintree-model.page';
import { IbraintreeTransaction } from '../interfaces/account-interface';
import { HttpProvider } from '../providers/http-provider.service';

@Component({
  selector: 'app-downgrade-conditions-model',
  templateUrl: './downgrade-conditions-model.page.html',
  styleUrls: ['./downgrade-conditions-model.page.scss'],
})
export class DowngradeConditionsModelPage implements OnInit {
  clickedParent: Boolean = false;
  braintreeTransaction: IbraintreeTransaction = {
    cash: '',
    token: '',
    atheleteId: '',
    packageDescription: '',
    accountId: '',
    user: '',
    packageCode: '',
    isAddOnActive: false,
    athleteOldPackageCode: '',
    effectiveDate : null
  };
  constructor(public navCtrl: NavController, private loadingCtrl: LoadingController,
    private alertCtrl: AlertController, public httpProvider: HttpProvider, 
    public modalCtrl: ModalController, private navParams: NavParams) {  
      if (navParams != null) {
        if (navParams.data.loggedType == "ParentType") {
          this.clickedParent = true;
        }
        else if (navParams.data.loggedType == "AthleteType") {
          this.clickedParent = false;
          this.braintreeTransaction.cash = navParams.data.cash;
          this.braintreeTransaction.atheleteId = navParams.data.atheleteId;
          this.braintreeTransaction.athleteOldPackageCode = navParams.data.athleteOldPackageCode;
          this.braintreeTransaction.packageCode = navParams.data.packageCode;
          this.braintreeTransaction.packageDescription = navParams.data.packageDescription;
          this.braintreeTransaction.user = navParams.data.user;
        }
      }  
  }
  ngOnInit(){
  }

  async proceedPayment() {    
      this.modalCtrl.dismiss();
      if (this.braintreeTransaction.packageCode == "4" || this.braintreeTransaction.packageCode == "5") {
        let data = {
          cash: this.braintreeTransaction.cash,
          atheleteId: this.braintreeTransaction.atheleteId,
          packageCode: this.braintreeTransaction.packageCode,
          packageDescription: this.braintreeTransaction.packageDescription,
          user: 'athlete',
          isAddOnActive: this.braintreeTransaction.isAddOnActive,
          athleteOldPackageCode: this.braintreeTransaction.athleteOldPackageCode
        }
        let modal = await this.modalCtrl.create({
          component : BraintreeModelPage,
          componentProps : data,
          cssClass: 'stripe-modal modelWidth'
        });;
        await modal.present();
      }
      else {
        let alert = this.alertCtrl.create({
          header: 'Alert',
          message: 'Sorry, your payment is failed. Please try again later.',
          buttons: ['OK']
        });
        (await alert).present();
      }
    
  }

  CancelPayment() {
    this.modalCtrl.dismiss();
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }
}
