import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-switch-plan-model',
  templateUrl: './switch-plan-model.page.html',
  styleUrls: ['./switch-plan-model.page.scss'],
})
export class SwitchPlanModelPage implements OnInit {
  effectiveDate: any;
  totalPrice: any;
  isUpgradePlan: any;
  isMonthlyPlan: boolean = false;
  sum: number=0;
  grid: any;
  atheleteId: any;
  accountId: any;
  packageList: any;

  constructor(public navCtrl: NavController, public httpProvider: HttpProvider,
    public modalCtrl: ModalController, private loadingCtrl: LoadingController,
    private alertCtrl: AlertController, private navParams: NavParams,
    private spinner: SpinnerService, private router: Router, private toaster : ToasterService) {
    if (navParams != null) {
      this.effectiveDate = navParams.data.EffectiveDate;
      this.totalPrice = navParams.data.TotalPrice;
      this.isUpgradePlan = navParams.data.IsUpgradePlan;
      this.isMonthlyPlan = navParams.data.isMonthlyPlan;
      this.grid = navParams.data.grid;
      this.packageList = navParams.data.packageList;
    }
  }

  ngOnInit(): void {
  }

  cancelSwitchPlan() {
    this.modalCtrl.dismiss();
  }

  async proceedSwitchPlan() {
    this.modalCtrl.dismiss();
    let gridData = this.grid;
    if (this.isMonthlyPlan == true) {
      gridData = (gridData as any).filter((a : any) => a.MonthAmount != 0);
      this.sum = 0;
      gridData.forEach((i: any) => {
        this.sum += parseFloat(i.MonthAmount);
      });
    }
    else {
      gridData = (gridData as any).filter((a : any) => a.YearAmount != 0);
      this.sum = 0;
      gridData.forEach((i: any) => {
        this.sum += parseFloat(i.YearAmount);
      });
    }

    if (this.sum > 0 && this.sum != null && this.sum != undefined) {
      this.spinner.show('Please wait...');
      localStorage.setItem('gridDataStore', JSON.stringify(gridData));
      localStorage.setItem('packageListStore', JSON.stringify(this.packageList));
      localStorage.setItem('isMonthlyPlan', JSON.stringify(this.isMonthlyPlan));
      let data = {
        cash: this.sum.toFixed(2),
        atheleteDetail: this.grid,
        subscriptionPackageDetails: localStorage.getItem('packageListStore') != null ? JSON.parse(localStorage.getItem('packageListStore') || '') : "",
        isMonthlyPlan: localStorage.getItem('isMonthlyPlan') || '',
        EffectiveFrom: this.effectiveDate
      }
      this.braintreeSwitchPlanTransaction(data);
      this.sum = 0;
    }
  }

  //Close Icon click
  dismiss() {
    this.modalCtrl.dismiss();
  }

  async braintreeSwitchPlanTransaction(data: any) {
    this.httpProvider.braintreeSwitchPlanTransaction(data).subscribe(async data => {
      if (data.ok = true && (data.body == "success" || data.body == "trial")) {
        this.spinner.hide();
        let plan = this.isMonthlyPlan == true ? "monthly" : "yearly";
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: "You have updated to " + plan + ' plan successfully.',
          buttons: ['OK']
        });
        (await alert).onDidDismiss().then(e => {
        });
        (await alert).present();
      }
      else {
        this.spinner.hide();
        let alert = this.alertCtrl.create({
          header: 'Alert',
          message: 'Sorry, your payment is failed. Please try again later.',
          buttons: ['OK']
        });
        (await alert).present();
        (await alert).onDidDismiss().then(async e => {
          this.spinner.show('Please wait...');
          this.router.navigate(['package'], { replaceUrl: true });;
          this.spinner.hide();
        });
      }
    },
      async (error: any) => {
        this.spinner.hide();
        if (error.status == 401) {
        }
        else if (error.status == 417) {
          var errorMessage = error.body;
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: errorMessage,
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(async e => {
            this.spinner.show('Please wait...');
            this.router.navigate(['package'], { replaceUrl: true });;
            this.spinner.hide();
          });
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
        else {
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: 'Sorry, your payment is failed. Please try again later.',
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(async e => {
            this.spinner.show('Please wait...');
            this.router.navigate(['package'], { replaceUrl: true });;
            this.spinner.hide();
          });
        }
      });
  }
}
