import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StatusBar} from "@capacitor/status-bar";
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading = false;

  constructor(public loadingCtrl: LoadingController) { }

  async show(message: any) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: message
    }).then(load => {
      load.present().then(() => {        
        if (!this.isLoading) {
          load.dismiss();
        }
      });
    });
  }

  async hide() {
    this.isLoading = false;
    let topLoader = await this.loadingCtrl.getTop();
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        break
      }
      topLoader = await this.loadingCtrl.getTop();
    }
  }

  // For keyboard scrolls footer issue and hide status bar
  setKeyboardStatusbarInCapacitor(){
    StatusBar.hide();
  }

}
