import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebApiService } from './web-api-service.service';
import { IAthleticModel, IAccomplishmentsModel, IPublicShare, IAccountLogin, IAccountSignup, IAcademicAccomplishment, IAthleticAccomplishment, IAthlete, IHeightWeight, IBbSbDetails, IbraintreeTransaction, IAdminsite } from '../interfaces/account-interface';

//localhost url
// var apiUrl = "http://localhost:24800/";
// var publishedURL = "http://localhost:8100/";

//Development url
// var apiUrl = "https://ipostapi.azurewebsites.net/";
// var publishedURL = "https://dev.ipostsports.net/";

//QA url
// var apiUrl = "https://ipostsportswebapi20241129030055.azurewebsites.net/"; 
// var publishedURL = "https://qa.ipostsports.net/";

//Production url
var apiUrl = "https://ipostprod-api1.azurewebsites.net/";
var publishedURL = "https://ipostprod-web1.ipostsports.net/";

var uploadURL = "https://media-prod.ipostsports.net:2000";

var httpLink = {
    login: apiUrl + "/api/Account/Login",
    signup: apiUrl + "/api/Account/Signup",
    mailVerification: apiUrl + "/api/Account/ActivateUser",
    waiverVerification: apiUrl + "/api/Account/WaiverSignIn",
    academicAccomplishment: apiUrl + "/api/AthleticProfile/SaveaAademicAccomplishment",
    athleticAccomplishment: apiUrl + "/api/AthleticProfile/SaveAthleticAccomplishment",
    getAcademicAccomplishment: apiUrl + "/api/AthleticProfile/GetAcademicAccomplishment",
    getAthleteAccomplishment: apiUrl + "/api/AthleticProfile/GetAthleticAccomplishment",
    getAthleteProfile: apiUrl + "/api/AthleticProfile/GetAthlete",
    saveAcademicAwards: apiUrl + "/api/AthleticProfile/SaveAcademicAwards",
    saveAthleticAwards: apiUrl + "/api/AthleticProfile/SaveAthleticAwards",
    memberAccount: apiUrl + "/api/MemberAccount/SaveAthleteDetails",
    getAllStates: apiUrl + "/api/Account/GetAllStates",
    getAllSports: apiUrl + "/api/Account/GetAllSports",
    getAthleteDetailsByAthleteId: apiUrl + "/api/AthleticProfile/GetAthleteDetailsByAthleteId",
    getAthleteDetailsByEncryptedAthleteId: apiUrl + "/api/AthleticProfile/GetAthleteDetailsByEncryptedAthleteId",
    getAthleteDetailsByEncrypte: apiUrl + "/api/AthleticProfile/GetAthleteDetailsByEncrypte",
    getDecrypedAthleteIdDetails: apiUrl + "/api/AthleticProfile/GetDecrypedAthleteIdDetails",
    getSubscriptionPackageCode: apiUrl + "/api/AthleticProfile/GetSubscriptionDetails",
    getAllAffiliates: apiUrl + "/api/Account/GetAllAffliates",
    UpdateAthleteDetails: apiUrl + "/api/AthleticProfile/UpdateAthleteDetails",
    updateMemberType: apiUrl + "/api/Account/UpdateMemberType",
    getSubscribed: apiUrl + "/api/AthleticProfile/GetSubscribed",
    saveProfileImage: apiUrl + "/api/AthleticProfile/SaveAthleteProfileImage",
    getAllSportsPosition: apiUrl + "/api/Account/GetAllSportsPositions",
    UpdateProfileImage: apiUrl + "/api/AthleticProfile/UpdateProfileImage",
    getLoggedUserEmail: apiUrl + "/api/MemberAccount/GetLoggedUserEmail",
    getathleteorparentdetails: apiUrl + "/api/MemberAccount/GetProfileDetailsByAccountId",
    saveaccountsettings: apiUrl + "/api/MemberAccount/UpdateAccountDetails",
    getaccounttype: apiUrl + "/api/account/GetAccountTypeByAccountId",
    getAllLevels: apiUrl + "api/Account/GetAllLevels",
    checkAccountAlreadyCreate: apiUrl + "api/Account/checkAccountAlreadyCreate",
    saveathleteVideoDetails: apiUrl + "api/HighlightUpload/SaveAthleteVideoDetail",
    getAthleteVideoDetails: apiUrl + "api/HighlightUpload/GetAthleteVideoDetails",
    getAthleteVideoList: apiUrl + "api/HighlightUpload/GetAthleteVideosList",
    deleteVideoDetails: apiUrl + "api/HighlightUpload/DeleteVideoDetails",
    getAthleteNames: apiUrl + "api/HighlightUpload/GetAthleteNames",
    getHighlightUploadVideos: apiUrl + "api/HighlightUpload/GetAthleteVideoDetails",
    getUploadedFilesSize: apiUrl + "api/HighlightUpload/GetUploadedFilesSize",
    getStatsLockerFIlters: apiUrl + "api/Stats/GetStatsLockerFilters",
    getDetailedStats: apiUrl + "api/Stats/GetStatsLockerDetailedStats",
    getDetailedStatsForShare: apiUrl + "api/Stats/GetStatsLockerDetailedStatsForShare",
    //braintree
    generateClientToken: apiUrl + "api/BraintreeTransaction/GenerateClientToken",
    //Single Athlete
    featurePackageOnLoad: apiUrl + "/api/BraintreeTransaction/FeaturePackage",
    braintreeTransaction: apiUrl + "/api/BraintreeTransaction/BraintreePayOut",
    braintreeAddOnTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeAddOnPayOut",
    braintreeAddOnCancelTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeAddOnCancelPayOut",
    cancelSingleAthleteSubscription: apiUrl + "api/BraintreeTransaction/BraintreeCancelAthleteSubscription",
    braintreeParentTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeParentPayOut",
    braintreeParentCancelTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeParentCancelSubscription",
    braintreeAddOnParentCancelTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeParentCancelAddOn",
    GetShortUrl: apiUrl + "/api/AthleticProfile/GetShortUrl",
    getAccountDetail: apiUrl + "/api/Account/GetAccountDetailByAccountId",
    forgotPassword: apiUrl + "/api/Account/ForgotPassword",
    resetPassword: apiUrl + "/api/Account/ResetPassword",
    getSwitchPlanDetail: apiUrl + "/api/BraintreeTransaction/GetSwitchPlanDetail",
    braintreeSwitchPlanTransaction: apiUrl + "/api/BraintreeTransaction/BraintreeSwitchPlan",
    braintreeCancelSwitchPlan: apiUrl + "/api/BraintreeTransaction/BraintreeCancelSwitchPlan",
    deletePlayer: apiUrl + "/api/Account/DeletePlayer",
    getAllAffiliateDetails: apiUrl + "/api/Account/GetAllAffliateInfoForAdminSite",
    addorUpdateTrainingCentre: apiUrl + "/api/Account/AddorUpdateTrainingCenter",
    deleteTrainingCentreDetails: apiUrl + "/api/Account/DeleteTrainingCentreDetails",
}

@Injectable({
    providedIn: 'root'
  })
export class HttpProvider {
    public APIUrl: any = apiUrl;
    public PublishedURL: any = publishedURL;
    public uploadMediaURL: any = uploadURL;

    public authorizationToken: string = '';

    athleticModel: IAthleticModel = {
        id: null as any,
        encryptedId: null as any
    }
    
    accomplishmentModel: IAccomplishmentsModel = {
        id: null as any,
        year: null as any,
        focus_code: null as any,
        team: null as any
    }

    publicShare: IPublicShare = {
        url: null as any,
        encryptedId: null as any,
        team: null as any,
        opponent: null as any,
        league: null as any,
        rlValue: null as any,
        startDate: null as any,
        endDate: null as any
    }
    
    constructor(private webApiService: WebApiService) {

    }

    tokenGeneration() {
        var token = localStorage.getItem("AuthToken");
        this.authorizationToken = token != null ? token : '';
    }
    
    getGameTapUrl(ath_id : any){
        // QA
        //var gameTapUrl = "https://qa.ipostsports.net/gametap/?ath_id=" + ath_id;
        // Production
        var gameTapUrl = "https://gametap.ipostsports.net/?ath_id=" + ath_id;
        return gameTapUrl;
    }

    public GetAllStates(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllStates);
    }

    public GetAllLevels(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllLevels);
    }

    public GetAllSports(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllSports);
    }

    public GetSubscriptionPackage(id: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getSubscriptionPackageCode + "?id=" + id);
    }

    public GetSubscribed(id: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getSubscribed + "?id=" + id);
    }
    public GetAthleteDetailsByAthleteId(id: number): Observable<any> {
        this.athleticModel.id = id;
        let data = JSON.stringify(this.athleticModel);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getAthleteDetailsByAthleteId, data);
    }

    public GetEncryptedAthleteId(value: IPublicShare): Observable<any> {
        let data = JSON.stringify(value);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getAthleteDetailsByEncrypte, data);
    }
    public Getshorturl(value: string): Observable<any> {
        let data = JSON.stringify(value);
        this.tokenGeneration();
        
        
        
        return this.webApiService.post(this.authorizationToken, httpLink.GetShortUrl, data);
    }
    public GetDecrypedAthleteIdDetails(id: any): Observable<any> {
        this.publicShare.encryptedId = id;
        let data = JSON.stringify(this.publicShare);
        this.tokenGeneration();
        
        
        
        return this.webApiService.post(this.authorizationToken, httpLink.getDecrypedAthleteIdDetails, data);
    }

    public login(accountLogin: IAccountLogin): Observable<any> {
        let data = JSON.stringify(accountLogin);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        
        return this.webApiService.post(this.authorizationToken, httpLink.login, data);
    }

    public signup(accountSignup: IAccountSignup): Observable<any> {
        let data = JSON.stringify(accountSignup);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        
        return this.webApiService.post(this.authorizationToken, httpLink.signup, data);
    }

    public mailVerifivation(activationCode: string): Observable<any> {
        let data = JSON.stringify(activationCode);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        
        return this.webApiService.post(this.authorizationToken, httpLink.mailVerification, data);
    }
    public waiverSigninVerification(waiverActivationCode: string, accountId: number): Observable<any> {
        let data = JSON.stringify(waiverActivationCode);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        
        return this.webApiService.post(this.authorizationToken, httpLink.waiverVerification + '?accountId=' + accountId, data);
    }

    public saveAcademicAccomplishment(academicAccomplishment: IAcademicAccomplishment): Observable<any> {
        let data = JSON.stringify(academicAccomplishment);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.academicAccomplishment, data);
    }

    public SaveAcademicFiles(formData: FormData, athleteId: any, deletedFiles: any): Observable<any> {
        this.tokenGeneration();        
        return this.webApiService.postWithSaveFiles(this.authorizationToken, httpLink.saveAcademicAwards, formData, athleteId, deletedFiles);
    }

    public SaveAthleticFiles(formData: FormData, athleteId: any, deletedFiles: any): Observable<any> {
        this.tokenGeneration();             
        return this.webApiService.postWithSaveFiles(this.authorizationToken, httpLink.saveAthleticAwards, formData, athleteId, deletedFiles);
    }

    public saveAthleticAccomplishment(athleticAccomplishment: IAthleticAccomplishment): Observable<any> {
        let data = JSON.stringify(athleticAccomplishment);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.athleticAccomplishment, data);
    }

    public GetAcademicAccomplishments(id: any, year: string): Observable<any> {
        this.accomplishmentModel.id = id;
        this.accomplishmentModel.year = year != "NaN" ? year : new Date().getFullYear().toString();
        let data = JSON.stringify(this.accomplishmentModel);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getAcademicAccomplishment, data);
    }

    public GetAthleteAccomplishments(id: any, year: string, focuscode: any, team: any): Observable<any> {
        this.accomplishmentModel.id = id;
        this.accomplishmentModel.year = year != "NaN" ? year : new Date().getFullYear().toString();
        this.accomplishmentModel.focus_code = focuscode;
        this.accomplishmentModel.team = team;
        let data = JSON.stringify(this.accomplishmentModel);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getAthleteAccomplishment, data);
    }

    public GetAthleteProfile(id: number): Observable<any> {
        this.athleticModel.id = id;
        let data = JSON.stringify(this.athleticModel);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getAthleteProfile, data);
    }

    public saveMemberDetails(athleteDetails: IAthlete, heightWeight: IHeightWeight, bbSbDetails: IBbSbDetails): Observable<any> {
        let athletedata = JSON.stringify(athleteDetails);
        let heightweightdata = JSON.stringify(heightWeight);
        let bbSbdata = JSON.stringify(bbSbDetails);
        let isCreatedByTAP = localStorage.getItem("isCreatedByTAP") == null ? false : localStorage.getItem("isCreatedByTAP") == "true" ? true : false;
        let memberdata = [athletedata, heightweightdata, bbSbdata, athleteDetails.isparent, isCreatedByTAP];
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.memberAccount, memberdata);
    }

    public featurePackageOnLoad(id: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.featurePackageOnLoad + '?id=' + id);
    }

    public GetAllAffiliates(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllAffiliates);
    }

    public UpdateProfileInfo(athleteDetails: any, heightWeightDetails: any, bbSbDetails: any): Observable<any> {
        let athletedata = JSON.stringify(athleteDetails);
        let heightweightdata = JSON.stringify(heightWeightDetails);
        let bbSbdata = JSON.stringify(bbSbDetails);
        let memberdata = [athletedata, heightweightdata, bbSbdata]
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.UpdateAthleteDetails, memberdata);
    }

    public UpdateMemberType(accountId: number, typeId: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.updateMemberType + '?accountId=' + accountId + '&typeId=' + typeId);
    }

    public SaveProfileImage(formData: FormData, athleteId: string, accountId: string, sportsFocusCode: string) {
        this.tokenGeneration();
        return this.webApiService.postWithSaveImage(this.authorizationToken, httpLink.saveProfileImage, formData, athleteId, accountId, sportsFocusCode);
    }

    public GetAllSportsPositions(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllSportsPosition);
    }

    public UpdateProfileImage(athleteId: any, updatedProfileImage: FormData, profileImageUrl: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.postWithUpdateProfileImage(this.authorizationToken, httpLink.UpdateProfileImage, updatedProfileImage, athleteId, profileImageUrl);
    }

    public GetLoggedUserEmail(id: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getLoggedUserEmail + '?id=' + id);
    }

    public GetAccountType(id: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getaccounttype + '?id=' + id);
    }

    public GetAthleteOrParentDetails(id: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getathleteorparentdetails + '?id=' + id);
    }

    public saveAccountSettingsDetails(athleteDetails: IAthlete): Observable<any> {
        let athletedata = JSON.stringify(athleteDetails);
        let memberdata = [athletedata, athleteDetails.isparent];
        return this.webApiService.post(this.authorizationToken, httpLink.saveaccountsettings, memberdata);
    }

    public checkAccountAlreadyCreate(accountId: number): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.checkAccountAlreadyCreate + '?accountId=' + accountId);
    }

    public UpdateAthleteVideos(data:any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.saveathleteVideoDetails, data);
    }

    public GetAthleteVideoDetails(athleteId : any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAthleteVideoDetails + "?athleteId=" + athleteId);
    }

    public GetAthleteVideoList(athleteId : any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAthleteVideoList + "?athleteId=" + athleteId);
    }

    public DeleteVideoDetails(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();        
        return this.webApiService.post(this.authorizationToken, httpLink.deleteVideoDetails, dataValue);
    }

    public GetAthleteName(accountId: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAthleteNames + "?accountId=" + accountId);
    }

    public GetHighlightUploadVideos(athleteId: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getHighlightUploadVideos + "?athleteId=" + athleteId);
    }

    public GetUploadedFilesSize(athleteId: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getUploadedFilesSize + "?athleteId=" + athleteId);
    }

    public GetStatsLockerFilters(athleteId: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getStatsLockerFIlters + "?athleteId=" + athleteId);
    }

    public GetDetailedStats(athleteId: any, teamFilter: any, opponentFilter: any, startDateFilter: any, endDateFilter: any, leagueFilter: any, rlFilter: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getDetailedStats + "?athleteId=" + athleteId + "&teamFilter=" + teamFilter + "&opponentFilter=" + opponentFilter + "&startDateFilter=" + startDateFilter + "&endDateFilter=" + endDateFilter + "&leagueFilter=" + leagueFilter + "&rlFilter=" + rlFilter);
    }

    public GetDetailedStatsForShare(athleteId: any, teamFilter: any, opponentFilter: any, startDateFilter: any, endDateFilter: any, leagueFilter: any, rlFilter: any): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getDetailedStatsForShare + "?athleteId=" + athleteId + "&teamFilter=" + teamFilter + "&opponentFilter=" + opponentFilter + "&startDateFilter=" + startDateFilter + "&endDateFilter=" + endDateFilter + "&leagueFilter=" + leagueFilter + "&rlFilter=" + rlFilter);
    }


    //braintree client token generation
    clientTokenGenerate(athleteId: any) {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.generateClientToken + "?id=" + athleteId);
    }

    //braintree transaction for single athlete
    public braintreeTransaction(Transaction: IbraintreeTransaction): Observable<any> {
        let data = JSON.stringify(Transaction);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeTransaction, data);
    }

    //braintree addOn transaction for single athlete
    public braintreeAddOnTransaction(Transaction: IbraintreeTransaction): Observable<any> {
        let data = JSON.stringify(Transaction);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeAddOnTransaction, data);
    }

    //braintree cancel addOn transaction for single athlete
    public braintreeAddOnCancelTransaction(Transaction: IbraintreeTransaction): Observable<any> {
        let data = JSON.stringify(Transaction);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeAddOnCancelTransaction, data);
    }

    //braintree addOn transaction for single athlete
    public braintreeCancelTransaction(Transaction: IbraintreeTransaction): Observable<any> {
        let data = JSON.stringify(Transaction);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.cancelSingleAthleteSubscription, data);
    }

    //braintree parent transaction
    public braintreeParentTransaction(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeParentTransaction, dataValue);
    }

    //braintree parent cancel transaction
    public braintreeParentCancelTransaction(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeParentCancelTransaction, dataValue);
    }

    //braintree parent addOn cancellation
    public braintreeParentAddOnCancelTransaction(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeAddOnParentCancelTransaction, dataValue);
    }

    public getAccountDetails(activationCode: string): Observable<any> {
        this.tokenGeneration();
        let data = JSON.stringify(activationCode);
        let headers = new Headers({ 'Content-Type': 'application/json' });        
        return this.webApiService.post(this.authorizationToken, httpLink.getAccountDetail, data);
    }

    //forgot pasword
    forgotPassword(userName: any) {
        this.tokenGeneration();
        let headers = new Headers({ 'Content-Type': 'application/json' });        
        return this.webApiService.get(this.authorizationToken, httpLink.forgotPassword + "?userNameOrEmail=" + userName);
    }

    //reset password
    resetPassword(newPassword: string, resetPasswordKey: any, applicationType: string) {     
        this.tokenGeneration();   
        return this.webApiService.getWithResetPasswordKey(this.authorizationToken, httpLink.resetPassword + "?newPassword=" + newPassword, resetPasswordKey, applicationType);
    }

    //get Switch Plan Detail
    getSwitchPlanDetail(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.getSwitchPlanDetail, dataValue);
    }

    //braintree parent transaction
    public braintreeSwitchPlanTransaction(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeSwitchPlanTransaction, dataValue);
    }

    //braintree parent transaction
    public braintreeCancelSwitchPlan(data: any): Observable<any> {
        let dataValue = JSON.stringify(data);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.braintreeCancelSwitchPlan, dataValue);
    }

    public deletePlayer(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.deletePlayer, null);
    }

    public SaveCageCamLocation(adminsiteData: IAdminsite): Observable<any> {
        let data = JSON.stringify(adminsiteData);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.addorUpdateTrainingCentre, data);
    }

    public GetAllAffiliateTrainingCentres(): Observable<any> {
        this.tokenGeneration();
        return this.webApiService.get(this.authorizationToken, httpLink.getAllAffiliateDetails);
    }

    public DeleteTrainingCentreDetails(affiliateId: number): Observable<any> {
        let data = JSON.stringify(affiliateId);
        this.tokenGeneration();
        return this.webApiService.post(this.authorizationToken, httpLink.deleteTrainingCentreDetails, data);
    }
}