import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { NavController, ModalController, NavParams, AlertController, LoadingController } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { IbraintreeTransaction } from '../interfaces/account-interface';
import { HttpProvider } from '../providers/http-provider.service';
import { NavigationExtras, Router } from '@angular/router';
import { SpinnerService } from '../providers/spinner-service.service';
declare var stripe: any;
declare var elements: any;
declare var braintree: any;
declare var cordova: any;

@Component({
  selector: 'app-braintree-model',
  templateUrl: './braintree-model.page.html',
  styleUrls: ['./braintree-model.page.scss'],
})
export class BraintreeModelPage implements OnInit {
  submitted = false;
  cash: string = "";
  braintreeTransaction: IbraintreeTransaction = {
    cash: '',
    token: '',
    atheleteId: '',
    packageDescription: '',
    accountId: '',
    user: '',
    packageCode: '',
    isAddOnActive: false,
    athleteOldPackageCode: '',
    effectiveDate: null
  }
  public gridDataStore: Object[] | any;
  @ViewChild('cardInfo') cardInfo: ElementRef | any;
  card: any;
  error: string = "";
  constructor(public navCtrl: NavController, public httpProvider: HttpProvider,
    public formModule: FormsModule, public modalCtrl: ModalController, public navParams: NavParams,
    private router: Router, private alertCtrl: AlertController, private loadingCtrl: LoadingController,
    private spinner: SpinnerService) {
    if (navParams != null) {
      this.braintreeTransaction.cash = navParams.data.cash;
      this.braintreeTransaction.packageCode = navParams.data.packageCode;
      this.braintreeTransaction.packageDescription = navParams.data.packageDescription;
      this.braintreeTransaction.atheleteId = navParams.data.atheleteId;
      this.braintreeTransaction.user = navParams.data.user;
      this.braintreeTransaction.isAddOnActive = navParams.data.isAddOnActive;
      this.braintreeTransaction.athleteOldPackageCode = navParams.data.athleteOldPackageCode;
      this.cash = navParams.data.cash;
    }
  }

  onClick(event: any) {
    if (cordova != undefined) {
      if (cordova.plugins != undefined) {
        if (cordova.plugins.Keyboard != undefined) {
          if (cordova.plugins.Keyboard.isVisible) {
            cordova.plugins.Keyboard.close();
          }
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    var self = this;
    self.spinner.show('Please wait...');
    self.gridDataStore = localStorage.getItem('gridDataStore') != null ? JSON.parse(localStorage.getItem('gridDataStore') || '') : "";
    var button = document.querySelector('#submit-button') as any;
    let coderGenerateId = self.braintreeTransaction.user == "parent" ? (localStorage.getItem('accountId') != null ? JSON.parse(localStorage.getItem('accountId') || '') : ""): self.braintreeTransaction.atheleteId;
    self.httpProvider.clientTokenGenerate(coderGenerateId).subscribe(async (data: any) => {
      braintree.dropin.create({
        authorization: data.body,
        selector: '#dropin-container',
        paypal: {
          flow: 'vault'
        },
        venmo: {
          allowNewBrowserTab: false
        },
        card: {
          overrides: {
            fields: {
              number: {
                placeholder: '**** **** **** ****',
                formatInput: true
              },
              cvv: {
                placeholder: '***',
                formatInput: true
              }
            }
          }
        }
      }, function (err:any, instance:any) {
        if (err) {
          return;
        }
        button.addEventListener('click', function () {
          instance.requestPaymentMethod(async function (err:any, payload:any) {
            if (err) {
              return;
            }
            // Submit payload.nonce to your server
            self.modalCtrl.dismiss();
            self.spinner.show('Payment Transaction is in process. Please wait..');
            self.braintreeTransaction.token = payload.nonce;
            if (self.gridDataStore == null) {
              if (self.braintreeTransaction.packageCode != "7") {
                self.httpProvider.braintreeTransaction(self.braintreeTransaction).subscribe(async data => {
                  if (data.ok = true && data.body == "success") {
                    self.spinner.hide();
                    localStorage.setItem('ispackDisable', "false");
                    let alert = self.alertCtrl.create({
                      header: 'Message',
                      message: '"Your payment for ' + self.braintreeTransaction.cash + ' has been successfully completed". You are now being redirected to home page.',
                      buttons: ['OK']
                    });
                    (await alert).onDidDismiss().then(e => {
                      let data = {
                        IsPlanChanged: true
                      }
                      let navigationExtras: NavigationExtras = { state: data };
                      self.spinner.show('Please wait...');
                      setTimeout(() => {
                        self.navCtrl.navigateBack(['profile/view'], navigationExtras);
                        self.spinner.hide();
                      }, 4000);
                    });
                    (await alert).present();
                  }
                  else {
                    self.spinner.hide();
                    let alert = self.alertCtrl.create({
                      header: 'Alert',
                      message: 'Sorry, your payment is failed. Please try again later.',
                      buttons: ['OK']
                    });
                    (await alert).onDidDismiss().then(async e => {
                      self.spinner.show('Please wait...');
                      self.router.navigate(['package']);
                      self.spinner.hide();
                    });
                    (await alert).present();
                  }
                },
                  async (error: any) => {
                    self.spinner.hide();
                    if (error.status == 401) {
                    }
                    else if (error.status == 417) {
                      var errorMessage = error.error;
                      let alert = self.alertCtrl.create({
                        header: 'Alert',
                        message: errorMessage,
                        buttons: ['OK']
                      });
                      (await alert).present();
                      (await alert).onDidDismiss().then(async e => {
                        self.spinner.show('Please wait...');
                        self.router.navigate(['package']);
                        self.spinner.hide();
                      });
                    }
                    else {
                      let alert = self.alertCtrl.create({
                        header: 'Alert',
                        message: 'Sorry, your payment is failed. Please try again later.',
                        buttons: ['OK']
                      });
                      (await alert).present();
                      (await alert).onDidDismiss().then(async e => {
                        self.spinner.show('Please wait...');
                        self.router.navigate(['package'], { replaceUrl: true });
                        self.spinner.hide();
                      });
                    }
                  })
              }
              else {
                self.httpProvider.braintreeAddOnTransaction(self.braintreeTransaction).subscribe(async data => {
                  if (data.ok = true && data.body == "success") {
                    self.spinner.hide();
                    let alert = self.alertCtrl.create({
                      header: 'Message',
                      message: '"Your payment for ' + self.braintreeTransaction.cash + ' has been successfully completed". You are now being redirected to home page.',
                      buttons: ['OK']
                    });
                    (await alert).onDidDismiss().then(e => {
                      let data = {
                        IsPlanChanged: true
                      }
                      let navigationExtras: NavigationExtras = { state: data };
                      self.spinner.show('Please wait...');
                      setTimeout(() => {
                        self.navCtrl.navigateBack(['profile/view'], navigationExtras);
                        self.spinner.hide();
                      }, 4000);
                    });
                    (await alert).present();
                  }
                  else {
                    self.spinner.hide();
                    let alert = self.alertCtrl.create({
                      header: 'Alert',
                      message: 'Sorry, your payment is failed. Please try again later.',
                      buttons: ['OK']
                    });
                    (await alert).present();
                    (await alert).onDidDismiss().then(async e => {
                      self.spinner.show('Please wait...');
                      self.router.navigate(['package'], { replaceUrl: true });
                      self.spinner.hide();
                    });
                  }
                },
                  async (error: any) => {
                    self.spinner.hide();
                    if (error.status == 401) {
                    }
                    else if (error.status == 417) {
                      var errorMessage = error.error;
                      let alert = self.alertCtrl.create({
                        header: 'Alert',
                        message: errorMessage,
                        buttons: ['OK']
                      });
                      (await alert).present();
                      (await alert).onDidDismiss().then(async e => {
                        self.spinner.show('Please wait...');
                        self.router.navigate(['package']);
                        self.spinner.hide();
                      });
                    }
                    else {
                      let alert = self.alertCtrl.create({
                        header: 'Alert',
                        message: 'Sorry, your payment is failed. Please try again later.',
                        buttons: ['OK']
                      });
                      (await alert).present();
                      (await alert).onDidDismiss().then(async e => {
                        self.spinner.show('Please wait...');
                        self.router.navigate(['package'], { replaceUrl: true });
                        self.spinner.hide();
                      });
                    }
                  })
              }
            }
            else {
              let value =
              {
                atheleteDetail: self.gridDataStore,
                accountId: localStorage.getItem('accountId') || '',
                cash: self.braintreeTransaction.cash,
                token: self.braintreeTransaction.token,
                user: self.braintreeTransaction.user,
                subscriptionPackageDetails: localStorage.getItem('packageListStore') != null ? JSON.parse(localStorage.getItem('packageListStore') || '') : "",
                isMonthlyPlan: localStorage.getItem('isMonthlyPlan') || '',
              }
              self.httpProvider.braintreeParentTransaction(value).subscribe(async data => {
                if (data.ok = true && data.body != null) {
                  self.spinner.hide();
                  let message = data.body;
                  localStorage.setItem('ispackDisable', "false");
                  let alert = self.alertCtrl.create({
                    header: 'Message',
                    message: message,
                    buttons: ['OK']
                  });
                  (await alert).onDidDismiss().then(e => {
                    let data = {
                      IsPlanChanged: true
                    }
                    let navigationExtras: NavigationExtras = { state: data };
                    self.spinner.show('Please wait...');
                      setTimeout(() => {
                        self.navCtrl.navigateBack(['profile/view'], navigationExtras);
                        self.spinner.hide();
                      }, 4000);
                  });
                  (await alert).present();
                }
                else {
                  self.spinner.hide();
                  let alert = self.alertCtrl.create({
                    header: 'Alert',
                    message: 'Sorry, your payment is failed. Please try again later.',
                    buttons: ['OK']
                  });
                  (await alert).present();
                  (await alert).onDidDismiss().then(async e => {
                    self.spinner.show('Please wait...');
                    self.router.navigate(['package'], { replaceUrl: true });
                    self.spinner.hide();
                  });
                }
              },
                async (error: any) => {
                  self.spinner.hide();
                  if (error.status == 401) {
                  }
                  else if (error.status == 417) {
                    var errorMessage = error.error;
                    let alert = self.alertCtrl.create({
                      header: 'Alert',
                      message: errorMessage,
                      buttons: ['OK']
                    });
                    (await alert).present();
                    (await alert).onDidDismiss().then(async e => {
                      self.spinner.show('Please wait...');
                      self.router.navigate(['package']);
                      self.spinner.hide();
                    });
                  }
                  else {
                    let alert = self.alertCtrl.create({
                      header: 'Alert',
                      message: 'Sorry, your payment is failed. Please try again later.',
                      buttons: ['OK']
                    });
                    (await alert).present();
                    (await alert).onDidDismiss().then(async e => {
                      self.spinner.show('Please wait...');
                      self.router.navigate(['package'], { replaceUrl: true });
                      self.spinner.hide();
                    });
                  }
                })
            }
          });
        })
      });
      self.spinner.hide();
    },
      async (error: any) => {
        self.spinner.hide();
        let alert = self.alertCtrl.create({
          header: 'Alert',
          message: "Something went wrong. Please try again later",
          buttons: ['Ok']
        });
        (await alert).present();
      });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }
}