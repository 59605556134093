import { Component, OnInit } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { IPublicShare } from '../interfaces/account-interface';
import { HttpProvider } from '../providers/http-provider.service';
import { ToasterService } from '../providers/toaster-service.service';
import { ShareCopyUrlComponent } from '../share-copy-url/share-copy-url.page';

@Component({
  selector: 'app-share-info-model',
  templateUrl: './share-info-model.page.html',
  styleUrls: ['./share-info-model.page.scss'],
})
export class ShareInfoModelPage implements OnInit {
  athleteDetailsForPublicShare: any;
  url: any;
  encryptedId: any;
  team: any;
  opponent: any;
  league: any;
  rlValue: any;
  startDate: any;
  endDate: any;
  publicShare: IPublicShare = {
    url: null,
    encryptedId: null,
    team: null,
    opponent: null,
    league: null,
    rlValue: null,
    startDate: null,
    endDate: null
  }
  constructor(public navCtrl: NavController, public navParams: NavParams,
    public httpProvider: HttpProvider, public modalCtrl: ModalController,
    private socialSharing: SocialSharing,  private toaster : ToasterService) {
    if (navParams != null) {
      this.url = navParams.data.url != undefined ? navParams.data.url : null;
      this.encryptedId = navParams.data.encryptedId != undefined ? navParams.data.encryptedId : null;
      this.team = navParams.data.team != undefined ? navParams.data.team : null;
      this.opponent = navParams.data.opponent != undefined ? navParams.data.opponent : null;
      this.league = navParams.data.league != undefined ? navParams.data.league : null;
      this.rlValue = navParams.data.rlValue != undefined ? navParams.data.rlValue : null;
      this.startDate = navParams.data.startDate != undefined ? navParams.data.startDate : null;
      this.endDate = navParams.data.endDate != undefined ? navParams.data.endDate : null;
    }
  }

  ngOnInit(): void {
    this.athleteDetailsForPublicShare = localStorage.getItem('athleteDetailsForShare') != null ? JSON.parse(localStorage.getItem('athleteDetailsForShare') || '') : "";
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }

  AcceptShareProfile(event: any) {
    this.modalCtrl.dismiss();
    this.publicShare =
    {
      url: this.url,
      encryptedId: this.encryptedId,
      team: this.team,
      opponent: this.opponent,
      league: this.league,
      rlValue: this.rlValue,
      startDate: this.startDate,
      endDate: this.endDate,
    }
    this.httpProvider.GetEncryptedAthleteId(this.publicShare).subscribe(async athletedata => {
      if (athletedata != null && athletedata.body != null) {
        var result = athletedata.body;
        if (result != null) {
          let data: any = [result.url, result.encryptedId];
          let modal = await this.modalCtrl.create({
            component: ShareCopyUrlComponent,
            componentProps: data,
            cssClass: "share-info-model",
          });
          await modal.present();
        }
      }
    }, (error: any) => {
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
     });
  }

  CancelShareProfile(event:any) {
    this.modalCtrl.dismiss();
  }

}
