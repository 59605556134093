import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

//declare function videojs(id: any, options: any, ready:any): any;

import videojs from 'video.js';


@Component({
  selector: 'videojs',
  templateUrl: './video-js.component.html',
  styleUrls: ['./video-js.component.scss'],
})
export class VideoJSComponent implements OnInit {

  // reference to the element itself, we use this to access events and methods
  private _elementRef: ElementRef | any;

  // index to create unique ID for component
  @Input() idx: string = '';

  // video asset url
  @Input() url: any;

  // declare player var
  private player: any;

  // constructor initializes our declared vars
  constructor(elementRef: ElementRef) {
    this.url = false;
    this.player = false;
  }

  ngOnInit() { 
    const videoElement: any = document.getElementById(this.idx);
  if (videoElement && videoElement.tagName === 'VIDEO') {
    const videoInstance: any = videojs(videoElement);
    videoInstance.dispose();
  } else {
    console.error('Video element or ID is invalid.');
  }
  }

  // use ngAfterViewInit to make sure we initialize the videojs element
  // after the component template itself has been rendered
  ngAfterViewInit() {
    let zoom = 1;
    let properties = ['transform', 'WebkitTransform', 'MozTransform',
      'msTransform', 'OTransform'];
    let zoomproperty = properties[0];
    let stage: any = document.getElementsByClassName('videoPlacementContainer')[0];
    for (var i = 0, j = properties.length; i < j; i++) {
      // Check if stage is defined before accessing its style property
      if (stage && typeof stage.style[properties[i]] !== 'undefined') {
        zoomproperty = properties[i];
        break;
      }
    }
    // ID with which to access the template's video element
    let el = 'video_' + this.idx;

    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(this.idx) as any, {
      controlBar: {
        children: [
          "playToggle",
          "volumePanel",
          "CurrentTimeDisplay",
          "DurationDisplay",
          "ProgressControl",
          "RemainingTimeDisplay",
          "FullscreenToggle",
          "PlaybackRateMenuButton",
          "constructor"
        ]
      },
    }, function (this: any) {
      this.playbackRates = [0.5, 1];
      // Store the video object
      var myPlayer = this as any, id = myPlayer.id();
      // Make up an aspect ratio
      var aspectRatio = 264 / 640;

      // internal method to handle a window resize event to adjust the video player
      function resizeVideoJS() {
        var parentElementObject = document.getElementById(id);
        if (parentElementObject != null && parentElementObject != undefined) {
          var width = (document.getElementById(id) as any).parentElement.offsetWidth;
          myPlayer.width(width);
          myPlayer.height(width * aspectRatio);
        }
      }

      var Button = videojs.getComponent('Button');
      var MyButton = videojs.extend(Button, {
        constructor: function (this : any) {
          Button.apply(this as any, arguments as any);
          this.controlText("Exit");
          this.addClass("vjs-close-button");
          /* initialize your button */
        },
        handleClick: function () {
          (document.getElementById(myPlayer.id_) as any).style.top = null;
          (document.getElementById(myPlayer.id_)as any).style.left = null;
          (document.getElementById(myPlayer.id_) as any).style.zIndex = null;
          (document.getElementById(myPlayer.id_) as any).style.position = null;
          (document.getElementById(myPlayer.id_) as any).style.height = null;
          (document.getElementById(myPlayer.id_) as any).style.paddingTop = "56.25%";
         
          let ionHeaderFooterLength =(document.getElementsByTagName('ion-header') as any).length;
          let ionFooterLength= (document.getElementsByTagName('ion-footer') as any).length;
          let playHeader: any = (document.getElementsByTagName('ion-header') as any) [ionHeaderFooterLength - 1];
          playHeader.style.display = "block";
          let playFooter: any = (document.getElementsByTagName('ion-footer') as any)[ionFooterLength - 1];
          playFooter.style.display = "block";
          myPlayer.controlBar.el_.style.bottom = '0%';
          let lengthBar = myPlayer.controlBar.children_.length;
          for (var i = 0; i < lengthBar; i++) {
            let myButtonValue: any = myPlayer.controlBar.children_[i];
            if (myButtonValue.name_ == "MyButton" || myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
              myButtonValue.el_.style.display = "none";
              let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
              element.style.transform = null;
            }
          }
          let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
          element.style.transform = null;
        }
      });

      videojs.registerComponent('MyButton', MyButton);
      myPlayer.getChild('controlBar').addChild('myButton', {});

      var Zoomin = videojs.extend(Button, {
        constructor: function (this : any) {
          Button.apply(this as any, arguments as any);
          this.controlText("Zoomin");
          this.addClass("vjs-add-button");
          /* initialize your button */
        },
        handleClick: function () {
          if (zoom < 2) {
            zoom = zoom + 0.2;
            let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
            element.style[zoomproperty] = 'scale(' + zoom + ') rotate(0deg)';
          }
        }
      });

      videojs.registerComponent('Zoomin', Zoomin);
      myPlayer.getChild('controlBar').addChild('Zoomin', {});

      var Zoomout = videojs.extend(Button, {
        constructor: function (this : any) {
          Button.apply(this as any , arguments as any);
          this.controlText("Zoomout");
          this.addClass("vjs-zoomout-button");
          /* initialize your button */
        },
        handleClick: function () {
          if (zoom > 1) {
            zoom = zoom - 0.2;
            let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
            element.style[zoomproperty] = 'scale(' + zoom + ') rotate(0deg)';
          }
        }
      });

      videojs.registerComponent('Zoomout', Zoomout);
      (myPlayer.getChild('controlBar') as any).addChild('Zoomout', {});

      myPlayer.on("play", function (this : any)  //function to play the video again//
      {
        var videoDetails = localStorage.getItem("videoStoredDetail") || '';
        if (videoDetails != null && videoDetails != undefined) {
          if (videoDetails != myPlayer.id_) {
            videojs(videoDetails).currentTime(0);
            videojs(videoDetails).pause();
            videojs(videoDetails).controlBar.hide(); //hides your controls//
            videojs(videoDetails).bigPlayButton.show();
            localStorage.setItem("videoStoredDetail", myPlayer.id_);
            videojs(myPlayer.id_).play();
          }
        }
        else {
          localStorage.setItem("videoStoredDetail", myPlayer.id_);
          console.log(myPlayer.id_);
        }
        let lengthBar = myPlayer.controlBar.children_.length;
        for (var i = 0; i < lengthBar; i++) {
          let myButtonValue = myPlayer.controlBar.children_[i];
          if (myButtonValue.name_ == "MyButton" || myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
            myButtonValue.el_.style.display = "block";
          }
        }
        (document.getElementById(this.id_) as any).style.top = "0px";
        (document.getElementById(this.id_) as any).style.left = "0px";
        (document.getElementById(this.id_) as any).style.zIndex = "999";
        (document.getElementById(this.id_) as any).style.position = "fixed";
        (document.getElementById(this.id_) as any).style.height = "100%";
        (document.getElementById(this.id_) as any).style.paddingTop = "0px";
        localStorage.setItem("isVideoPlaying","true");
        
        if (myPlayer.isFullscreen()) {
          this.controlBar.el_.style.bottom = '0%';
        }
        else {
          if (window.matchMedia('(min-width: 768px)').matches && !((window.innerWidth == 1366 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 1366) || (window.innerWidth == 768 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 768))) {
            this.controlBar.el_.style.bottom = '0%'; // For tabs
          }
          else {
            this.controlBar.el_.style.bottom = '0%';
          }
        }
        let ionHeaderFooterLength = (document.getElementsByTagName('ion-header') as any).length;
        let ionFooterLength= (document.getElementsByTagName('ion-footer') as any).length;
        let playHeader: any = (document.getElementsByTagName('ion-header') as any)[ionHeaderFooterLength - 1];
        playHeader.style.display = "none";
        let playFooter: any = (document.getElementsByTagName('ion-footer') as any)[ionFooterLength - 1];
        playFooter.style.display = "none";
        myPlayer.controlBar.show(); //shows your controls//
        myPlayer.bigPlayButton.hide();
      });

      myPlayer.on('pause', function () {
      });

      myPlayer.on("ended", function () {
        myPlayer.currentTime(0);
        myPlayer.controlBar.show(); //shows your controls//
        myPlayer.bigPlayButton.show(); //shows your play button//        
      });

      myPlayer.on('fullscreenchange', function ( this : any) {
        zoom = 1;
        // do stuff...
        if (myPlayer.isFullscreen()) {
          this.controlBar.el_.style.bottom = '0%';
          let lengthBar = myPlayer.controlBar.children_.length;
          for (var i = 0; i < lengthBar; i++) {
            let myButtonValue: any = myPlayer.controlBar.children_[i];
            if (myButtonValue.name_ == "MyButton") {
              myButtonValue.el_.style.display = "none";
            }
            if (myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
              myButtonValue.el_.style.display = "block";
            }
          }
        }
        else {
          let positionFound: any = document.getElementById(myPlayer.id_) as any;
          if (positionFound.style.position == "fixed") {
            if (window.matchMedia('(min-width: 768px)').matches && !((window.innerWidth == 1366 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 1366) || (window.innerWidth == 768 && window.innerHeight == 1024 || window.innerWidth == 1024 && window.innerHeight == 768))) {
              this.controlBar.el_.style.bottom = '0%'; // For tabs
            }
            else {
              this.controlBar.el_.style.bottom = '0%';
            }
            let lengthBar = myPlayer.controlBar.children_.length;
            for (var i = 0; i < lengthBar; i++) {
              let myButtonValue: any = myPlayer.controlBar.children_[i];
              if (myButtonValue.name_ == "MyButton" || myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
                myButtonValue.el_.style.display = "block";
              }
            }
          }
          else {
            this.controlBar.el_.style.bottom = '0%';
            let lengthBar = myPlayer.controlBar.children_.length;
            for (var i = 0; i < lengthBar; i++) {
              let myButtonValue: any = myPlayer.controlBar.children_[i];
              if (myButtonValue.name_ == "Zoomin" || myButtonValue.name_ == "Zoomout") {
                myButtonValue.el_.style.display = "none";
                let element: any = (document.getElementById(myPlayer.id_) as any).children[0]
                element.style.transform = null;
              }
            }
          }
        }
      });
      // Initialize resizeVideoJS()
      resizeVideoJS();

      // Then on resize call resizeVideoJS()
      window.onresize = resizeVideoJS;
      document.addEventListener("pause", () => {
        myPlayer.pause();
      }, false);
    });
  }

}