import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';

import { NavController, ModalController, NavParams, AlertController, ToastController,  } from '@ionic/angular';

import { NavigationExtras, Router } from '@angular/router';
import { IAthleteVideoDetails } from '../interfaces/account-interface';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { DataSharingService } from '../providers/data-sharing.service';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-category-model',
  templateUrl: './category-model.page.html',
  styleUrls: ['./category-model.page.scss'],
})
export class CategoryModelPage implements OnInit {
  loading: any;
  header_data: any;
  isAlreadyLoggedIn: Boolean = false;
  screenType: any;
  setMarginForButtons: any;
  platformMobile: Boolean = false;
  setPaddingForVideoContainer: any;
  setMarginTopForVideoTypeContainer: any;
  setPaddingPaddingContinue: any;
  buttonOffenseActiveClass: string="";
  buttonDefenseActiveClass: string="";
  buttonTrainingActiveClass: string ="";
  buttonWorkActiveClass: string ="";
  videoCategory: string ="";
  videoFile: any;
  uploadedVideoPath: any;
  videoThumbnailURL: any;
  isWaiverfield: any;
  videolocationPath: any;
  videoTitle: any;
  isEditCategory: any;
  isUnSyncedFile: any;
  rawFileName: any;
  transcodedFileName: any;
  video_id: any;
  ipostTv: boolean = false;
  categoryCopy: any;
  waiverfieldCopy: boolean = false;
  athlete_id: any;
  athleteVideoDetails: IAthleteVideoDetails = {
    id: 0,
    athlete_id: 0,
    video_id: 0,
    raw_file_name: '',
    transcoded_file_name: '',
    category_code: '',
    ipost_tv: '',
    video_title: '',
    localPath: '',
    account_id: 0,
    modificationDate: null,
    created_date: null,
    video_size:0,
    file_blob:undefined,
    upload_date:null
  }
  modificationDate: any;
  ipostTV: any;
  fileSize: any;
  titleText: any;
  localFilePath: any;
  isUploaded: boolean = false;

  @ViewChild('TvPost') public checkbox: CheckBoxComponent | any;
  constructor(public navCtrl: NavController, public accountService: AccountService,
    public httpProvider: HttpProvider, public router: Router,
    public modalCtrl: ModalController, public navParams: NavParams, private spinner: SpinnerService,
    public alertCtrl: AlertController, public toastCtrl: ToastController, public dataSharing: DataSharingService) { 
      let waiverfield = localStorage.getItem('waiverfield');
      if (waiverfield != null) {
        this.isWaiverfield = waiverfield == "1" ? true : false;
      }
      else {
        this.isWaiverfield = false;
      }
      if (navParams != null) {
        this.athlete_id = navParams.data.athlete_id != undefined ? navParams.data.athlete_id : null;
        let isEditable = navParams.data.isEdit != undefined ? navParams.data.isEdit : null;
        this.isEditCategory = isEditable != null ? true : false;
        this.videoTitle = navParams.data.videoTitle != undefined ? navParams.data.videoTitle : null;
        this.video_id = navParams.data.videoId != undefined ? navParams.data.videoId : null;
        this.rawFileName = navParams.data.rawFileName != undefined ? navParams.data.rawFileName : null;
        this.transcodedFileName = navParams.data.transcodedFileName != undefined ? navParams.data.transcodedFileName : null;
        this.fileSize = navParams.data.fileSize != undefined ? navParams.data.fileSize : null;
        this.videoCategory = navParams.data.videoCategory != undefined ? navParams.data.videoCategory : null;
        this.categoryCopy = navParams.data.videoCategory != undefined ? navParams.data.videoCategory : null;
        this.modificationDate = navParams.data.modification_date != undefined ? navParams.data.modification_date : null;
        if (this.videoCategory != null) {
          switch (this.videoCategory) {
            case 'D': this.defenseClick();
              break;
            case 'O': this.offenseClick();
              break;
            case 'T': this.trainingClick();
              break;
            case 'W': this.workClick();
              break;
          }
        }
        else {
          this.buttonOffenseActiveClass = "buttonsStyles";
          this.buttonDefenseActiveClass = "buttonsStyles";
          this.buttonTrainingActiveClass = "buttonsStyles";
          this.buttonWorkActiveClass = "buttonsStyles";
        }
  
        if (navParams.data.ipostTV != undefined && this.isWaiverfield) {
          this.ipostTv = navParams.data.ipostTV == 'Y' ? true : false;
          this.waiverfieldCopy = navParams.data.ipostTV == 'Y' ? true : false;
        }
      }
    }

  ngOnInit() {}

  UpdateCategory() {
    this.spinner.show("Updating Category...");
    this.athleteVideoDetails.athlete_id = this.athlete_id;
    this.athleteVideoDetails.category_code = this.videoCategory;
    this.athleteVideoDetails.ipost_tv = (this.isWaiverfield && this.checkbox.checked) ? "Y" : "N";
    this.athleteVideoDetails.video_title = this.videoTitle;
    this.athleteVideoDetails.raw_file_name = this.rawFileName;
    this.athleteVideoDetails.transcoded_file_name = this.transcodedFileName;
    this.athleteVideoDetails.video_id = this.video_id;
    this.athleteVideoDetails.created_date = this.modificationDate;
    this.httpProvider.UpdateAthleteVideos(this.athleteVideoDetails).subscribe((data: any) => {
      setTimeout(() => {
        this.modalCtrl.dismiss();
        this.spinner.hide();
        let navigationExtras: NavigationExtras = {
          state: { enableEdit: true },
        };
        this.router.navigate(["profile/edit"], { replaceUrl: true, ...navigationExtras });
        this.dataSharing.isUpdatingVideos.next(true);
      }, 300);
    }, (error : any) => {
      this.spinner.hide();
      alert("Something went wrong");
      console.log(error);
    });
  }

  cancelUpload() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  offenseClick() {
    this.buttonOffenseActiveClass = "buttonsActiveStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.videoCategory = "O";
  }

  defenseClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsActiveStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.videoCategory = "D";

  }

  trainingClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsActiveStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.videoCategory = "T";
  }

  workClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsActiveStyles";
    this.videoCategory = "W";
  }

}
