import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-waiver-model',
  templateUrl: './waiver-model.page.html',
  styleUrls: ['./waiver-model.page.scss'],
})
export class WaiverModelPage implements OnInit {
  clickedParent: Boolean = false;
  accountId: any;
  waiver_field: any;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    private alertCtrl: AlertController,
    public accountService: AccountService,
    public httpProvider: HttpProvider,
    public modalCtrl: ModalController,
    private router: Router, private toaster : ToasterService) {
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }

  AcceptWaiver(event: any) {
    this.modalCtrl.dismiss();
    this.accountId = localStorage.getItem('accounIdStore') || '';
    this.waiver_field = event.srcElement.innerText;
    this.httpProvider.waiverSigninVerification(this.waiver_field, this.accountId).subscribe((data: any) => {
      if (data != null && data.body != null) {
        var resultData = data.body;
        if (resultData == 1) {
          localStorage.setItem('waiverfield', resultData);         
          this.router.navigate(['package'], { replaceUrl: true });;
        }
      }
    }, (error: any) => {
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }


  DeclineWaiver(event: any) {
    this.modalCtrl.dismiss();
    this.accountId = localStorage.getItem('accounIdStore') || '';
    this.waiver_field = event.srcElement.innerText;
    this.httpProvider.waiverSigninVerification(this.waiver_field, this.accountId).subscribe((data: any) => {
      if (data != null && data.body != null) {
        var resultData = data.body;
        if (resultData == 1) {
          localStorage.setItem('waiverfield', resultData);
          this.router.navigate(['package'], { replaceUrl: true });;
        }
      }
    }, (error: any) => {
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }
}