// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-base-content {
    height: 75px;
    width: 100%;
    background-color: black;
    color: white;
    text-align: left;
    padding: 1%;
    position: sticky;
    bottom: 30px;
}

.hoverClick{
    color:white; 
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
}

.hoverClick:hover {
    opacity: 0.7;
}

.label1 {
    color: gray;
    font-size: 14px;
    margin-top: 0px;
}

@media screen and (min-width: 416px) and (max-width: 1024px) {
    .login-base-content {
        padding: 1% !important;

        .login-base-content-right {
        }

        .login-base-content-left {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 415px) and (max-height: 815px) {
    .login-base-content {
        padding: 1% !important;

        .login-base-content-right {
        }

        .login-base-content-left {
            width: 100% !important;
        }
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
    .login-base-content {
        padding: 1% !important;

        .login-base-content-right {
        }

        .login-base-content-left {
            width: 100% !important;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/app/common-footer/common-footer.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;;QAEtB;QACA;;QAEA;YACI,sBAAsB;QAC1B;IACJ;AACJ;;AAEA;IACI;QACI,sBAAsB;;QAEtB;QACA;;QAEA;YACI,sBAAsB;QAC1B;IACJ;AACJ;;AAEA;IACI;QACI,sBAAsB;;QAEtB;QACA;;QAEA;YACI,sBAAsB;QAC1B;IACJ;AACJ","sourcesContent":[".login-base-content {\n    height: 75px;\n    width: 100%;\n    background-color: black;\n    color: white;\n    text-align: left;\n    padding: 1%;\n    position: sticky;\n    bottom: 30px;\n}\n\n.hoverClick{\n    color:white; \n    cursor: pointer;\n    font-size: 12px;\n    font-style: italic;\n}\n\n.hoverClick:hover {\n    opacity: 0.7;\n}\n\n.label1 {\n    color: gray;\n    font-size: 14px;\n    margin-top: 0px;\n}\n\n@media screen and (min-width: 416px) and (max-width: 1024px) {\n    .login-base-content {\n        padding: 1% !important;\n\n        .login-base-content-right {\n        }\n\n        .login-base-content-left {\n            width: 100% !important;\n        }\n    }\n}\n\n@media screen and (max-width: 415px) and (max-height: 815px) {\n    .login-base-content {\n        padding: 1% !important;\n\n        .login-base-content-right {\n        }\n\n        .login-base-content-left {\n            width: 100% !important;\n        }\n    }\n}\n\n@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {\n    .login-base-content {\n        padding: 1% !important;\n\n        .login-base-content-right {\n        }\n\n        .login-base-content-left {\n            width: 100% !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
