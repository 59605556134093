import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { IAthlete } from '../interfaces/account-interface';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { AlertService } from '../providers/alert-service.service';
import { BirthDateProvider } from '../providers/birth-date.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';
import { onResize } from '@syncfusion/ej2-angular-grids';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.page.html',
  styleUrls: ['./account-settings.page.scss'],
})
export class AccountSettingsPage implements OnInit {

  athleteData: IAthlete = {
    athlete_id: 0, account_id: 0, first_name: '', last_name: '', birthdate: new Date(), gender: '', address1: '', address2: '', city: '',
    athlete_state_code: '', zipcode: 0, zip4: 0, area_code_phone: 0, phone: 0, email: '', parent_first_name: '', parent_last_name: '', parent_address1: '', parent_address2: '',
    parent_city: '', parent_state_code: '', parent_zipcode: 0, parent_zip4: 0, parent_email: '', parent_area_code_phone: 0, parent_phone: 0, school_team_name: '',
    graduation_year: null, school_name: '', grade_level: '', parentPhoneNumber: '', parentzipCode: '', athletePhoneNumber: '', athletezipCode: '', isparent: false, birthDate: ''
  };

  athleteDataDuplicate: IAthlete = {
    athlete_id: 0, account_id: 0, first_name: '', last_name: '', birthdate: new Date(), gender: '', address1: '', address2: '', city: '',
    athlete_state_code: '', zipcode: 0, zip4: 0, area_code_phone: 0, phone: 0, email: '', parent_first_name: '', parent_last_name: '', parent_address1: '', parent_address2: '',
    parent_city: '', parent_state_code: '', parent_zipcode: 0, parent_zip4: 0, parent_email: '', parent_area_code_phone: 0, parent_phone: 0, school_team_name: '',
    graduation_year: null, school_name: '', grade_level: '', parentPhoneNumber: '', parentzipCode: '', athletePhoneNumber: '', athletezipCode: '', isparent: false, birthDate: ''
  };

  isParent: Boolean = false;
  submitted = false;
  accountId: number = 0;
  loading: any;
  athleteProfileList: any;
  careerSubmitted = false;
  hideEdit: Boolean = false;
  hideButtons: Boolean = false;
  isDisabled: Boolean = true;
  isEmailValid: Boolean = false;
  oldEmail: string = "";
  isEmailChanged: Boolean = false;
  isAlreadyLoggedIn: Boolean = false;
  accountTypeId: number = 0;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();

  public diffForParent = this.currentYear - 5;
  public diffForAthlete = this.currentYear - 13;

  public parentMaxDate: Date = new Date(this.diffForParent, this.currentMonth, this.currentDay);
  public athleteMaxDate: Date = new Date(this.diffForAthlete, this.currentMonth, this.currentDay);
  footerYear: any = new Date().getFullYear();
  isZipValid: Boolean = false;
  isParentPhoneValid: Boolean = false;
  isAthletePhoneValid: Boolean = false;
  colorChange: string = "";
  parentPhoneNumberStore: any;
  athletePhoneNumberStore: any;
  public phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  invalidBirthDate: boolean = false;
  isValidData: boolean = false;
  isFirstNameValid: boolean = false;
  isLastNameValid: boolean = false;
  hidePage: any;
  isMobileView: boolean = false;
  webSize: boolean = false;

  @ViewChild('athleteBirthDate')
  public athleteBirthDate!: DatePickerComponent;
  windowWidth: number | any;
  constructor(public accountService: AccountService, public httpProvider: HttpProvider,
    public modalCtrl: ModalController, private titleService: Title,
    public platform: Platform,
    private alertService: AlertService,
    private spinner: SpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertCtrl: AlertController,
    private toaster: ToasterService,
    private birthDateProvider: BirthDateProvider,
    private navcontroller: NavController) {
     
      if (( this.platform.is('android') || this.platform.is('ios')) && !(this.platform.is('tablet'))) {
        this.isMobileView= true;
      }
      else {
        this.isMobileView = false;
        this.webSize = true;
      }
      if (this.webSize == true) {
        if (window.matchMedia('(max-width: 800px)').matches) {
          this.isMobileView = true;
        }
      }
      
    this.isAlreadyLoggedIn = this.accountService.hasLoggedIn();
    if (this.isAlreadyLoggedIn === true) {
      localStorage.setItem('storedURL', 'settings');
      this.GetAllStates();
      this.GetLoggedUserEmail();
      this.LoadDetails(); 
    }
    else {
      this.accountService.logout();
      this.router.navigate(['login'], { replaceUrl: true });
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowWidth = window.innerWidth;
    console.log('Window resized, new width:', this.windowWidth);
    if(this.windowWidth < 600){
      this.isMobileView = true;
    }else{
      this.isMobileView = false;
    }
  }

  ngOnInit() {
    var checkAccountId = this.accountService.getAccountId();
    this.hidePage = localStorage.getItem('hideMenuPage') != null ? JSON.parse(localStorage.getItem('hideMenuPage') || '') : "";
    if (checkAccountId != null && checkAccountId != undefined) {
      this.accountId = parseInt(this.accountService.getAccountId());
      this.httpProvider.GetAccountType(this.accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData != null && resultData > 0 && resultData === 5) {
            this.isParent = true;
          }
          else if (resultData === 6) {
            this.isParent = false;
          }
          else if (resultData == null) {
            this.accountService.logout();
            this.router.navigate(['login'], { replaceUrl: true });
          }
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });

    }
    this.titleService.setTitle('Account Settings | iPost Sports');
    localStorage.setItem('ispackDisable', "false");
  }

  OndocumentClick(e: { srcElement: { className: string | string[]; }; }) {
    if (e.srcElement != undefined && e.srcElement.className.indexOf("e-ddl") == -1) {
      let collect: any = document.getElementsByClassName("e-dropdownlist");
      for (var i = 0; i < collect.length; i++) {
        collect[i].ej2_instances[0].hidePopup();
        collect[i].ej2_instances[0].focusOut();
      }
    }
  }

  public genderWaterMark: string = 'Select a Gender';
  public stateWaterMark: string = 'Select a State';

  public stateList: any;
  public stateFields: Object = { text: 'state_name', value: 'state_code' };
  public genderList: Object[] = [
    { Id: "M", Text: 'Male' },
    { Id: "F", Text: 'Female' }
  ];

  public genderFields: Object = { text: 'Text', value: 'Id' };


  GetAllStates() {
    this.httpProvider.GetAllStates().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.stateList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          resultData[i].state_code = resultData[i].state_code.toString();
        }
        this.stateList = resultData;
        this.setOpacity(2);
      }
    }, (error: any) => {
      if (error.status == 401) {
        this.accountService.logout();
        //this.app.getRootNav().popToRoot();
      }
      else if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  LoadDetails() {
    this.colorChange = 'disableColor';
    this.spinner.show('Please wait...');
    //Load AthleteDetails
    var checkAccountId = this.accountService.getAccountId();
    if (checkAccountId != null && checkAccountId != undefined) {
      this.accountId = parseInt(this.accountService.getAccountId());
      this.httpProvider.GetAccountType(this.accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData != null && resultData > 0 && resultData === 5) {
            this.isParent = true;
          }
          else if (resultData === 6) {
            this.isParent = false;
          }
          this.httpProvider.GetAthleteOrParentDetails(this.accountId).subscribe((data: any) => {
            if (data != null && data.body != null) {
              var resultData = data.body;
              if (resultData != null) {
                this.athleteData = resultData;
                this.athleteDataDuplicate = Object.assign({}, resultData);
                if (this.isParent) {
                  this.oldEmail = resultData.parent_email;
                  this.athleteData.parent_state_code = resultData.parent_state_code != null ? resultData.parent_state_code.toString() : null;
                  this.athleteData.parentPhoneNumber =  resultData.parent_area_code_phone + "" + resultData.parent_phone;
                  this.parentPhoneNumberStore = resultData.parent_area_code_phone + "" + resultData.parent_phone;
                  if (resultData.parent_zip4 > 0) {
                    this.athleteData.parentzipCode = resultData.parent_zipcode + "-" + resultData.parent_zip4;
                  }
                  else {
                    this.athleteData.parentzipCode = resultData.parent_zipcode;
                  }
                  this.athleteData.birthdate = new Date(new Date(resultData.birthdate).getUTCFullYear(), new Date(resultData.birthdate).getUTCMonth(), new Date(resultData.birthdate).getUTCDate());
                  this.setOpacity(2);
                }
                else {
                  this.oldEmail = resultData.email;
                  this.athleteData.birthdate = new Date(new Date(resultData.birthdate).getUTCFullYear(), new Date(resultData.birthdate).getUTCMonth(), new Date(resultData.birthdate).getUTCDate());
                  //this.athleteData.birthdate = new Date(resultData.birthdate);
                  this.athleteData.athlete_state_code = resultData.athlete_state_code != null ? resultData.athlete_state_code.toString() : null;
                  this.athleteData.athletePhoneNumber = resultData.area_code_phone + "" + resultData.phone;
                  this.athletePhoneNumberStore = resultData.area_code_phone + "" + resultData.phone;
                  if (resultData.zip4 > 0) {
                    this.athleteData.athletezipCode = resultData.zipcode + "-" + resultData.zip4;
                  }
                  else {
                    this.athleteData.athletezipCode = resultData.zipcode;
                  }
                  this.setOpacity(2);
                }

              }
              else {
                localStorage.setItem('ispackDisable', "true");
                localStorage.setItem('accountSettingsPage', "true");
                this.router.navigate(['type']);
              }
            }
          },
            async (error: any) => {
              if (error && error.message) {
                this.toaster.showFailureToast(error.message);
              }
            });
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });
    }
    this.spinner.hide();
  }

  GetLoggedUserEmail() {
    var accountId = this.accountService.getAccountId();
    if (accountId != null && accountId != undefined) {
      this.httpProvider.GetLoggedUserEmail(accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          this.athleteData.parent_email = resultData;
          this.athleteData.email = resultData;
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });
    }
  }

  EditDetails(e: any) {
    this.hideEdit = true;
    this.hideButtons = true;
    this.isDisabled = false;
    this.colorChange = 'enableColor';
    this.setOpacity(1);
  }

  settingscancel(e: any) {
    this.submitted = false;
    this.careerSubmitted = false;
    this.hideEdit = false;
    this.hideButtons = false;
    this.isDisabled = true;
    this.colorChange = 'disableColor';
    this.setOpacity(2);
    this.athleteData = this.athleteDataDuplicate;
    if (this.isParent) {
      let duplicateParentStateCode: any = this.athleteDataDuplicate.parent_state_code != null ? this.athleteDataDuplicate.parent_state_code.toString() : null;
      this.athleteData.parent_state_code = duplicateParentStateCode;
      this.athleteData.parentPhoneNumber =  this.athleteDataDuplicate.parent_area_code_phone + ""+this.athleteDataDuplicate.parent_phone;
      this.parentPhoneNumberStore = this.athleteDataDuplicate.parent_area_code_phone +""+ this.athleteDataDuplicate.parent_phone;
      if (this.athleteDataDuplicate.parent_zip4 > 0) {
        this.athleteData.parentzipCode = this.athleteDataDuplicate.parent_zipcode + "-" + this.athleteDataDuplicate.parent_zip4;
      }
      else {
        this.athleteData.parentzipCode = this.athleteDataDuplicate.parent_zipcode.toString();
      }
    }
    else {
      this.athleteData.birthdate = new Date(this.athleteDataDuplicate.birthdate);
      let duplicateAthleteStateCode: any = this.athleteDataDuplicate.athlete_state_code != null ? this.athleteDataDuplicate.athlete_state_code.toString() : null;
      this.athleteData.athlete_state_code = duplicateAthleteStateCode;
      this.athleteData.athletePhoneNumber =  this.athleteDataDuplicate.area_code_phone + "" + this.athleteDataDuplicate.phone;
      this.athletePhoneNumberStore = this.athleteDataDuplicate.area_code_phone + "" + this.athleteDataDuplicate.phone;
      if (this.athleteDataDuplicate.zip4 > 0) {
        this.athleteData.athletezipCode = this.athleteDataDuplicate.zipcode + "-" + this.athleteDataDuplicate.zip4;
      }
      else {
        this.athleteData.athletezipCode = this.athleteDataDuplicate.zipcode.toString();
      }
    }
    this.athleteDataDuplicate = Object.assign({}, this.athleteDataDuplicate);
  }

  SettingsDone(e: any, form: NgForm) {
    this.submitted = true;
    this.careerSubmitted = true;
    if (this.athleteData.parentPhoneNumber != null && this.athleteData.parentPhoneNumber != undefined) {
      if (this.parentPhoneNumberStore != this.athleteData.parentPhoneNumber) {
        if (!(this.athleteData.parentPhoneNumber.length == 10 && this.athleteData.parentPhoneNumber.charAt(0) != "0")) {
          this.isParentPhoneValid = true;
          this.careerSubmitted = false;
        }
        else {
          this.isParentPhoneValid = false;
        }
      }
    }

    if (this.athleteData.athletePhoneNumber != null && this.athleteData.athletePhoneNumber != undefined) {
      if (this.athletePhoneNumberStore != this.athleteData.athletePhoneNumber) {
        if (!(this.athleteData.athletePhoneNumber.length == 10 && this.athleteData.athletePhoneNumber.charAt(0) != "0")) {
          this.isAthletePhoneValid = true;
          this.careerSubmitted = false;
        }
        else {
          this.isAthletePhoneValid = false;
        }
      }
    }

    if (form.valid && this.careerSubmitted == true && !this.isEmailValid && (this.isFirstNameValid == false && this.isLastNameValid == false)) {
      this.invalidBirthDate = this.birthDateProvider.ValidateBirthDate(this.athleteData.birthdate, this.isParent);
      if (!this.invalidBirthDate) {
        this.athleteData.birthDate = this.athleteData.birthdate.toDateString();
        this.spinner.show('Please wait...');

        if (this.isParent) {
          if (this.oldEmail == this.athleteData.parent_email) {
            this.isEmailChanged = false;
          }
          else {
            this.isEmailChanged = true;
          }
          let parentZip = this.athleteData.parentzipCode.toString().split("-")[0];
          let parentZip4 = this.athleteData.parentzipCode.toString().split("-")[1];
          let parentPhone = this.athleteData.parentPhoneNumber.substring(3);
          let parentPhoneCode = this.athleteData.parentPhoneNumber.substring(0, 3);

          this.athleteData.parent_zipcode = + parentZip;
          this.athleteData.parent_zip4 = + parentZip4;
          this.athleteData.parent_phone = + parentPhone;
          this.athleteData.parent_area_code_phone = + parentPhoneCode;
        }
        else {
          if (this.oldEmail == this.athleteData.email) {
            this.isEmailChanged = false;
          }
          else {
            this.isEmailChanged = true;
          }
          let athletePhone = this.athleteData.athletePhoneNumber.substring(3);
          let athletePhoneCode = this.athleteData.athletePhoneNumber.substring(0, 3);
          let athleteZip = this.athleteData.athletezipCode.toString().split("-")[0];
          let athleteZip4 = this.athleteData.athletezipCode.toString().split("-")[1];
          this.athleteData.zipcode = + athleteZip;
          this.athleteData.zip4 = + athleteZip4;
          this.athleteData.phone = + athletePhone;
          this.athleteData.area_code_phone = + athletePhoneCode;
        }

        this.athleteData.isparent = this.isParent;
        this.athleteData.account_id = this.accountId;
        this.athleteData.birthdate = new Date((this.athleteData.birthdate.getMonth() + 1).toString() + '/' + this.athleteData.birthdate.getDate() + '/' + this.athleteData.birthdate.getFullYear() + ' 12:00 PM');
        this.httpProvider.saveAccountSettingsDetails(this.athleteData).subscribe(async data => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData != null && resultData.IsSuccess == true) {
              this.spinner.hide();
              if (this.isEmailChanged) {
                let alert = this.alertCtrl.create({
                  header: 'Message',
                  message: "A verification email has been sent to your updated email with activation link.",
                  buttons: ['OK']
                });
                (await alert).present();
                (await alert).onDidDismiss().then(e => {
                  this.router.navigate(['login'], { replaceUrl: true });
                  localStorage.setItem('storedURL', 'login');
                });
              }
              else {
                if (this.hidePage == true && this.hidePage != undefined && this.hidePage != null) {
                  this.navcontroller.navigateBack(['profile/view']);
                }
                else if (this.hidePage == false && this.hidePage != undefined && this.hidePage != null) {
                  this.navcontroller.navigateBack(['package']);
                }
              }
            }
            else {
              if (resultData != null && resultData.IsSuccess == false && resultData.Message != null && resultData.Message != "") {
                this.spinner.hide();
                this.alertService.showAlertWithOkBtn('Alert', resultData.Message);
              }
              else {
                this.spinner.hide();
                if (this.hidePage == true && this.hidePage != undefined && this.hidePage != null) {
                  this.navcontroller.navigateBack(['profile/view']);
                }
                else if (this.hidePage == false && this.hidePage != undefined && this.hidePage != null) {
                  this.navcontroller.navigateBack(['package']);
                }
              }
            }
          }
        }, (error: any) => {
          if (error.status == 401) {
            this.accountService.logout();
          }
          else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          }
        });
      }
      else {
        var last_name = (document.getElementById('last_name') as any);
        last_name.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  backButtonAction() {
    window.history.back();
  }

  validateNumber(event: any) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  };

  setOpacity(arg: any) {
    setTimeout(() => {
      this.spinner.show("Please wait...");
      var parentstate = document.getElementById("parentstate") as any;
      var parentstateChildren: any = parentstate != undefined ? parentstate.shadowRoot.children : undefined;

      var parentGender = document.getElementById("parentGender") as any;
      var parentGenderChildren: any = parentGender != undefined ? parentGender.shadowRoot.children : undefined;
      if (arg == 1) { // Enable
        if (parentstateChildren != undefined && parentstateChildren.length > 0) {
          parentstateChildren[0].style.opacity = 1;
          parentstateChildren[1].style.opacity = 1;
        }
        if (parentGenderChildren != undefined && parentGenderChildren.length > 0) {
          parentGenderChildren[0].style.opacity = 1;
          parentGenderChildren[1].style.opacity = 1;
        }
      }
      else if (arg == 2) { //Disable
        if (parentstateChildren != undefined && parentstateChildren.length > 0) {
          parentstateChildren[0].style.opacity = 0.33;
          parentstateChildren[1].style.opacity = 0.33;
        }
        if (parentGenderChildren != undefined && parentGenderChildren.length > 0) {
          parentGenderChildren[0].style.opacity = 0.33;
          parentGenderChildren[1].style.opacity = 0.33;
        }
      }
      this.spinner.hide();
    }, 1000);
  }

  onEmailChange(event: any) {
    this.isEmailValid = this.accountService.emailValidationFunction(event);
  }

  onFirstNameChange(event: any) {
    this.isFirstNameValid = this.accountService.validateEnteredData(event);
  }

  onLastNameChange(event: any) {
    this.isLastNameValid = this.accountService.validateEnteredData(event);
  }

  onPhoneNumberChange(event: any) {
    if (event != null && event != undefined && event.value != null && event.value != undefined && event.value.length > 0) {
      if (event.value.length == 10 && event.value.charAt(0) != "0") {
        if (this.isParent) {
          this.isParentPhoneValid = false;
        } else {
          this.isAthletePhoneValid = false;
        }
      }
      else {
        if (this.isParent) {
          this.isParentPhoneValid = true;
        } else {
          this.isAthletePhoneValid = true;
        }
      }
    }
  }

  ionViewDidEnter() {}

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if(!this.isParent){
    (this.athleteBirthDate as any).hide();
    }
  }
}