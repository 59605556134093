import { Component, ViewChild, ElementRef, HostListener } from "@angular/core";

import { Platform, PopoverController, ModalController } from "@ionic/angular";
import {
  ChartComponent,
  IPointRenderEventArgs,
  ILoadedEventArgs,
} from "@syncfusion/ej2-angular-charts";
import { Title } from "@angular/platform-browser";
import { Browser } from "@syncfusion/ej2-base";

import {
  DateRangePickerComponent,
  DatePickerComponent,
} from "@syncfusion/ej2-angular-calendars";

import { HttpProvider } from "../providers/http-provider.service";
import { SpinnerService } from "../providers/spinner-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { AccountService } from "../providers/account-service.service";
import { AlertService } from "../providers/alert-service.service";
import { ShareInfoModelPage } from "../share-info-model/share-info-model.page";
import { DataSharingService } from "../providers/data-sharing.service";
import { ToasterService } from "../providers/toaster-service.service";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import {
  IHeightWeight,
  IBbSbDetails,
  IAthlete,
  IAcademicAccomplishment,
  IAthleticAccomplishment,
  IFiles,
  IDetailedStats,
  IShareProfileDetails,
  IPublicShare,
  IHighlightUploadVideoDetails,
} from "../interfaces/account-interface";
import { UpdateAthleteInfoPage } from "../update-athlete-info/update-athlete-info.page";
import { ShareCopyUrlComponent } from "../share-copy-url/share-copy-url.page";
import { ContextMenuPage } from "../context-menu/context-menu.page";
import { DropDownListComponent } from "@syncfusion/ej2-angular-dropdowns";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.page.html",
  styleUrls: ["./view-profile.page.scss"],
})
export class ViewProfilePage {
  heightWeight: IHeightWeight = {
    athlete_id: 0,
    entry_date: new Date(),
    height_feet: 0,
    height_inches: 0,
    weight_pounds: 0,
    height: "",
    weight: "",
  };
  bbSbDetails: IBbSbDetails = {
    athlete_id: 0,
    bats: "",
    throws: "",
    league1_sports_position_code: 0,
    league1_sports_focus_code: 0,
    league2_sports_position_code: 0,
    league2_sports_focus_code: 0,
    league1_type: "",
    league1_name: "",
    league2_type: "",
    league2_name: "",
    grade_level: "",
    affiliate_id: 0,
    name: "",
    sports_postion_name: "",
    positionCode: "",
    sports_position_club_name: "",
    TrainingCenter: "",
    league1PositionName: "",
    league2PositionName: "",
    league1PositionShortName: "",
    league2PositionShortName: "",
    league3PositionName: "",
    league4PositionName: "",
    league5PositionName: "",
    league6PositionName: "",
    league7PositionName: "",
    league8PositionName: "",
    league9PositionName: "",
    league3PositionShortName: "",
    league4PositionShortName: "",
    league5PositionShortName: "",
    league6PositionShortName: "",
    league7PositionShortName: "",
    league8PositionShortName: "",
    league9PositionShortName: "",
    league3_sports_position_code: 0,
    league4_sports_position_code: 0,
    league5_sports_position_code: 0,
    league6_sports_position_code: 0,
    league7_sports_position_code: 0,
    league8_sports_position_code: 0,
    league9_sports_position_code: 0
  };
  athleteProfile: IAthlete = {
    athlete_id: 0,
    account_id: 0,
    first_name: "",
    last_name: "",
    birthdate: new Date(),
    gender: "",
    address1: "",
    address2: "",
    city: "",
    athlete_state_code: "",
    zipcode: 0,
    zip4: 0,
    area_code_phone: 0,
    phone: 0,
    email: "",
    parent_first_name: "",
    parent_last_name: "",
    parent_address1: "",
    parent_address2: "",
    parent_city: "",
    parent_state_code: "",
    parent_zipcode: 0,
    parent_zip4: 0,
    parent_email: "",
    parent_area_code_phone: 0,
    parent_phone: 0,
    school_team_name: "",
    graduation_year: 0,
    school_name: "",
    grade_level: "",
    parentzipCode: "",
    athletezipCode: "",
    parentPhoneNumber: "",
    athletePhoneNumber: "",
    isparent: false,
    birthDate: "",
  };
  academicAccomplishment: IAcademicAccomplishment = {
    athlete_id: 1,
    year: "",
    high_school: "",
    high_school_city: "",
    high_school_state: "",
    grade_level: "",
    gpa: 0,
    class_rank: 0,
    class_size: 0,
    act_score: 0,
    sat_score: 0,
    awards: "",
    created_date: "",
    modified_date: "",
    created_by_account_id: 0,
    modified_by_account_id: 0,
    is_active: "",
    is_deleted: "",
  };
  athleticAccomplishment: IAthleticAccomplishment = {
    athletic_accomplishment_id: 0,
    athlete_id: 0,
    year: "",
    sports_focus_code: 1,
    team: "",
    coach: "",
    accomplishments: "",
    reference_email: "",
    created_date: "",
    modified_date: "",
    created_by_account_id: "",
    modified_by_account_id: "",
    is_active: "",
    is_deleted: "",
    sports_position_code: 0,
    sports_position_shortname: "",
  };
  Files: IFiles = {
    fileName: null,
    fileSource: null,
    fileList: null,
    fileAccess: null,
    fileDescription: null,
    fileRowColor: null,
    title: null,
  };

  detailedStats: IDetailedStats = {
    Batting_Average: "",
    On_Base_Percentage: "",
    Slugging_Percentage: "",
    Plate_Appearance: "",
    Games_Played: "",
    At_Bat: "",
    On_Base_Plus_Slugging: "",
    Put_in_Play: "",
    RBI: "",
    Runs: "",
    SB: "",
    First_Base: "",
    Second_Base: "",
    Third_Base: "",
    Home_Run: "",
    BB: "",
    SO: "",
    SAC: "",
    FC: "",
    ROE: "",
    Rank: "",
    OUT: "",
    HBP: "",
  };
  athleteDetails: IShareProfileDetails = {
    athlete_id: 0,
    name: "",
    school: "",
    age: 0,
    bats: "",
    throws: "",
    grade: "",
    positions: null,
    training_center: null,
    image: null,
    league1_team: null,
    league2_team: null,
    AB: null,
    BA: null,
    OBP: null,
    SLG: null,
    RBI: null,
    sprayChartData: [],
    subscriptionPackageCode: null,
    Feet: null,
    Inches: null,
    weight: null,
  };
  HighlightUploadVideoDetails: IHighlightUploadVideoDetails =
    {
      id: 0,
      athlete_id: 0,
      video_id: 0,
      raw_file_name: '',
      transcoded_file_name: '',
      category_code: '',
      ipost_tv: '',
      video_title: '',
      localPath: '',
      account_id: 0,
      fileName: null,
      fileSource: null,
      fileList: null,
      fileDescription: null,
      fileRowColor: null,
      title: null,
      created_date: null
    }

  @ViewChild('filtertype') public filterTypeObj: DropDownListComponent | any;
  //Chart Attributes
  public datac: Object | any;
  public datacir: Object | any;
  public scatterData: Object | any;
  public datal3: Object | any;
  public datal4: Object | any;
  public datal5: Object | any;
  public datal6: Object | any;
  public spdata: Object | any;
  public scatterData1: Object | any;
  public scatterData2: Object | any;
  public scattersmall1: Object | any;
  public scattersmall2: Object | any;
  public rect1: Object | any;
  public dataLine: Object | any;
  public splineBorderData: Object | any;
  public primaryXAxis: Object | any;
  public title: string = "";
  public primaryYAxis: Object | any;
  public series1: Object | any;
  public series2: Object | any;
  public series3: Object | any;
  public marker: Object | any;
  public animation: Object | any;
  public myText: string | any;
  public data2: Object | any;
  public data3: Object | any;
  public margin: Object | any;
  public data: Object | any;
  public areaData: Object | any;
  public scatter2: Object | any;
  public data4: Object | any;
  public scatter1: Object | any;
  public scatter3: Object | any;
  public marker1: Object | any;
  public marker2: Object | any;
  public marker4: Object | any;
  public sline: Object | any;
  public columnWidth: number = 0;
  public scatter4: Object | any;
  public legend: Object = { visible: false };
  public pointColor: string = "";
  @ViewChild("chart")
  public chart: ChartComponent | any;
  sprayChartData: any;
  footerYear: any = new Date().getFullYear();

  public border: Object = { color: "white", width: 1, height: 16 };
  public markerrect: Object = {
    visible: true,
    width: 8,
    height: 16,
    shape: "Rectangle",
  };
  public markerscatter: Object = { visible: true, width: 120, height: 120 };
  public markersmall1: Object = {
    visible: true,
    shape: "Diamond",
    width: 10,
    height: 10,
  };
  public markersmall12: Object = {
    visible: true,
    shape: "Circle",
    width: 15,
    height: 15,
  };
  public markercir: Object = {
    visible: true,
    shape: "Circle",
    width: 60,
    height: 60,
  };
  public markerscordinate: Object = { visible: true, shape: "Circle" };
  public markerc: Object = {
    visible: true,
    shape: "Diamond",
    width: 100,
    height: 90,
  };
  public zoom: Object | any;
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public datal1: Object | any;
  public datal2: Object | any;
  public width: string = "";
  public height: string = "";

  subscriptionPackageCode: any;
  isProfileInfoChanged: Boolean = false;
  team_name: string = "";
  tableHeight: string = "";
  PositionName: string = "";
  PositionNameForStats: string = "";
  disabledPreviousColor: string = "";
  disabledNextColor: string = "";
  isPitcher: Boolean;
  academicFileList: any;
  athleticDocList: any;
  athleticVideoList: any;
  isMoveAthlete: Boolean = false;
  isathleteRecordsFound: Boolean = true;
  isathleteDocumentFound: Boolean = true;
  isathleteVideosFound: Boolean = true;
  isacademicDocumentFound: Boolean = true;
  isacademicRecordsFound: Boolean = true;
  isProfileImageChanged: Boolean = true;
  isMobile: Boolean = false;
  isImageLoading: Boolean = false;
  hideImage: Boolean = false;
  teamList: any;
  stateList: any;
  sportsFocusCode: any;
  profileImageSource: any;
  Border: any;
  accountId: any;
  athleticBackground: any;
  academicBackground: any;
  disableNextNav: any;
  disablePreviousNav: any;
  nonextpointer: any;
  noprevpointer: any;
  loader: Boolean = false;
  athleteProfileList: any;
  sportsFocusCodes: any;
  parentStyle: any;
  isParent: Boolean = false;
  accountType: any;
  birthDate: any;
  academicYear: any;
  academicYearData: any;
  athleteYear: any;
  athleteYearData: any;
  sportsfocuscode: any;
  academictab: boolean;
  athletetab: boolean;
  academicColor: string = "#000";
  academicBorder: string = "1px solid #EAEAEA";
  athleteBorder: string = "0px solid #EAEAEA";
  athleteColor: string = "#000";
  alertMessage: any;
  imageLoader: any;
  isAlreadyLoggedIn: Boolean = false;
  GPA: string = "";
  isBothPitcher: Boolean = false;
  buttonOffenseActiveClass: string = "";
  buttonDefenseActiveClass: string = "";
  buttonTrainingActiveClass: string = "";
  buttonWorkActiveClass: string = "";
  offenseVideoContainerHeight: string = "";
  defenseVideoContainerHeight: string = "";
  trainingVideoContainerHeight: string = "";
  worksVideoContainerHeight: string = "";
  offenseVideoLockerList: any;
  defenseVideoLockerList: any;
  trainingVideoLockerList: any;
  worksVideoLockerList: any;
  videoLockerRowBackgroundColor: string = "";
  isOffenseDataFound: Boolean = true;
  isDefenseDataFound: Boolean = true;
  isTrainingDataFound: Boolean = true;
  isWorksDataFound: Boolean = true;
  isOffenseClicked: Boolean = true;
  isDefenseClicked: Boolean = false;
  isTrainingClicked: Boolean = false;
  isWorksClicked: Boolean = false;
  isacademicyearchanged: boolean = false;
  isathleticyearchanged: boolean = false;
  games_played: any;
  plate_appearence: any;
  slugging_percentage: any;
  base_percentage: any;
  batting_average: any;
  minDate: any;
  maxDate: any;
  seasonYear: any;
  public teamWaterMark: string = "Select a Team";
  public headerText: Object[] = [
    { text: "Detailed Stats" },
    { text: "Spray Chart" },
  ];
  screenType: any;
  setMarginForButtons: any;
  setWidthForVideoLockerList: any;
  setFontSizeForButtons: any;
  gameDay: any;
  filterType: any = "OFFENSE";
  teamFilterValue: any;
  leagueFilterValue: any = "All";
  opponentFilterValue: any;
  pitcherFilterValue: any = "All";
  public filterList: string[] = ["OFFENSE", "PITCHING", "DEFENSE"];
  public leagueFilterList: string[] = ["All", "School", "City", "Club"];
  public pitcherFilterList: string[] = ["All", "Versus RHP", "Versus LHP"];
  public teamFilterList: any = [];
  public opponentFilterList: any = [];
  public typeWaterMark: string = "Select a Type";
  public teamFilterWaterMark: string = "Teams";
  public leagueFilterWaterMark: string = "Leagues";
  public opponentFilterWaterMark: string = "Opponents";
  public pitcherFilterWaterMark: string = "Pitchers";
  @ViewChild("statsTab") public statsTab: TabComponent | any;
  @ViewChild("seasonPicker") public seasonPickerObj: DatePickerComponent | any;
  @ViewChild("gamedayRange") public gameDayDateRange:
    | DateRangePickerComponent
    | any;
  @ViewChild("videopanel", { read: ElementRef }) public videopanel:
    | ElementRef
    | any;
  @ViewChild("docpanel", { read: ElementRef }) public docpanel:
    | ElementRef
    | any;
  @ViewChild("athtabYear") public athtabYear: DatePickerComponent | any;
  @ViewChild("accTabYear") public accTabYear: DatePickerComponent | any;
  @ViewChild("videoPlayer") public videoPlayer: ElementRef | any;
  @ViewChild("gamedayRange") public gamedayRange: DateRangePickerComponent | any;
  publicShare: IPublicShare = {
    url: null,
    encryptedId: null,
    team: null,
    opponent: null,
    league: null,
    rlValue: null,
    startDate: null,
    endDate: null,
  };
  enableEdit: boolean = false;
  isMobileView: boolean = false;
  isMobileDevice: boolean = false;
  webSize: boolean = false;
  hidepage: any;
  constructor(
    private router: Router,
    public accountService: AccountService,
    public modalCtrl: ModalController,
    public popOverCtrl: PopoverController,
    public httpProvider: HttpProvider,
    public platform: Platform,
    public location: PlatformLocation,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private titleService: Title,
    private dataSharingService: DataSharingService,
    private toaster: ToasterService,
    private socialSharing: SocialSharing,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {

    this.observeScreenSize();
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      this.isMobileView = false;
      this.webSize = true;
    }
    else {
      this.isMobileView = true;
    }
    if (this.webSize == true) {
      if (window.matchMedia('(max-width: 800px)').matches) {
        this.isMobileView = true;
      }
    }
    this.dataSharingService.isUpdatingVideos.subscribe((value) => {
      if(value != null  && value != undefined && value == true){
       this.getHighlightUploadVideos();
      }
   });
    this.titleService.setTitle("View Profile | iPost Sports");
    this.profileImageSource = "";
    this.athletetab = true;
    this.academictab = false;
    this.academicColor = "white";
    this.academicBorder = "6px solid #a7a9ac";
    this.academicBackground = "transparent";
    this.athleticBackground = "#a7a9ac";
    this.Border = "6px solid #a7a9ac";
    this.athleteBorder = "0px solid #a7a9ac";
    this.academicYear = new Date();
    this.academicYearData = new Date();
    this.minDate = new Date("09/01/" + new Date().getFullYear().toString());
    this.maxDate = new Date(
      "08/31/" + (new Date().getFullYear() + 1).toString()
    );
    this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
    if (new Date() >= this.minDate && new Date() <= this.maxDate) {
      this.seasonYear = new Date(
        "02/01/" + (new Date().getFullYear() + 1).toString()
      );
    } else {
      this.seasonYear = new Date();
      this.minDate = new Date(
        "09/01/" + (new Date().getFullYear() - 1).toString()
      );
      this.maxDate = new Date("08/31/" + new Date().getFullYear().toString());
      this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
    }
    this.athleteYear = new Date();
    this.athleteYearData = new Date();
    this.sportsfocuscode = 1;
    this.isPitcher = true;

    this.isAlreadyLoggedIn = this.accountService.hasLoggedIn();
    if (this.isAlreadyLoggedIn === true) {
      localStorage.setItem("storedURL", "profile");
    } else {
    }

    var url = window.location.href;
    if (url.search("profile/edit") != -1) {
      this.enableEdit = true;
      this.titleService.setTitle('Edit Profile | iPost Sports');
    }
    else {
      this.enableEdit = false;
      this.titleService.setTitle('View Profile | iPost Sports');
    }

    if (
      (this.platform.is("mobile") || this.platform.is("mobileweb")) &&
      !this.platform.is("tablet")
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // Profile Image Update
    this.dataSharingService.isProfileImageUpdated.subscribe((value: any) => {
      if (value != null && value != "") {
        this.profileImageSource =
          value.imageDetail + "?" + new Date().getTime();
        localStorage.setItem("imageUrl", this.profileImageSource);
      }
    });

    this.dataSharingService.isCloseUpdateAthleteInfo.subscribe((value: any) => {
      if (value) {
        this.isProfileInfoChanged = true;
      }
    });

    this.dataSharingService.isCloseUpdateProfile.subscribe((value: any) => {
      if (value) {
        this.LoadAcademicData(this.athleteProfile.athlete_id);
      }
    });

    this.dataSharingService.isImageNotChanged.subscribe((value: any) => {
      if (value) {
        this.isProfileImageChanged = false;
        this.isImageLoading = false;
      }
    });

    var storedPackageCode = localStorage.getItem("athleteSubscription") || "";
    if (
      storedPackageCode != null &&
      storedPackageCode != undefined &&
      storedPackageCode != ""
    ) {
      this.subscriptionPackageCode = storedPackageCode;
    }
  }

  ngOnInit(): void {
    let id: any = this.accountService.getAccountId();
    if (id != null) {
      this.httpProvider.GetSubscribed(id).subscribe(result => {
        localStorage.setItem("hideMenuPage", result.body);
        this.activatedRoute.queryParams.subscribe(params => {          
          var routerNav = (this.router.getCurrentNavigation() as any);
          if (routerNav != null && routerNav.extras != null && routerNav.extras.state != null) {
            var athlete_id = (this.router.getCurrentNavigation() as any).extras.state.athlete_id;
            if (athlete_id != null && athlete_id != undefined && athlete_id != "") {
              this.athleteProfile.athlete_id = athlete_id;
              this.GetAllStates();
            }
          }
        });
        this.hidepage = localStorage.getItem('hideMenuPage') != null ? JSON.parse(localStorage.getItem('hideMenuPage') || '') : "";
        if (this.hidepage == true) {
          this.accountId = localStorage.getItem("accounIdStore") || "";
          this.spinner.show("Please Wait...");
          this.GetAllStates();
          window.addEventListener("message", this.handleframe, false);
          this.screenStyle();
          this.buttonOffenseActiveClass = "buttonsActiveStyles";
          this.buttonDefenseActiveClass = "buttonsStyles";
          this.buttonTrainingActiveClass = "buttonsStyles";
          this.buttonWorkActiveClass = "buttonsStyles";
          this.renderChart();

          this.activatedRoute.queryParams.subscribe((params) => {
            var routerNav = (this.router.getCurrentNavigation() as any);
            if (routerNav != null && routerNav.extras != null && routerNav.extras.state != null) {
              var value = (this.router.getCurrentNavigation() as any).extras.state
                .enableEdit;
              if (value != null && value != undefined && value != "") {
                this.enableEdit = value;
              }
            }
          });
        }else{
          this.router.navigate(['/package'], { replaceUrl: true });
        }
      },
        async (error: any) => {
          if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          }
        });
    }
  }

  renderChart1() {
    this.margin = { left: -70, right: -70 };
    this.marker = { visible: true, shape: 'Diamond', width: 10, height: 10 },
      this.marker4 = { visible: true, shape: 'Diamond', width: 12, height: 10 },
      this.marker1 = { visible: true, width: 12, height: 12 }
    this.marker2 = { visible: true, width: 36, height: 35 }
    this.areaData = [
      { x: 0, y: 140 }, { x: 1, y: 140 },
      { x: 2, y: 140 }, { x: 3, y: 140 },
      { x: 4, y: 140 }, { x: 5, y: 140 },
      { x: 6, y: 140 }, { x: 7, y: 140 },
      { x: 8, y: 140 }, { x: 9, y: 140 },
      { x: 10, y: 140 }, { x: 11, y: 140 },
      { x: 12, y: 140 }, { x: 13, y: 140 },
      { x: 14, y: 140 }, { x: 15, y: 140 }, { x: 16, y: 140 }, { x: 84, y: 140 }, { x: 85, y: 140 },
      { x: 86, y: 140 }, { x: 87, y: 140 }, { x: 88, y: 140 },
      { x: 89, y: 140 }, { x: 90, y: 140 },
      { x: 91, y: 140 }, { x: 92, y: 140 },
      { x: 93, y: 140 }, { x: 94, y: 140 },
      { x: 95, y: 140 }, { x: 96, y: 140 },
      { x: 97, y: 140 }, { x: 98, y: 140 },
      { x: 99, y: 140 }, { x: 100, y: 140 },
      { x: 0, y: 122 }, { x: 0, y: 122 }, { x: 1, y: 122 },
      { x: 2, y: 122 }, { x: 3, y: 122 },
      { x: 4, y: 122 }, { x: 5, y: 122 },
      { x: 6, y: 122 }, { x: 7, y: 122 },
      { x: 8, y: 122 }, { x: 9, y: 122 },
      { x: 10, y: 122 }, { x: 11, y: 122 },
      { x: 12, y: 122 }, { x: 13, y: 122 },
      { x: 14, y: 122 }, { x: 86, y: 122 },
      { x: 86, y: 122 }, { x: 87, y: 122 }, { x: 88, y: 123 },
      { x: 89, y: 122 }, { x: 90, y: 122 },
      { x: 91, y: 122 }, { x: 92, y: 122 },
      { x: 93, y: 122 }, { x: 94, y: 122 },
      { x: 95, y: 122 }, { x: 96, y: 122 },
      { x: 97, y: 122 }, { x: 98, y: 122 },
      { x: 99, y: 122 }, { x: 100, y: 122 },
      { x: 0, y: 123 }, { x: 0, y: 123 }, { x: 1, y: 123 },
      { x: 2, y: 123 }, { x: 3, y: 123 },
      { x: 4, y: 123 }, { x: 5, y: 123 },
      { x: 6, y: 123 }, { x: 7, y: 123 },
      { x: 8, y: 123 }, { x: 9, y: 123 },
      { x: 10, y: 123 }, { x: 11, y: 123 },
      { x: 12, y: 123 }, { x: 13, y: 123 },
      { x: 14, y: 123 }, { x: 86, y: 123 },
      { x: 86, y: 123 }, { x: 87, y: 123 }, { x: 88, y: 123 },
      { x: 89, y: 123 }, { x: 90, y: 123 },
      { x: 91, y: 123 }, { x: 92, y: 123 },
      { x: 93, y: 123 }, { x: 94, y: 123 },
      { x: 95, y: 123 }, { x: 96, y: 123 },
      { x: 97, y: 123 }, { x: 98, y: 123 },
      { x: 99, y: 123 }, { x: 100, y: 123 },
      { x: 0, y: 124 }, { x: 0, y: 124 }, { x: 1, y: 124 },
      { x: 2, y: 124 }, { x: 3, y: 124 },
      { x: 4, y: 124 }, { x: 5, y: 124 },
      { x: 6, y: 124 }, { x: 7, y: 124 },
      { x: 8, y: 124 }, { x: 9, y: 124 },
      { x: 10, y: 124 }, { x: 11, y: 124 },
      { x: 12, y: 124 }, { x: 13, y: 124 },
      { x: 14, y: 124 }, { x: 86, y: 124 },
      { x: 86, y: 124 }, { x: 87, y: 124 }, { x: 88, y: 124 },
      { x: 89, y: 124 }, { x: 90, y: 124 },
      { x: 91, y: 124 }, { x: 92, y: 124 },
      { x: 93, y: 124 }, { x: 94, y: 124 },
      { x: 95, y: 124 }, { x: 96, y: 124 },
      { x: 97, y: 124 }, { x: 98, y: 124 },
      { x: 99, y: 124 }, { x: 100, y: 124 },
      { x: 0, y: 125 }, { x: 0, y: 125 }, { x: 1, y: 125 },
      { x: 2, y: 125 }, { x: 3, y: 125 },
      { x: 4, y: 125 }, { x: 5, y: 125 },
      { x: 6, y: 125 }, { x: 7, y: 125 },
      { x: 8, y: 125 }, { x: 9, y: 125 },
      { x: 10, y: 125 }, { x: 11, y: 125 },
      { x: 12, y: 125 }, { x: 13, y: 125 },
      { x: 14, y: 125 }, { x: 86, y: 125 },
      { x: 86, y: 125 }, { x: 87, y: 125 }, { x: 88, y: 125 },
      { x: 89, y: 125 }, { x: 90, y: 125 },
      { x: 91, y: 125 }, { x: 92, y: 125 },
      { x: 93, y: 125 }, { x: 94, y: 125 },
      { x: 95, y: 125 }, { x: 96, y: 125 },
      { x: 97, y: 125 }, { x: 98, y: 125 },
      { x: 99, y: 125 }, { x: 100, y: 125 },
      { x: 0, y: 126 }, { x: 0, y: 126 }, { x: 1, y: 126 },
      { x: 2, y: 126 }, { x: 3, y: 126 },
      { x: 4, y: 126 }, { x: 5, y: 126 },
      { x: 6, y: 126 }, { x: 7, y: 126 },
      { x: 8, y: 126 }, { x: 9, y: 126 },
      { x: 10, y: 126 }, { x: 11, y: 126 },
      { x: 12, y: 126 }, { x: 13, y: 126 },
      { x: 14, y: 126 }, { x: 15, y: 126 }, { x: 16, y: 126 }, { x: 84, y: 126 }, { x: 85, y: 126 },
      { x: 86, y: 126 }, { x: 87, y: 126 }, { x: 88, y: 126 },
      { x: 89, y: 126 }, { x: 90, y: 126 },
      { x: 91, y: 126 }, { x: 92, y: 126 },
      { x: 93, y: 126 }, { x: 94, y: 126 },
      { x: 95, y: 126 }, { x: 96, y: 126 },
      { x: 97, y: 126 }, { x: 98, y: 126 },
      { x: 99, y: 126 }, { x: 100, y: 126 },
      { x: 0, y: 127 }, { x: 0, y: 127 }, { x: 1, y: 127 },
      { x: 2, y: 127 }, { x: 3, y: 127 },
      { x: 4, y: 127 }, { x: 5, y: 127 },
      { x: 6, y: 127 }, { x: 7, y: 127 },
      { x: 8, y: 127 }, { x: 9, y: 127 },
      { x: 10, y: 127 }, { x: 11, y: 127 },
      { x: 12, y: 127 }, { x: 13, y: 127 },
      { x: 14, y: 127 }, { x: 15, y: 127 }, { x: 16, y: 127 }, { x: 84, y: 127 }, { x: 85, y: 127 },
      { x: 86, y: 127 }, { x: 87, y: 127 }, { x: 88, y: 127 },
      { x: 89, y: 127 }, { x: 90, y: 127 },
      { x: 91, y: 127 }, { x: 92, y: 127 },
      { x: 93, y: 127 }, { x: 94, y: 127 },
      { x: 95, y: 127 }, { x: 96, y: 127 },
      { x: 97, y: 127 }, { x: 98, y: 127 },
      { x: 99, y: 127 }, { x: 100, y: 127 },
      { x: 0, y: 128 }, { x: 0, y: 128 }, { x: 1, y: 128 },
      { x: 2, y: 128 }, { x: 3, y: 128 },
      { x: 4, y: 128 }, { x: 5, y: 128 },
      { x: 6, y: 128 }, { x: 7, y: 128 },
      { x: 8, y: 128 }, { x: 9, y: 128 },
      { x: 10, y: 128 }, { x: 11, y: 128 },
      { x: 12, y: 128 }, { x: 13, y: 128 },
      { x: 14, y: 128 }, { x: 15, y: 128 }, { x: 16, y: 128 }, { x: 84, y: 128 }, { x: 85, y: 128 },
      { x: 86, y: 128 }, { x: 87, y: 128 }, { x: 88, y: 128 },
      { x: 89, y: 128 }, { x: 90, y: 128 },
      { x: 91, y: 128 }, { x: 92, y: 128 },
      { x: 93, y: 128 }, { x: 94, y: 128 },
      { x: 95, y: 128 }, { x: 96, y: 128 },
      { x: 97, y: 128 }, { x: 98, y: 128 },
      { x: 99, y: 128 }, { x: 100, y: 128 },
      { x: 0, y: 129 }, { x: 0, y: 129 }, { x: 1, y: 129 },
      { x: 2, y: 129 }, { x: 3, y: 129 },
      { x: 4, y: 129 }, { x: 5, y: 129 },
      { x: 6, y: 129 }, { x: 7, y: 129 },
      { x: 8, y: 129 }, { x: 9, y: 129 },
      { x: 10, y: 129 }, { x: 11, y: 129 },
      { x: 12, y: 129 }, { x: 13, y: 129 },
      { x: 14, y: 129 }, { x: 15, y: 129 }, { x: 16, y: 129 }, { x: 84, y: 129 }, { x: 85, y: 129 },
      { x: 86, y: 129 }, { x: 87, y: 129 }, { x: 88, y: 129 },
      { x: 89, y: 129 }, { x: 90, y: 129 },
      { x: 91, y: 129 }, { x: 92, y: 129 },
      { x: 93, y: 129 }, { x: 94, y: 129 },
      { x: 95, y: 129 }, { x: 96, y: 129 },
      { x: 97, y: 129 }, { x: 98, y: 129 },
      { x: 99, y: 129 }, { x: 100, y: 129 },
      { x: 0, y: 130 }, { x: 0, y: 130 }, { x: 1, y: 130 },
      { x: 2, y: 130 }, { x: 3, y: 130 },
      { x: 4, y: 130 }, { x: 5, y: 130 },
      { x: 6, y: 130 }, { x: 7, y: 130 },
      { x: 8, y: 130 }, { x: 9, y: 130 },
      { x: 10, y: 130 }, { x: 11, y: 130 },
      { x: 12, y: 130 }, { x: 13, y: 130 },
      { x: 14, y: 130 }, { x: 15, y: 130 }, { x: 16, y: 130 }, { x: 84, y: 130 }, { x: 85, y: 130 },
      { x: 86, y: 130 }, { x: 87, y: 130 }, { x: 88, y: 130 },
      { x: 89, y: 130 }, { x: 90, y: 130 },
      { x: 91, y: 130 }, { x: 92, y: 130 },
      { x: 93, y: 130 }, { x: 94, y: 130 },
      { x: 95, y: 130 }, { x: 96, y: 130 },
      { x: 97, y: 130 }, { x: 98, y: 130 },
      { x: 99, y: 130 }, { x: 100, y: 130 },
      { x: 0, y: 131 }, { x: 0, y: 131 }, { x: 1, y: 131 },
      { x: 2, y: 131 }, { x: 3, y: 131 },
      { x: 4, y: 131 }, { x: 5, y: 131 },
      { x: 6, y: 131 }, { x: 7, y: 131 },
      { x: 8, y: 131 }, { x: 9, y: 131 },
      { x: 10, y: 131 }, { x: 11, y: 131 },
      { x: 12, y: 131 }, { x: 13, y: 131 },
      { x: 14, y: 131 }, { x: 15, y: 131 }, { x: 16, y: 131 }, { x: 84, y: 131 }, { x: 85, y: 131 },
      { x: 86, y: 131 }, { x: 87, y: 131 }, { x: 88, y: 131 },
      { x: 89, y: 131 }, { x: 90, y: 131 },
      { x: 91, y: 131 }, { x: 92, y: 131 },
      { x: 93, y: 131 }, { x: 94, y: 131 },
      { x: 95, y: 131 }, { x: 96, y: 131 },
      { x: 97, y: 131 }, { x: 98, y: 131 },
      { x: 99, y: 131 }, { x: 100, y: 131 },
      { x: 0, y: 133 }, { x: 0, y: 133 }, { x: 1, y: 133 },
      { x: 2, y: 133 }, { x: 3, y: 133 },
      { x: 4, y: 133 }, { x: 5, y: 133 },
      { x: 6, y: 133 }, { x: 7, y: 133 },
      { x: 8, y: 133 }, { x: 9, y: 133 },
      { x: 10, y: 133 }, { x: 11, y: 133 },
      { x: 12, y: 133 }, { x: 13, y: 133 },
      { x: 14, y: 133 }, { x: 15, y: 133 }, { x: 16, y: 133 }, { x: 84, y: 133 }, { x: 85, y: 133 },
      { x: 86, y: 133 }, { x: 87, y: 133 }, { x: 88, y: 133 },
      { x: 89, y: 133 }, { x: 90, y: 133 },
      { x: 91, y: 133 }, { x: 92, y: 133 },
      { x: 93, y: 133 }, { x: 94, y: 133 },
      { x: 95, y: 133 }, { x: 96, y: 133 },
      { x: 97, y: 133 }, { x: 98, y: 133 },
      { x: 99, y: 133 }, { x: 100, y: 133 },
      { x: 0, y: 135 }, { x: 0, y: 135 }, { x: 1, y: 135 },
      { x: 2, y: 135 }, { x: 3, y: 135 },
      { x: 4, y: 135 }, { x: 5, y: 135 },
      { x: 6, y: 135 }, { x: 7, y: 135 },
      { x: 8, y: 135 }, { x: 9, y: 135 },
      { x: 10, y: 135 }, { x: 11, y: 135 },
      { x: 12, y: 135 }, { x: 13, y: 135 },
      { x: 14, y: 135 }, { x: 15, y: 135 }, { x: 16, y: 135 }, { x: 84, y: 135 }, { x: 85, y: 135 },
      { x: 86, y: 135 }, { x: 87, y: 135 }, { x: 88, y: 135 },
      { x: 89, y: 135 }, { x: 90, y: 135 },
      { x: 91, y: 135 }, { x: 92, y: 135 },
      { x: 93, y: 135 }, { x: 94, y: 135 },
      { x: 95, y: 135 }, { x: 96, y: 135 },
      { x: 97, y: 135 }, { x: 98, y: 135 },
      { x: 99, y: 135 }, { x: 100, y: 135 },
      { x: 0, y: 137 }, { x: 0, y: 137 }, { x: 1, y: 137 },
      { x: 2, y: 137 }, { x: 3, y: 137 },
      { x: 4, y: 137 }, { x: 5, y: 137 },
      { x: 6, y: 137 }, { x: 7, y: 137 },
      { x: 8, y: 137 }, { x: 9, y: 137 },
      { x: 10, y: 137 }, { x: 11, y: 137 },
      { x: 12, y: 137 }, { x: 13, y: 137 },
      { x: 14, y: 137 }, { x: 15, y: 137 }, { x: 16, y: 137 }, { x: 84, y: 137 }, { x: 85, y: 137 },
      { x: 86, y: 137 }, { x: 87, y: 137 }, { x: 88, y: 137 },
      { x: 89, y: 137 }, { x: 90, y: 137 },
      { x: 91, y: 137 }, { x: 92, y: 137 },
      { x: 93, y: 137 }, { x: 94, y: 137 },
      { x: 95, y: 137 }, { x: 96, y: 137 },
      { x: 97, y: 137 }, { x: 98, y: 137 },
      { x: 99, y: 137 }, { x: 100, y: 137 },

    ];
    this.sline = [{ x: 99.3, y: 52 }, { x: 0.5, y: 52 },
    ]
      ;
    this.scatter3 = [{ x: 0, y: 20 }]
      ;
    this.scatter2 = [
      { x: 0, y: 52 },
    ]
    this.scatter1 = [
      { x: 88.8, y: 57.5 },
      { x: 11.5, y: 58 },
      { x: 0, y: 76 },

    ]
    this.scatter4 = [{ x: 0, y: 18 },]
    this.data4 = [
      { x: 1, y: 75 },
      { x: 10, y: 60 }, { x: 11, y: 59 },
      { x: 89, y: 15 }, { x: 91, y: 2 },
      { x: 90, y: 5 }, { x: 94, y: 29 },
      { x: 89.5, y: 58.7 },
      { x: 100, y: 78 }]
    this.data2 = [{ x: 95, y: 22 }, { x: 86, y: 125 }];
    this.data3 = [{ x: 6, y: 22 }, { x: 13.9, y: 125 }];
    this.series3 = [
      { x: 97.4, y: 12 }, { x: 86, y: 95 },
      { x: 87.7, y: 95 }, { x: 91, y: 95 },
      { x: 91.1, y: 95 }, { x: 91.2, y: 95 },
      { x: 91.3, y: 95 }, { x: 91.4, y: 95 },
      { x: 92.1, y: 95 }, { x: 92, y: 95 },
      { x: 95, y: 95.1 }, { x: 93, y: 95.5 },
      { x: 94, y: 95.4 }, { x: 95, y: 95 },
      { x: 96, y: 95 }, { x: 97, y: 95 },
      { x: 98, y: 95 }, { x: 99, y: 95 },
      { x: 2, y: 95 },
      { x: 4, y: 95 }, { x: 6, y: 95 },
      { x: 8, y: 95 }, { x: 8.4, y: 95 },
      { x: 8.6, y: 95 }, { x: 8.7, y: 95 },
      { x: 8.8, y: 95 }, { x: 8.9, y: 95 },
      { x: 9.1, y: 95 }, { x: 9.2, y: 95 },
      { x: 9.3, y: 95 }, { x: 9.4, y: 95 },
      { x: 9.5, y: 95 }, { x: 9.6, y: 95 },
      { x: 9.7, y: 95 }, { x: 9.8, y: 95 },
      { x: 9.9, y: 95 }, { x: 10, y: 95 },
      { x: 10.1, y: 95 }, { x: 10.2, y: 95 },
      { x: 10.3, y: 95 }, { x: 10.4, y: 95 },
      { x: 10.5, y: 95 }, { x: 10.6, y: 95 },
      { x: 10.7, y: 95 }, { x: 10.8, y: 95 },
      { x: 10.9, y: 95 }, { x: 11, y: 95 },
      { x: 11.1, y: 95 }, { x: 11.2, y: 95 },
      { x: 11.3, y: 95 }, { x: 11.4, y: 95 },
      { x: 11.5, y: 95 }, { x: 11.6, y: 95 },
      { x: 11.7, y: 95 }, { x: 11.8, y: 95 },
      { x: 11.9, y: 95 }, { x: 12, y: 95 }, { x: 12, y: 95 },
      { x: 14, y: 95 }, { x: 7, y: 15 },

    ];
    this.series1 = [
      { x: 0, y: 125 }, { x: 1, y: 125 },
      { x: 2, y: 125 }, { x: 3, y: 125 },
      { x: 4, y: 125 }, { x: 5, y: 125 },
      { x: 6, y: 125 }, { x: 7, y: 125 },
      { x: 8, y: 125 }, { x: 9, y: 125 },
      { x: 10, y: 125 }, { x: 11, y: 125 },
      { x: 12, y: 125 }, { x: 13, y: 125 },
      { x: 14, y: 125 }, { x: 15, y: 125 },
      { x: 15.5, y: 125 },
      { x: 84.5, y: 125 },
      { x: 85, y: 125 },
      { x: 86, y: 125 }, { x: 87, y: 125 }, { x: 88, y: 125 },
      { x: 89, y: 125 }, { x: 90, y: 125 },
      { x: 91, y: 125 }, { x: 92, y: 125 },
      { x: 93, y: 125 }, { x: 94, y: 125 },
      { x: 95, y: 125 }, { x: 96, y: 125 },
      { x: 97, y: 125 }, { x: 98, y: 125 },
      { x: 99, y: 125 }, { x: 100, y: 125 }];
    // this.margin = { left: 20, right: 20 , top: 20, bottom: 20}
    this.primaryXAxis = {
      majorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minimum: 0, maximum: 100, interval: 10,
      labelStyle: { color: 'transparent' }

    }
    this.primaryYAxis = {
      majorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minimum: 0, maximum: 140, interval: 20,
      labelStyle: { color: 'transparent' },
      lineStyle: { width: 0 }
    }
    this.animation = { enable: false };

  }

  renderChart(): void {
    if ((this.platform.is('ipad')) || this.isMobileDevice == false) {
      this.width = '500';
      this.height = '400';
      this.marker4 = { visible: true, shape: 'Diamond', width: 12, height: 10 },
      this.marker1 = { visible: true, width: 350, height: 115 }
      this.marker2 = { visible: true, width: 36, height: 35 }
      this.scatterData = [
        // {x: 45, y: 82 },{x: 55, y: 89 },
        // {x: 50, y: 30 },{x: 41, y: 80 },
      ]

      this.data4 = [
        { x: 0, y: 90 },
        { x: 1, y: 92 },
        { x: 2, y: 94 },
        { x: 60, y: 137 },
        { x: 119, y: 92 },
        { x: 120, y: 90 },
        { x: 119, y: 90 },
        { x: 0, y: 90 },
      ];
      // [
      //   { x: 30, y: 90 },
      //   { x: 31, y: 93 },
      //   { x: 32, y: 96 },
      //   { x: 60, y: 130 },
      //   { x: 89, y: 93 },
      //   { x: 90, y: 90 },
      //   { x: 89, y: 90 },
      //   { x: 30, y: 90 },
      // ];
      //this.margin = { left: 90, right: 80 }
      this.data2 = [
        { x: 60, y: 0 },
        { x: 0, y: 90 },
        { x: 10, y: 92 },
        { x: 80, y: 92 },
        { x: 120, y: 90 },
        { x: 60, y: 0 }];
      this.datac = [
        { x: 59, y: 27 },
        { x: 41, y: 55 },
        { x: 60, y: 80 },
        { x: 79, y: 55 },
        { x: 61, y: 27 },
      ]
      //this.datal1 = [{ x: 60, y: 3 }, { x: 30, y: 80 }];
      //this.datal2 = [{ x: 60, y: 3 }, { x: 70, y: 80 }];
      this.datal3 = [
        { x: 60, y: 15 },
        { x: 20, y: 78 },
        { x: 30, y: 85 },
        { x: 40, y: 85 },
        { x: 102, y: 78 },
        { x: 60, y: 15 }];
      this.datal4 = [{ x: 60, y: 20 }, { x: 73, y: 70 }];
      this.datacir = [{ x: 60, y: 23 },];

      this.spdata = [{ x: 20, y: 78 }, { x: 23, y: 82 }, { x: 100, y: 81 },
      { x: 102, y: 78 }, { x: 100, y: 78 }, { x: 60, y: 78 }, { x: 20, y: 78 }
      ];
      this.scatterData1 = [
        //{x: 49.5, y: 98}
      ];
      this.scatterData2 = [
        //{x: 49.5, y: 98}
      ];
      this.scattersmall1 = [
        { x: 60, y: 23 }, { x: 41, y: 53 },
        { x: 79, y: 53 }, { x: 60, y: 80 },
      ];
      this.scattersmall2 = [
        { x: 60, y: 55 },
      ];
      this.sline = [{ x: 58.6, y: 55 }, { x: 61.3, y: 55 }];
      this.datal5 = [{ x: 57, y: 26 }, { x: 8, y: 104 }];
      this.datal6 = [{ x: 63, y: 26 }, { x: 113, y: 102.5 }];
      this.rect1 = [{ x: 58, y: 23 }, { x: 62, y: 23 }];

      this.dataLine = [{ x: 0, y: 90 }, { x: 60, y: 0.5 }, { x: 120, y: 90 }]
      this.splineBorderData =
        [{ x: 1, y: 90 },
        { x: 2, y: 92 },
        { x: 3, y: 94 },
        { x: 10, y: 107 },
        { x: 60, y: 137 },
        { x: 110, y: 106.8 },
        { x: 117, y: 94 },
        { x: 118, y: 92 },
        { x: 119, y: 90 }];

      this.primaryXAxis = {
        visible: false,
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minimum: 0, maximum: 120, interval: 10,
        labelStyle: { color: 'transparent' },
        lineStyle: { width: 0 }
      }
      this.primaryYAxis = {
        visible: false,
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minimum: 0, maximum: 150, interval: 20,
        labelStyle: { color: 'transparent' },
        lineStyle: { width: 0 }
      }
      this.animation = { enable: false };
      
    }
    else {
      this.height = '300';
      this.datal5 = [{ x: 56, y: 26 }, { x: 8, y: 105 }];
      this.datal6 = [{ x: 63.80, y: 25.5 }, { x: 113, y: 103.2 }];
      this.rect1 = [{ x: 63, y: 22 }, { x: 57, y: 22 }];
      this.dataLine = [{ x: 0, y: 90 }, { x: 60, y: 1 }, { x: 120, y: 90 }]
      this.splineBorderData =
        [{ x: 0, y: 90 },
        { x: 1, y: 90 },
        { x: 2, y: 92 },
        { x: 3, y: 94 },
        { x: 60, y: 137 },
        { x: 117, y: 94 },
        { x: 118, y: 92 },
        { x: 119, y: 90 }];

        this.marker4 = { visible: true, shape: 'Diamond', width: 12, height: 10 },
        this.marker1 = { visible: true, width: 350, height: 115 }
      this.marker2 = { visible: true, width: 36, height: 35 }
      this.scatterData = [
        // {x: 45, y: 82 },{x: 55, y: 89 },
        // {x: 50, y: 30 },{x: 41, y: 80 },
      ]
  
      this.data4 = [
        { x: 0, y: 90 },
        { x: 1, y: 92 },
        { x: 2, y: 94 },
        { x: 60, y: 137 },
        { x: 119, y: 92 },
        { x: 120, y: 90 },
        { x: 119, y: 90 },
        { x: 0, y: 90 },
      ];
      // [
      //   { x: 30, y: 90 },
      //   { x: 31, y: 93 },
      //   { x: 32, y: 96 },
      //   { x: 60, y: 130 },
      //   { x: 89, y: 93 },
      //   { x: 90, y: 90 },
      //   { x: 89, y: 90 },
      //   { x: 30, y: 90 },
      // ];
      //this.margin = { left: 90, right: 80 }
      this.data2 = Browser.isDevice ? [
        { x: 60, y: 0 },
        { x: 0, y: 90 },
        { x: 10, y: 92 },
        { x: 80, y: 92 },
        { x: 120, y: 90 },
        { x: 60, y: 0 }] :
        [
          { x: 60, y: 0 },
          { x: 30, y: 90 },
          { x: 40, y: 92 },
          { x: 60, y: 92 },
          { x: 90, y: 90 },
          { x: 60, y: 0 }];
      //this.data3 = [{ x: 60, y: 0 }, { x: 80, y: 80 }];
      this.datac = Browser.isDevice ? [
        { x: 60, y: 27 },
        { x: 41, y: 55 },
        { x: 60, y: 80 },
        { x: 79, y: 55 },
        { x: 60, y: 27 },
      ] : [
        { x: 60, y: 27 },
        { x: 51, y: 55 },
        { x: 60, y: 80 },
        { x: 69, y: 55 },
        { x: 60, y: 27 },
      ];
      //this.datal1 = [{ x: 60, y: 3 }, { x: 30, y: 80 }];
      //this.datal2 = [{ x: 60, y: 3 }, { x: 70, y: 80 }];
      this.datal3 = [
        { x: 60, y: 15 },
        { x: 20, y: 78 },
        { x: 30, y: 85 },
        { x: 40, y: 85 },
        { x: 102, y: 78 },
        { x: 60, y: 15 }];
      this.datal4 = [{ x: 60, y: 20 }, { x: 73, y: 70 }];
      this.datacir = [{ x: 60, y: 23 },];
  
      this.spdata = [{ x: 20, y: 78 }, { x: 23, y: 82 }, { x: 100, y: 81 },
      { x: 102, y: 78 }, { x: 100, y: 78 }, { x: 60, y: 78 }, { x: 20, y: 78 }
      ];
      this.scatterData1 = [
        //{x: 49.5, y: 98}
      ];
      this.scatterData2 = [
        //{x: 49.5, y: 98}
      ];
      this.scattersmall1 = Browser.isDevice ? [
        { x: 60, y: 23 }, { x: 41, y: 53 },
        { x: 79, y: 53 }, { x: 60, y: 80 },
      ] : [
        { x: 60, y: 23 }, { x: 51, y: 53 },
        { x: 69, y: 53 }, { x: 60, y: 80 },
      ];
      this.scattersmall2 = [
        { x: 60, y: 55 },
      ];
      this.sline = Browser.isAndroid ? [{ x: 58.6, y: 55 }, { x: 61.3, y: 55 }]
        : [{ x: 59.6, y: 55 }, { x: 60.3, y: 55 }];
  
      this.primaryXAxis = {
        visible: false,
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minimum: 0, maximum: 120, interval: 10,
        labelStyle: { color: 'transparent' },
        lineStyle: { width: 0 }
      }
      this.primaryYAxis = {
        visible: false,
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minimum: 0, maximum: 150, interval: 20,
        labelStyle: { color: 'transparent' },
        lineStyle: { width: 0 }
      }
      this.animation = { enable: false };
    }
     

  }

  handleframe(e: any) {
    if (
      e != undefined &&
      e.origin == "http://localhost:59558" &&
      e.data != null
    ) {
    }
  }

  screenStyle() {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.offenseVideoContainerHeight = "360px";
      this.defenseVideoContainerHeight = "360px";
      this.trainingVideoContainerHeight = "360px";
      this.worksVideoContainerHeight = "360px";
    }
  }

  academicTab() {
    this.academictab = false;
    this.athletetab = true;
    this.academicColor = "white";
    this.academicBorder = "6px solid #a7a9ac";
    this.athleteBorder = "0px solid #a7a9ac";
    //this.academicBackground = '#a7a9ac';
    //this.athleticBackground = 'transparent';
    this.athleteColor = "white";
    this.Border = "6px solid #a7a9ac";
    if (
      this.subscriptionPackageCode == 4 ||
      this.subscriptionPackageCode == 5
    ) {
      this.alertService.showAlertWithOkBtn(
        "Alert",
        "This feature is available on Prep ScoutPost Membership"
      );
    }
  }

  athleteTab() {
    this.athletetab = true;
    this.academictab = false;
    this.athleteColor = "white";
    this.academicColor = "white";
    this.academicBorder = "0px solid #a7a9ac";
    this.athleteBorder = "6px solid #a7a9ac";
    this.academicBackground = "transparent";
    this.athleticBackground = "#a7a9ac";
    this.Border = "6px solid #a7a9ac";
    if (this.subscriptionPackageCode == 4) {
      this.statsTab.selectedItem = 1;
    } else {
      this.statsTab.selectedItem = 0;
    }
  }

  GetAllSports() {
    try {
      this.httpProvider.GetAllSports().subscribe(
        (data) => {
          if (data != null && data.body != null) {
            this.sportsFocusCodes = [];
            var resultData = data.body;
            this.sportsFocusCodes = resultData;
            localStorage.setItem(
              "SportsFocusCodes",
              JSON.stringify(this.sportsFocusCodes)
            );
          }
          this.LoadProfile();
        },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            localStorage.removeItem("accountId");
            localStorage.removeItem("accounIdStore");
            localStorage.removeItem("storedURL");
            this.accountService.logout();
            this.router.navigate(["/login"]);
          } else {
            this.alertService.showAlertWithOkBtn(
              "Error",
              error.statusText != "" && error.statusText != null
                ? error.statusText
                : "Unknown Error"
            );
          }
        }
      );
    } catch (e) { }
  }

  LoadProfile() {
    try {
      this.accountId = localStorage.getItem("accountId");
      this.httpProvider.GetAthleteProfile(this.accountId).subscribe(
        (data) => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData != null) {
              if (
                this.athleteProfile.athlete_id != undefined &&
                this.athleteProfile.athlete_id != null &&
                this.athleteProfile.athlete_id != 0
              ) {
                if (resultData[0] != null)
                  this.athleteProfileList = resultData[0];
                for (let i = 0; i < this.athleteProfileList.length; i++) {
                  if (
                    this.athleteProfile.athlete_id ==
                    this.athleteProfileList[i].athlete_id
                  ) {
                    this.athleteProfile = this.athleteProfileList[i];
                    break;
                  }
                }
              } else {
                if (resultData[0] != null) {
                  this.athleteProfileList = resultData[0];
                  this.athleteProfile =
                    this.athleteProfileList.length > 0
                      ? this.athleteProfileList[0]
                      : this.athleteProfile;
                  this.disabledPreviousColor = "0.5";
                  this.disabledNextColor =
                    this.athleteProfileList.length > 1 ? "1" : "0.5";
                }
              }
              this.accountType = resultData[1];
              localStorage.setItem("accounttype", this.accountType);
              if (
                this.athleteProfile != null &&
                this.athleteProfile.athlete_id != null
              )
                localStorage.setItem(
                  "athleteIdForUpdateprofile",
                  this.athleteProfile.athlete_id.toString()
                );
              else {
                this.router.navigate(["/type"]);
                this.profileImageSource = "assets/imgs/Athlete Image.png";
                localStorage.setItem("imageUrl", "");
                this.hideImage = false;
              }
            }
            if (this.accountType == 5) {
              this.isParent = true;
              this.parentStyle = "70px";
            } else {
              this.parentStyle = "25px";
            }
            if (this.athleteProfile.birthdate != null) {
              let birthdate = new Date(this.athleteProfile.birthdate);
              this.CalculateBirthDate(birthdate);
            }
          }
          this.LoadAthleteDetailsByAthleteId(this.athleteProfile.athlete_id);
        },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            localStorage.removeItem("accountId");
            localStorage.removeItem("accounIdStore");
            localStorage.removeItem("storedURL");
            this.accountService.logout();
            this.router.navigate(["/login"]);
          } else {
            this.alertService.showAlertWithOkBtn(
              "Alert",
              error.statusText != "" && error.statusText != null
                ? error.statusText
                : "Unknown Error"
            );
          }
        }
      );
    } catch (e) { }
  }

  ShowPreviousAthlete(profile: any) {
    let index = this.athleteProfileList.indexOf(profile);
    if (index != 0) {
      this.team_name = "";
      if (!this.isMoveAthlete) {
        this.spinner.show("Please wait...");
        this.isMoveAthlete = true;
      }
      this.academicYear = new Date();
      this.athleteYear = new Date();
      this.minDate = new Date("09/01/" + new Date().getFullYear().toString());
      this.maxDate = new Date(
        "08/31/" + (new Date().getFullYear() + 1).toString()
      );
      this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      if (new Date() >= this.minDate && new Date() <= this.maxDate) {
        this.seasonYear = new Date(
          "02/01/" + (new Date().getFullYear() + 1).toString()
        );
      } else {
        this.seasonYear = new Date(
          "02/01/" + new Date().getFullYear().toString()
        );
        this.minDate = new Date(
          "09/01/" + (new Date().getFullYear() - 1).toString()
        );
        this.maxDate = new Date("08/31/" + new Date().getFullYear().toString());
        this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      }
      this.athleteProfile = this.athleteProfileList[index - 1];
      this.LoadAthleteDetailsByAthleteId(this.athleteProfile.athlete_id);
      localStorage.setItem(
        "athleteIdForUpdateprofile",
        this.athleteProfile.athlete_id.toString()
      );
      if (index == 1) {
        this.disabledPreviousColor = "0.5";
        this.disabledNextColor = "1";
      } else {
        this.disabledPreviousColor = "1";
        this.disabledNextColor = "1";
      }
    }
    if (this.athleteProfile.birthdate != null) {
      let birthdate = new Date(this.athleteProfile.birthdate);
      this.CalculateBirthDate(birthdate);
    }
  }

  ShowNextAthlete(profile: any) {
    let index = this.athleteProfileList.indexOf(profile);
    if (index < this.athleteProfileList.length - 1) {
      this.team_name = "";
      if (!this.isMoveAthlete) {
        this.spinner.show("Please wait...");
        this.isMoveAthlete = true;
      }
      this.academicYear = new Date();
      this.athleteYear = new Date();
      this.minDate = new Date("09/01/" + new Date().getFullYear().toString());
      this.maxDate = new Date(
        "08/31/" + (new Date().getFullYear() + 1).toString()
      );
      this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      if (new Date() >= this.minDate && new Date() <= this.maxDate) {
        this.seasonYear = new Date(
          "02/01/" + (new Date().getFullYear() + 1).toString()
        );
      } else {
        this.seasonYear = new Date(
          "02/01/" + new Date().getFullYear().toString()
        );
        this.minDate = new Date(
          "09/01/" + (new Date().getFullYear() - 1).toString()
        );
        this.maxDate = new Date("08/31/" + new Date().getFullYear().toString());
        this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      }
      this.athleteProfile = this.athleteProfileList[index + 1];
      this.LoadAthleteDetailsByAthleteId(this.athleteProfile.athlete_id);
      localStorage.setItem(
        "athleteIdForUpdateprofile",
        this.athleteProfile.athlete_id.toString()
      );
      if (index == this.athleteProfileList.length - 2) {
        this.disabledNextColor = "0.5";
        this.disabledPreviousColor = "1";
      } else {
        this.disabledNextColor = "1";
        this.disabledPreviousColor = "1";
      }
    }
    if (this.athleteProfile.birthdate != null) {
      let birthdate = new Date(this.athleteProfile.birthdate);
      this.CalculateBirthDate(birthdate);
    }
  }

  public LoadAcademicData(athleteId: any) {
    try {
      if (
        this.athleteProfile.athlete_id != null &&
        this.athleteProfile.athlete_id != undefined
      ) {
        let year = new Date(this.academicYear).getFullYear().toString();
        this.httpProvider
          .GetAcademicAccomplishments(this.athleteProfile.athlete_id, year)
          .subscribe(
            (data) => {
              if (data != null && data.body != null) {
                var resultData = data.body;
                if (resultData != null && resultData[0] != null) {
                  this.academicAccomplishment = resultData[0];
                  if (
                    this.academicAccomplishment.gpa % 1 == 0 &&
                    this.academicAccomplishment.gpa != null &&
                    this.academicAccomplishment.gpa != 0
                  ) {
                    this.GPA =
                      this.academicAccomplishment.gpa.toString() + ".0";
                  } else {
                    this.GPA =
                      this.academicAccomplishment.gpa != null
                        ? this.academicAccomplishment.gpa.toString()
                        : "";
                  }
                  this.isacademicRecordsFound = true;
                  if (resultData[1] != null) {
                    this.academicFileList = [];
                    var fileList = resultData[1];
                    for (var i = 0; i < fileList.length; i++) {
                      this.Files = {} as IFiles;
                      var fileWithExtension: string = fileList[i].replace(
                        /^.*[\\\/]/,
                        ""
                      );
                      this.Files.fileName = fileWithExtension;
                      this.Files.fileSource = fileList[i]; // fileList[i];
                      this.academicFileList.push(this.Files);
                    }
                    this.isacademicDocumentFound = true;
                  } else this.isacademicDocumentFound = false;
                } else {
                  this.isacademicRecordsFound = false;
                  this.isacademicDocumentFound = false;
                  this.academicAccomplishment.year = "";
                  this.academicAccomplishment.high_school = "";
                  this.academicAccomplishment.high_school_city = "";
                  this.academicAccomplishment.high_school_state = "";
                  this.academicAccomplishment.grade_level = "";
                  this.academicAccomplishment.gpa = 0;
                  this.academicAccomplishment.class_rank = 0;
                  this.academicAccomplishment.class_size = 0;
                  this.academicAccomplishment.act_score = 0;
                  this.academicAccomplishment.sat_score = 0;
                  this.academicAccomplishment.awards = "";
                  this.academicAccomplishment.created_date = "";
                  this.academicAccomplishment.modified_date = "";
                  this.academicAccomplishment.created_by_account_id = 0;
                  this.academicAccomplishment.modified_by_account_id = 0;
                  this.academicAccomplishment.is_active = "";
                  this.academicAccomplishment.is_deleted = "";
                }
              }
              this.LoadAthleticData(this.athleteProfile.athlete_id);
            },
            (error) => {
              this.spinner.hide();
              if (error.status == 401) {
                localStorage.removeItem("accountId");
                localStorage.removeItem("accounIdStore");
                localStorage.removeItem("storedURL");
                this.accountService.logout();
                this.router.navigate(["/login"]);
              } else {
                this.alertService.showAlertWithOkBtn(
                  "Alert",
                  error.statusText != "" && error.statusText != null
                    ? error.statusText
                    : "Unknown Error"
                );
              }
            }
          );
      }
    } catch (e) { }
  }

  LoadAthleticData(athleteId: any) {
    try {
      if (
        this.athleteProfile.athlete_id != null &&
        this.athleteProfile.athlete_id != undefined
      ) {
        let year = new Date(this.athleteYear).getFullYear().toString();
        this.httpProvider
          .GetAthleteAccomplishments(
            this.athleteProfile.athlete_id,
            year,
            this.sportsfocuscode,
            this.team_name
          )
          .subscribe(
            (data) => {
              if (data != null && data.body != null) {
                var resultData = data.body;
                if (resultData != null && resultData[0] != null) {
                  this.athleticAccomplishment = resultData[0];
                  this.team_name = this.athleticAccomplishment.team;
                  this.isathleteRecordsFound = true;
                  if (resultData[1] != null) {
                    this.athleticDocList = [];
                    var fileList = resultData[1];
                    for (var i = 0; i < fileList.length; i++) {
                      this.Files = {} as IFiles;
                      var fileWithExtension: string = fileList[i].replace(
                        /^.*[\\\/]/,
                        ""
                      );
                      this.Files.fileName = fileWithExtension;
                      this.Files.fileSource = fileList[i]; // fileList[i];
                      this.athleticDocList.push(this.Files);
                    }
                    this.isathleteDocumentFound = true;
                  } else this.isathleteDocumentFound = false;

                  if (resultData[2] != null) {
                    this.athleticVideoList = [];
                    var fileDataList = resultData[2];
                    for (var j = 0; j < fileDataList.length; j++) {
                      this.Files = {} as IFiles;
                      var fileDataWithExtension: string = fileDataList[
                        j
                      ].replace(/^.*[\\\/]/, "");
                      this.Files.fileName = fileDataWithExtension;
                      this.Files.fileSource = fileDataList[j];
                      if (this.subscriptionPackageCode == 4) {
                        this.Files.fileAccess = j == 0 ? "" : "none";
                      }
                      this.athleticVideoList.push(this.Files);
                    }
                    this.isathleteVideosFound = true;
                  } else this.isathleteVideosFound = false;
                } else {
                  this.isathleteRecordsFound = false;
                  this.isathleteVideosFound = false;
                  this.isathleteDocumentFound = false;
                  this.athleticAccomplishment.year = "";
                  this.athleticAccomplishment.sports_focus_code = 0;
                  this.athleticAccomplishment.team = "";
                  this.athleticAccomplishment.coach = "";
                  this.athleticAccomplishment.accomplishments = "";
                  this.athleticAccomplishment.reference_email = "";
                }
              }
              if (
                this.athleteYear.getFullYear().toString() !=
                new Date().getFullYear().toString()
              ) {
                if (resultData[3].length > 0) {
                  var team = resultData[3];
                  this.teamList = team;
                } else {
                  this.teamList = [];
                }
                setTimeout(() => {
                  this.team_name = this.athleticAccomplishment.team;
                }, 10);
              } else {
                var teamstore = localStorage.getItem("team") || "";
                var result = JSON.parse(teamstore);
                this.teamList = [];
                if (result != null) {
                  this.teamList = result;
                }
                if (this.team_name != null) {
                  if (this.team_name == result[0]) {
                    setTimeout(() => {
                      this.team_name = result[0];
                    }, 10);
                  } else if (this.team_name == result[1]) {
                    setTimeout(() => {
                      this.team_name = result[1];
                    }, 10);
                  }
                }
              }
              if (this.isImageLoading) {
                setTimeout(() => {
                  this.imageLoader = "";
                }, 10000);
                this.isImageLoading = false;
              } else {
                this.imageLoader = "";
                this.isImageLoading = true;
              }

              if (
                this.athleteYear.getFullYear().toString() !=
                new Date().getFullYear().toString()
              ) {
                if (
                  resultData[3] != undefined &&
                  resultData[3] != null &&
                  resultData[3].length > 0
                ) {
                  var teamData = resultData[3];
                  this.teamList = teamData;
                  //  this.teamList.push("All");
                  this.teamList.sort();
                } else {
                  this.teamList = [];
                }
              } else {
                var teamstoreData = localStorage.getItem("team") || "";
                var resultDetail = JSON.parse(teamstoreData);
                this.teamList = [];
                if (resultDetail != null) {
                  this.teamList = resultDetail;
                  //this.teamList.push("All");
                  this.teamList.sort();
                }
                if (this.team_name != null) {
                  if (this.team_name == resultDetail[0]) {
                    setTimeout(() => {
                      this.team_name = resultDetail[0];
                    }, 10);
                  } else if (this.team_name == resultDetail[1]) {
                    setTimeout(() => {
                      this.team_name = resultDetail[1];
                    }, 10);
                  } else if (this.team_name == resultDetail[2]) {
                    setTimeout(() => {
                      this.team_name = resultDetail[2];
                    }, 10);
                  }
                }
              }
              this.isMoveAthlete = false;
            },
            (error) => {
              this.spinner.hide();
              if (error.status == 401) {
                localStorage.removeItem("accountId");
                localStorage.removeItem("accounIdStore");
                localStorage.removeItem("storedURL");
                this.accountService.logout();
                this.router.navigate(["/login"]);
              } else {
                this.alertService.showAlertWithOkBtn(
                  "Alert",
                  error.statusText != "" && error.statusText != null
                    ? error.statusText
                    : "Unknown Error"
                );
              }
            }
          );
      }
      if (this.subscriptionPackageCode == 4) {
        this.statsTab.selectedItem = 1;
        setTimeout(() => {
          var element = document.getElementsByClassName("nav-header");
          if (element != undefined && element != null) {
            for (var i = 0; i < element.length; i++) {
              element[i].scrollIntoView();
            }
          }
        }, 50);
      } else {
        this.statsTab.selectedItem = 0;
        setTimeout(() => {
          var element = document.getElementsByClassName("nav-header");
          if (element != undefined && element != null) {
            for (var i = 0; i < element.length; i++) {
              element[i].scrollIntoView();
            }
          }
        }, 50);
      }
      this.LoadStatsFilters(athleteId);
      this.getHighlightUploadVideos();
    } catch (e) { }
  }

  LoadAthleteDetailsByAthleteId(athleteId: any) {
    this.isMoveAthlete = true;
    try {
      if (athleteId != null && athleteId != undefined) {
        this.PositionName = "";
        this.httpProvider.GetAthleteDetailsByAthleteId(athleteId).subscribe(
          (data) => {
            if (data != null && data.body != null) {
              this.profileImageSource = "";
              var resultData = data.body;
              if (resultData != null) {
                if (resultData[0] != null) {
                  this.heightWeight = resultData[0];
                  if (this.heightWeight.height_feet != null && this.heightWeight.height_feet != undefined && this.heightWeight.height_feet != 0) {
                    this.heightWeight.height_feet = (this.heightWeight.height_feet + "' " + this.heightWeight.height_inches + '"') as any;
                  } else {
                    if (this.isMobileView) {
                      this.heightWeight.height_feet = "-" as any;
                      this.heightWeight.height_inches = null as any;

                    } else {
                      this.heightWeight.height_feet = null as any;
                      this.heightWeight.height_inches = null as any;
                    }
                  }
                  if (this.heightWeight.weight_pounds != null && this.heightWeight.weight_pounds != undefined && this.heightWeight.weight_pounds != 0) {
                    this.heightWeight.weight_pounds = this.heightWeight.weight_pounds + " lbs" as any;
                  } else {
                    if (this.isMobileView) {
                      this.heightWeight.weight_pounds = "-" as any;
                    } else {
                      this.heightWeight.weight_pounds = null as any;
                    }
                  }
                } else {
                  this.heightWeight.entry_date = new Date();
                  this.heightWeight.height_feet = null as any;
                  this.heightWeight.height_inches = null as any;
                  this.heightWeight.weight_pounds = null as any;
                  this.heightWeight.height = "";
                  this.heightWeight.weight = "";
                }
                if (resultData[1] != null) {
                  localStorage.setItem(
                    "sports_code",
                    resultData[1].league1_sports_focus_code
                  );
                  this.bbSbDetails = resultData[1];
                  this.teamList = [];
                  if (
                    this.bbSbDetails.league1_name != null ||
                    this.bbSbDetails.league1_name != ""
                  ) {
                    this.teamList.push(this.bbSbDetails.league1_name);
                  }
                  if (
                    this.bbSbDetails.league2_name != null &&
                    this.bbSbDetails.league2_name != ""
                  ) {
                    this.teamList.push(this.bbSbDetails.league2_name);
                  }
                  if ((this.bbSbDetails.league2_name == null || this.bbSbDetails.league2_name == undefined || this.bbSbDetails.league2_name.trim() == "") && this.isMobileView) {
                    this.bbSbDetails.league2_name = "-";
                  }
                  if (this.bbSbDetails.grade_level == null || this.bbSbDetails.grade_level == undefined || this.bbSbDetails.grade_level.trim() == "" && this.isMobileView) {
                    this.bbSbDetails.grade_level = "-";
                  }
                  // if (this.bbSbDetails.league1_name != null)
                  //   this.team_name = this.bbSbDetails.league1_name;

                  // localStorage.setItem("teamNameForProfile", this.team_name);

                  localStorage.setItem("team", JSON.stringify(this.teamList));
                  switch (this.bbSbDetails.bats) {
                    case "L":
                      this.bbSbDetails.bats = "Left";
                      break;
                    case "R":
                      this.bbSbDetails.bats = "Right";
                      break;
                    case "B":
                      this.bbSbDetails.bats = "Both";
                      break;
                  }
                  if (this.bbSbDetails.bats == null || this.bbSbDetails.bats == undefined || this.bbSbDetails.bats.trim() == "" && this.isMobileView) {
                    this.bbSbDetails.bats = "-";
                  }

                  // if (this.bbSbDetails.league1PositionName == "Pitcher" || this.bbSbDetails.league2PositionName == "Pitcher") {
                  //   this.tableHeight = '360px';
                  //   this.isPitcher = true;
                  // }
                  // else {
                  //   this.isPitcher = false;
                  //   this.tableHeight = '250px';
                  // }
                  if (this.bbSbDetails.league1PositionName != null) {
                    this.PositionName += this.bbSbDetails.league1PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league1PositionShortName != null ? this.bbSbDetails.league1PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league2PositionName != null) {
                    this.PositionName += this.bbSbDetails.league2PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league2PositionShortName != null ? this.bbSbDetails.league2PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league3PositionName != null) {
                    this.PositionName += this.bbSbDetails.league3PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league3PositionShortName != null ? this.bbSbDetails.league3PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league4PositionName != null) {
                    this.PositionName += this.bbSbDetails.league4PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league4PositionShortName != null ? this.bbSbDetails.league4PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league5PositionName != null) {
                    this.PositionName += this.bbSbDetails.league5PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league5PositionShortName != null ? this.bbSbDetails.league5PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league6PositionName != null) {
                    this.PositionName += this.bbSbDetails.league6PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league6PositionShortName != null ? this.bbSbDetails.league6PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league7PositionName != null) {
                    this.PositionName += this.bbSbDetails.league7PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league7PositionShortName != null ? this.bbSbDetails.league7PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league8PositionName != null) {
                    this.PositionName += this.bbSbDetails.league8PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league8PositionShortName != null ? this.bbSbDetails.league8PositionShortName.trim() + ', ' : "";
                  }
                  if (this.bbSbDetails.league9PositionName != null) {
                    this.PositionName += this.bbSbDetails.league9PositionName + " | ";
                    this.PositionNameForStats += this.bbSbDetails.league9PositionShortName != null ? this.bbSbDetails.league9PositionShortName.trim() + ', ' : "";
                  }
                  this.PositionName = this.PositionName.trim();
                  if (this.PositionName.endsWith('|')) {
                    this.PositionName = this.PositionName.slice(0, -1);
                  }
                  this.PositionNameForStats = this.PositionNameForStats.trim();
                  if (this.PositionNameForStats.endsWith(',')) {
                    this.PositionNameForStats = this.PositionNameForStats.slice(0, -1);
                  }
                  if (this.PositionName == null || this.PositionName == undefined || this.PositionName.trim() == "" && this.isMobileView) {
                    this.PositionName = "-" as any;
                  }

                  switch (this.bbSbDetails.throws) {
                    case "L":
                      this.bbSbDetails.throws = "Left";
                      break;
                    case "R":
                      this.bbSbDetails.throws = "Right";
                      break;
                    case "B":
                      this.bbSbDetails.throws = "Both";
                      break;
                  }
                  if (this.bbSbDetails.throws == null || this.bbSbDetails.throws == undefined || this.bbSbDetails.throws.trim() == "" && this.isMobileView) {
                    this.bbSbDetails.throws = "-";
                  }
                } else {
                  this.bbSbDetails.athlete_id = 0;
                  this.bbSbDetails.bats = "";
                  this.bbSbDetails.throws = "";
                  this.bbSbDetails.league1_name = "";
                  this.bbSbDetails.league2_name = "";
                  this.bbSbDetails.grade_level = "";
                  this.bbSbDetails.affiliate_id = 0;
                  this.bbSbDetails.name = "";
                }
                if (resultData[3] != null) {
                  this.profileImageSource =
                    resultData[3] + "?" + new Date().getTime();
                  localStorage.setItem("imageUrl", this.profileImageSource);
                  if (this.isProfileImageChanged) {
                    this.spinner.show("Loading profile image...");
                  }
                } else {
                  this.profileImageSource = "assets/imgs/Athlete Image.png";
                  localStorage.setItem("imageUrl", "");
                }
                if (resultData[4] != null && resultData[4] != 0) {
                  this.subscriptionPackageCode = resultData[4];
                  if (this.subscriptionPackageCode == 4) {
                    this.statsTab.selectedItem = 1;
                  } else {
                    this.statsTab.selectedItem = 0;
                  }
                } else if (resultData[4] == 0) {
                  this.router.navigate(["/package"]);
                } else this.subscriptionPackageCode = 0;
                this.hideImage = true;
              }
            }
            //Share profile details for athlete
            this.athleteDetails.athlete_id = resultData[2].athlete_id;
            this.athleteDetails.bats = this.bbSbDetails.bats;
            this.athleteDetails.grade = resultData[1].grade_level;
            this.athleteDetails.image = resultData[3];
            this.athleteDetails.league1_team = resultData[1].league1_name;
            this.athleteDetails.league2_team = resultData[1].league2_name;
            this.athleteDetails.positions = this.PositionNameForStats;
            this.athleteDetails.school = resultData[2].school_name;
            this.athleteDetails.throws = this.bbSbDetails.throws;
            this.athleteDetails.training_center =
              this.bbSbDetails.TrainingCenter;
            this.athleteDetails.name =
              resultData[2].first_name + " " + resultData[2].last_name;
            this.athleteDetails.age = this.birthDate;
            this.athleteDetails.subscriptionPackageCode =
              this.subscriptionPackageCode;

            if (this.enableEdit)
              window.history.pushState("", "", "/profile/edit");
            this.LoadAcademicData(this.athleteProfile.athlete_id);
          },
          (error) => {
            this.spinner.hide();
            if (error.status == 401) {
              localStorage.removeItem("accountId");
              localStorage.removeItem("accounIdStore");
              localStorage.removeItem("storedURL");
              this.accountService.logout();
              this.router.navigate(["/login"]);
            } else {
              this.alertService.showAlertWithOkBtn("Error", error.statusText);
            }
          }
        );
      }
    } catch (e) { }
  }

  LoadStatsFilters(athleteId: any) {
    this.httpProvider.GetStatsLockerFilters(athleteId).subscribe(
      (data) => {
        if (data != null) {
          var resultData = data.body;
          if (resultData != null && resultData.length > 0) {
            if (resultData[0] != null && resultData[0].length > 0) {
              this.teamFilterList = [];
              resultData[0].forEach((element: any) => {
                this.teamFilterList.push(element);
              });
              this.teamFilterList.push("All");
              this.teamFilterList.sort();
              this.teamFilterValue = "All";
            }

            if (resultData[1] != null && resultData[1].length > 0) {
              this.opponentFilterList = [];
              resultData[1].forEach((element: any) => {
                this.opponentFilterList.push(element);
              });
              this.opponentFilterList.push("All");
              this.opponentFilterList.sort();
              this.opponentFilterValue = "All";
            }
            this.GetAthleteDetailedStats();
          }
        }
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 401) {
          this.accountService.logout();
        }
      }
    );
  }

  GetAthleteDetailedStats() {
    if (
      this.teamFilterValue != null &&
      this.teamFilterValue != "" &&
      this.opponentFilterValue != null &&
      this.opponentFilterValue != "" &&
      this.leagueFilterValue != null &&
      this.leagueFilterValue != "" &&
      this.pitcherFilterValue != null &&
      this.pitcherFilterValue != ""
    ) {
      var team = "",
        opponent = "",
        league = "",
        rlValue = "",
        startDate = "",
        endDate = "";
      team = this.teamFilterValue == "All" ? "*" : this.teamFilterValue;
      opponent =
        this.opponentFilterValue == "All" ? "*" : this.opponentFilterValue;
      league = this.leagueFilterValue == "All" ? "*" : this.leagueFilterValue;
      rlValue =
        this.pitcherFilterValue == "All"
          ? "*"
          : this.pitcherFilterValue == "Versus RHP"
            ? "R"
            : "L";
      startDate =
        this.gameDayDateRange != undefined
          ? this.gameDayDateRange.startDate.getFullYear().toString() +
          "-" +
          (this.gameDayDateRange.startDate.getMonth() + 1).toString() +
          "-" +
          this.gameDayDateRange.startDate.getDate().toString()
          : this.minDate.getFullYear().toString() + "-09-01";
      endDate =
        this.gameDayDateRange != undefined
          ? this.gameDayDateRange.endDate.getFullYear().toString() +
          "-" +
          (this.gameDayDateRange.endDate.getMonth() + 1).toString() +
          "-" +
          this.gameDayDateRange.endDate.getDate().toString()
          : this.maxDate.getFullYear().toString() + "-08-31";

      this.httpProvider
        .GetDetailedStats(
          this.athleteProfile.athlete_id,
          team,
          opponent,
          startDate,
          endDate,
          league,
          rlValue
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data != null) {
              var resultData = data.body;
              if (resultData != null && resultData.length > 0) {
                this.detailedStats.Batting_Average =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Batting_Average != null &&
                      resultData[0].Batting_Average != ""
                      ? resultData[0].Batting_Average
                      : "0.000"
                    : "NA";
                this.detailedStats.On_Base_Percentage =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].On_Base_Percentage != null &&
                      resultData[0].On_Base_Percentage != ""
                      ? resultData[0].On_Base_Percentage
                      : "0.000"
                    : "NA";
                this.detailedStats.Slugging_Percentage =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Slugging_Percentage != null &&
                      resultData[0].Slugging_Percentage != ""
                      ? resultData[0].Slugging_Percentage
                      : "0.000"
                    : "NA";
                this.detailedStats.Plate_Appearance =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Plate_Appearance != null &&
                      resultData[0].Plate_Appearance != ""
                      ? resultData[0].Plate_Appearance
                      : "0"
                    : "NA";
                this.detailedStats.Games_Played =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Games_Played != null &&
                      resultData[0].Games_Played != ""
                      ? resultData[0].Games_Played
                      : "0"
                    : "NA";
                this.detailedStats.At_Bat =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].At_Bat != null && resultData[0].At_Bat != ""
                      ? resultData[0].At_Bat
                      : "0"
                    : "NA";
                this.detailedStats.On_Base_Plus_Slugging =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].On_Base_Plus_Slugging != null &&
                      resultData[0].On_Base_Plus_Slugging != ""
                      ? resultData[0].On_Base_Plus_Slugging
                      : "0.000"
                    : "NA";
                this.detailedStats.Put_in_Play =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Put_in_Play != null &&
                      resultData[0].Put_in_Play != ""
                      ? resultData[0].Put_in_Play
                      : "0.000"
                    : "NA";
                this.detailedStats.RBI =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].RBI != null && resultData[0].RBI != ""
                      ? resultData[0].RBI
                      : "0"
                    : "NA";
                this.detailedStats.Runs =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Runs != null && resultData[0].Runs != ""
                      ? resultData[0].Runs
                      : "0"
                    : "NA";
                this.detailedStats.SB =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].SB != null && resultData[0].SB != ""
                      ? resultData[0].SB
                      : "0"
                    : "NA";
                this.detailedStats.First_Base =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].First_Base != null &&
                      resultData[0].First_Base != ""
                      ? resultData[0].First_Base
                      : "0"
                    : "NA";
                this.detailedStats.Second_Base =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Second_Base != null &&
                      resultData[0].Second_Base != ""
                      ? resultData[0].Second_Base
                      : "0"
                    : "NA";
                this.detailedStats.Third_Base =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Third_Base != null &&
                      resultData[0].Third_Base != ""
                      ? resultData[0].Third_Base
                      : "0"
                    : "NA";
                this.detailedStats.Home_Run =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].Home_Run != null &&
                      resultData[0].Home_Run != ""
                      ? resultData[0].Home_Run
                      : "0"
                    : "NA";
                this.detailedStats.BB =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].BB != null && resultData[0].BB != ""
                      ? resultData[0].BB
                      : "0"
                    : "NA";
                this.detailedStats.SO =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].SO != null && resultData[0].SO != ""
                      ? resultData[0].SO
                      : "0"
                    : "NA";
                this.detailedStats.SAC =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].SAC != null && resultData[0].SAC != ""
                      ? resultData[0].SAC
                      : "0"
                    : "NA";
                this.detailedStats.FC =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].FC != null && resultData[0].FC != ""
                      ? resultData[0].FC
                      : "0"
                    : "NA";
                this.detailedStats.ROE =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].ROE != null && resultData[0].ROE != ""
                      ? resultData[0].ROE
                      : "0"
                    : "NA";
                this.detailedStats.OUT =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].OUT != null && resultData[0].OUT != ""
                      ? resultData[0].OUT
                      : "0"
                    : "NA";
                this.detailedStats.HBP =
                  resultData[2] != undefined &&
                    resultData[2] != null &&
                    resultData[2] == true
                    ? resultData[0].HBP != null && resultData[0].HBP != ""
                      ? resultData[0].HBP
                      : "0"
                    : "NA";
                this.detailedStats.Rank =
                  resultData[1] != null &&
                    resultData[1] != "" &&
                    resultData[1] != "0" &&
                    resultData[1] != ""
                    ? resultData[1]
                    : "NA";
                if (
                  resultData[3] != undefined &&
                  resultData[3] != null &&
                  resultData[3].length > 0
                ) {
                  this.sprayChartData = [];
                  resultData[3].forEach((element: any, index: any) => {
                    var sprayData = {
                      x: null,
                      y: null,
                      ab_result: null,
                      fill: null as string | null,
                    };
                    sprayData.x = element.x_coordinate;
                    sprayData.y = element.y_coordinate;
                    sprayData.ab_result = element.ab_result.trim();
                    sprayData.fill = this.renderPointColor(
                      element.ab_result.trim()
                    );
                    this.sprayChartData.push(sprayData);
                  });
                } else {
                  this.sprayChartData = [];
                }
              } else {
                this.UnassignStats();
              }

              //share profile details for athlete
              this.athleteDetails.AB = this.detailedStats.At_Bat;
              this.athleteDetails.BA = this.detailedStats.Batting_Average;
              this.athleteDetails.OBP = this.detailedStats.On_Base_Percentage;
              this.athleteDetails.SLG = this.detailedStats.Slugging_Percentage;
              this.athleteDetails.RBI = this.detailedStats.RBI;
              this.athleteDetails.sprayChartData = this.sprayChartData;
              localStorage.setItem(
                "athleteDetailsForShare",
                JSON.stringify(this.athleteDetails)
              );
            } else {
              this.UnassignStats();
            }
          },
          (error) => {
            this.spinner.hide();
            this.alertService.showAlertWithOkBtn(
              "Alert",
              "Something went wrong"
            );

            if (error.status == 401) {
              this.accountService.logout();
            }
          }
        );
    } else {
      this.UnassignStats();
    }
    if (this.subscriptionPackageCode == 4) {
      this.statsTab.selectedItem = 1;
      setTimeout(() => {
        var element = document.getElementById('profileMainpage')
        if (element != undefined && element != null) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 50);
    }
    else {
      this.statsTab.selectedItem = 0;
      setTimeout(() => {
        var element = document.getElementById('profileMainpage')
        if (element != undefined && element != null) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 50);
    }
  }

  UnassignStats() {
    this.detailedStats.Batting_Average = "NA";
    this.detailedStats.On_Base_Percentage = "NA";
    this.detailedStats.Slugging_Percentage = "NA";
    this.detailedStats.Plate_Appearance = "NA";
    this.detailedStats.Games_Played = "NA";
    this.detailedStats.At_Bat = "NA";
    this.detailedStats.On_Base_Plus_Slugging = "NA";
    this.detailedStats.Put_in_Play = "NA";
    this.detailedStats.RBI = "NA";
    this.detailedStats.Runs = "NA";
    this.detailedStats.SB = "NA";
    this.detailedStats.First_Base = "NA";
    this.detailedStats.Second_Base = "NA";
    this.detailedStats.Third_Base = "NA";
    this.detailedStats.Home_Run = "NA";
    this.detailedStats.BB = "NA";
    this.detailedStats.SO = "NA";
    this.detailedStats.SAC = "NA";
    this.detailedStats.FC = "NA";
    this.detailedStats.ROE = "NA";
    this.detailedStats.OUT = "NA";
    this.detailedStats.HBP = "NA";
    this.detailedStats.Rank = "NA";
    this.sprayChartData = [];

    //share profile details for athlete
    this.athleteDetails.AB = this.detailedStats.At_Bat;
    this.athleteDetails.BA = this.detailedStats.Batting_Average;
    this.athleteDetails.OBP = this.detailedStats.On_Base_Percentage;
    this.athleteDetails.SLG = this.detailedStats.Slugging_Percentage;
    this.athleteDetails.RBI = this.detailedStats.RBI;
    this.athleteDetails.sprayChartData = this.sprayChartData;
    localStorage.setItem(
      "athleteDetailsForShare",
      JSON.stringify(this.athleteDetails)
    );
  }

  public loaded(args: ILoadedEventArgs): void {
    for (var i = 0; i < this.chart.series.length; i++) {
      var id = document.getElementById("chart-containerSeriesGroup" + i);
      if (id) id.removeAttribute("clip-path");
    }
  }

  public pointRender(args: IPointRenderEventArgs): void {
    let materialColors: string[] = [
      "#00bdae",
      "#404041",
      "#357cd2",
      "#e56590",
      "#f8b883",
      "#70ad47",
      "#dd8abd",
      "#7f84e8",
      "#7bb4eb",
      "#ea7a57",
      "#404041",
      "#00bdae",
    ];
    if (
      args.point.index % 2 === 0 &&
      args.series.drawType === "Column" &&
      args.series.name != "colum2"
    ) {
      args.fill = "#00FF00";
    }

    if (args.series.drawType === "Line" && args.series.name === "abResults") {
      const dataSource = args.series.dataSource as any[]; // Explicitly type dataSource as an array
      args.fill = dataSource[args.point.index].fill; // Access the fill property using the index
    }
  }

  renderPointColor(atBatType: any) {
    let color = null;
    switch (atBatType) {
      case "OUT":
        color = "red";
        break;
      case "FC":
        color = "red";
        break;
      case "1B":
        color = "#000";
        break;
      case "2B":
        color = "blue";
        break;
      case "3B":
        color = "orange";
        break;
      case "HR":
        color = "#fff";
        break;
    }
    return color;
  }

  async UpdateProfileInfo(myEvent: any) {
    let data = [this.athleteProfile.athlete_id, this.profileImageSource];
    let modal = await this.modalCtrl.create({
      component: UpdateAthleteInfoPage,
      componentProps: data,
      cssClass: "update-athlete-model",
    });
    await modal.present();
    (await modal).onDidDismiss().then((e: any) => {
      if (this.isProfileInfoChanged) {
        this.spinner.show("Please Wait");
        this.LoadProfile();
        this.isProfileInfoChanged = false;
      }
    });
  }

  async CalculateBirthDate(birthdate: any) {
    let dob: any = new Date(birthdate);
    let today: any = new Date();
    let age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
    this.birthDate = age;
  }

  PreviousAcademicDocument() {
    this.docpanel.nativeElement.scrollLeft -= 50;
  }

  NextAcademicDocument() {
    this.docpanel.nativeElement.scrollLeft += 50;
  }

  PreviousAthleticVideo() {
    this.videopanel.nativeElement.scrollLeft -= 50;
  }

  NextAthleticVideo() {
    this.videopanel.nativeElement.scrollLeft += 50;
  }

  mouseEnterOnPrevious(profile: any) {
    let index = this.athleteProfileList.indexOf(profile);
    if (index == 0) {
      this.disablePreviousNav = "not-allowed";
      this.noprevpointer = "none";
    } else this.disablePreviousNav = "pointer";
    this.noprevpointer = "";
  }

  mouseEnterOnNext(profile: any) {
    let index = this.athleteProfileList.indexOf(profile);
    if (index == this.athleteProfileList.length - 1) {
      this.disableNextNav = "not-allowed";
      this.nonextpointer = "none";
    } else this.disableNextNav = "pointer";
    this.nonextpointer = "";
  }

  GetAllStates() {
    try {
      this.httpProvider.GetAllStates().subscribe(
        (data) => {
          if (data != null && data.body != null) {
            this.stateList = [];
            var resultData = data.body;
            for (let i = 0; i < resultData.length; i++) {
              resultData[i].state_code = resultData[i].state_code.toString();
            }
            this.stateList = resultData;
            localStorage.setItem("States", JSON.stringify(this.stateList));
          }
          this.GetAllSports();
        },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            localStorage.removeItem("accountId");
            localStorage.removeItem("accounIdStore");
            localStorage.removeItem("storedURL");
            this.accountService.logout();
            this.router.navigate(["/login"]);
          } else {
            this.alertService.showAlertWithOkBtn(
              "Error",
              error.statusText != "" && error.statusText != null
                ? error.statusText
                : "Unknown Error"
            );
          }
        }
      );
    } catch (e) { }
  }

  OnchangeAcademicYear(args: any) {
    try {
      if (args != undefined && args.isInteracted) {
        if (
          this.athleteProfile.athlete_id != null &&
          this.athleteProfile.athlete_id != undefined
        ) {
          let year = new Date(this.academicYear).getFullYear().toString();
          this.spinner.show("Please Wait");
          this.httpProvider
            .GetAcademicAccomplishments(this.athleteProfile.athlete_id, year)
            .subscribe(
              (data) => {
                if (data != null && data.body != null) {
                  var resultData = data.body;
                  if (resultData != null && resultData[0] != null) {
                    this.academicAccomplishment = resultData[0];
                    if (
                      this.academicAccomplishment.gpa % 1 == 0 &&
                      this.academicAccomplishment.gpa != null &&
                      this.academicAccomplishment.gpa != 0
                    ) {
                      this.GPA =
                        this.academicAccomplishment.gpa.toString() + ".0";
                    } else {
                      this.GPA =
                        this.academicAccomplishment.gpa != null
                          ? this.academicAccomplishment.gpa.toString()
                          : "";
                    }
                    this.isacademicRecordsFound = true;
                    if (resultData[1] != null) {
                      this.academicFileList = [];
                      var fileList = resultData[1];
                      for (var i = 0; i < fileList.length; i++) {
                        this.Files = {} as IFiles;
                        var fileWithExtension: string = fileList[i].replace(
                          /^.*[\\\/]/,
                          ""
                        );
                        this.Files.fileName = fileWithExtension;
                        this.Files.fileSource = fileList[i]; // fileList[i];
                        this.academicFileList.push(this.Files);
                      }
                      this.isacademicDocumentFound = true;
                    } else this.isacademicDocumentFound = false;
                  } else {
                    this.isacademicRecordsFound = false;
                    this.isacademicDocumentFound = false;
                    this.academicAccomplishment.year = '';
                    this.academicAccomplishment.high_school = '';
                    this.academicAccomplishment.high_school_city = '';
                    this.academicAccomplishment.high_school_state = '';
                    this.academicAccomplishment.grade_level = '';
                    this.academicAccomplishment.gpa = 0;
                    this.academicAccomplishment.class_rank = 0;
                    this.academicAccomplishment.class_size = 0;
                    this.academicAccomplishment.act_score = 0;
                    this.academicAccomplishment.sat_score = 0;
                    this.academicAccomplishment.awards = '';
                    this.academicAccomplishment.created_date = '';
                    this.academicAccomplishment.modified_date = '';
                    this.academicAccomplishment.created_by_account_id =
                      0;
                    this.academicAccomplishment.modified_by_account_id =
                      0;
                    this.academicAccomplishment.is_active = "";
                    this.academicAccomplishment.is_deleted = "";
                  }
                }
                this.spinner.hide();
              },
              (error) => {
                this.spinner.hide();
                if (error.status == 401) {
                  this.accountService.logout();
                  this.router.navigate(["/login"]);
                  //
                } else {
                  this.alertService.showAlertWithOkBtn(
                    "Error",
                    error.statusText != "" && error.statusText != null
                      ? error.statusText
                      : "Unknown Error"
                  );
                }
              }
            );
          //  }
        }
      }
    } catch (e) { }
  }

  OnchangeAthleteYear(args: any) {
    try {
      if (args != undefined && args.isInteracted) {
        if (
          this.athleteProfile.athlete_id != null &&
          this.athleteProfile.athlete_id != undefined
        ) {
          this.team_name =
            args.element.localName != undefined &&
              args.element.localName == "ejs-datepicker"
              ? ""
              : this.team_name;
          let year = new Date(this.academicYear).getFullYear().toString();
          this.spinner.show("Please wait...");
          this.httpProvider
            .GetAthleteAccomplishments(
              this.athleteProfile.athlete_id,
              year,
              this.sportsfocuscode,
              this.team_name
            )
            .subscribe(
              (data) => {
                if (data != null && data.body != null) {
                  var resultData = data.body;
                  if (resultData != null && resultData[0] != null) {
                    this.athleticAccomplishment = resultData[0];
                    this.team_name = this.athleticAccomplishment.team;
                    this.isathleteRecordsFound = true;
                    if (resultData[1] != null) {
                      this.athleticDocList = [];
                      var docList = resultData[1];
                      for (var i = 0; i < docList.length; i++) {
                        this.Files = {} as IFiles;
                        var fileWithExtension: string = docList[i].replace(
                          /^.*[\\\/]/,
                          ""
                        );
                        this.Files.fileName = fileWithExtension;
                        this.Files.fileSource = docList[i]; // fileList[i];
                        this.athleticDocList.push(this.Files);
                      }
                      this.isathleteDocumentFound = true;
                    } else this.isathleteDocumentFound = false;

                    if (resultData[2] != null) {
                      this.athleticVideoList = [];
                      var videoList = resultData[2];
                      for (var j = 0; j < videoList.length; j++) {
                        this.Files = {} as IFiles;
                        var fileDataWithExtension: string = videoList[
                          j
                        ].replace(/^.*[\\\/]/, "");
                        this.Files.fileName = fileDataWithExtension;
                        this.Files.fileSource = videoList[j];
                        if (this.subscriptionPackageCode == 4) {
                          this.Files.fileAccess = j == 0 ? "" : "none";
                        } // fileList[i];
                        this.athleticVideoList.push(this.Files);
                      }
                      this.isathleteVideosFound = true;
                    } else this.isathleteVideosFound = false;
                  } else {
                    this.isathleteRecordsFound = false;
                    this.isathleteDocumentFound = false;
                    this.isathleteVideosFound = false;
                    this.athleticAccomplishment.sports_focus_code = 0;
                    this.athleticAccomplishment.team = "";
                    this.athleticAccomplishment.coach = "";
                    this.athleticAccomplishment.accomplishments = "";
                    this.athleticAccomplishment.reference_email = "";
                  }
                  if (
                    this.athleteYear != null &&
                    this.athleteYear.getFullYear().toString() !=
                    new Date().getFullYear().toString()
                  ) {
                    if (
                      resultData[3] != undefined &&
                      resultData[3] != null &&
                      resultData[3].length > 0
                    ) {
                      var team = resultData[3];
                      this.teamList = team;
                      this.teamList.sort();
                    } else {
                      this.teamList = [];
                    }
                  } else {
                    var teamstore = localStorage.getItem("team") || '';
                    var result = JSON.parse(teamstore);
                    this.teamList = [];
                    if (result != null) {
                      this.teamList = result;
                      this.teamList.sort();
                    }
                    if (this.team_name != null) {
                      if (this.team_name == result[0]) {
                        setTimeout(() => {
                          this.team_name = result[0];
                        }, 10);
                      } else if (this.team_name == result[1]) {
                        setTimeout(() => {
                          this.team_name = result[1];
                        }, 10);
                      } else if (this.team_name == result[2]) {
                        setTimeout(() => {
                          this.team_name = result[2];
                        }, 10);
                      }
                    } else {
                      //  this.team_name = "All";
                    }
                  }
                }
                this.spinner.hide();
              },
              (error) => {
                this.spinner.hide();
                if (error.status == 401) {
                  localStorage.removeItem("accountId");
                  localStorage.removeItem("accounIdStore");
                  localStorage.removeItem("storedURL");
                  this.accountService.logout();
                  this.router.navigate(["/login"]);
                } else {
                  this.alertService.showAlertWithOkBtn(
                    "Error",
                    error.statusText != "" && error.statusText != null
                      ? error.statusText
                      : "Unknown Error"
                  );
                }
              }
            );
          //}
        }
      }
    } catch (e) { }
  }

  offenseClick() {
    this.buttonOffenseActiveClass = "buttonsActiveStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.isOffenseClicked = true;
    this.isDefenseClicked = false;
    this.isTrainingClicked = false;
    this.isWorksClicked = false;
  }

  defenseClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsActiveStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.isOffenseClicked = false;
    this.isDefenseClicked = true;
    this.isTrainingClicked = false;
    this.isWorksClicked = false;
  }

  trainingClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsActiveStyles";
    this.buttonWorkActiveClass = "buttonsStyles";
    this.isOffenseClicked = false;
    this.isDefenseClicked = false;
    this.isTrainingClicked = true;
    this.isWorksClicked = false;
  }

  workClick() {
    this.buttonOffenseActiveClass = "buttonsStyles";
    this.buttonDefenseActiveClass = "buttonsStyles";
    this.buttonTrainingActiveClass = "buttonsStyles";
    this.buttonWorkActiveClass = "buttonsActiveStyles";
    this.isOffenseClicked = false;
    this.isDefenseClicked = false;
    this.isTrainingClicked = false;
    this.isWorksClicked = true;
  }

  downloadFile(sourceUrl: any) {
    window.open(sourceUrl);
  }

  getHighlightUploadVideos() {
    try {
      if (
        this.athleteProfile.athlete_id != null &&
        this.athleteProfile.athlete_id != undefined
      ) {
        this.httpProvider
          .GetHighlightUploadVideos(this.athleteProfile.athlete_id)
          .subscribe((data) => {
            this.spinner.hide();
            if (data != null && data.body != null) {
              var resultData = data.body;
              this.offenseVideoLockerList = [];
              this.defenseVideoLockerList = [];
              this.trainingVideoLockerList = [];
              this.worksVideoLockerList = [];
              var offenseDataList = resultData[0].offenseList;
              //   console.log(offenseDataList);
              var defenseDataList = resultData[0].defenseList;
              var trainingDataList = resultData[0].trainingList;
              var worksDataList = resultData[0].worksList;
              let isIEOrEdge = /msie\s|trident\/|edge\//i.test(
                window.navigator.userAgent
              );
              if (offenseDataList != null && offenseDataList.length > 0) {
                this.isOffenseDataFound = true;
                if (this.platform.is("ios") || this.platform.is("android")) {
                  this.offenseVideoContainerHeight = "360px";
                } else {
                  if (offenseDataList.length == 1) {
                    if (isIEOrEdge != true) {
                      this.offenseVideoContainerHeight = "400px";
                    } else {
                      this.offenseVideoContainerHeight = "600px";
                    }
                  } else {
                    if (isIEOrEdge != true) {
                      this.offenseVideoContainerHeight = "400px";
                    } else {
                      this.offenseVideoContainerHeight = "700px";
                    }
                  }
                }
                for (var i = 0; i < offenseDataList.length; i++) {
                  this.HighlightUploadVideoDetails =
                    {} as IHighlightUploadVideoDetails;
                  this.HighlightUploadVideoDetails.athlete_id =
                    offenseDataList[i].athlete_id;
                  this.HighlightUploadVideoDetails.video_id =
                    offenseDataList[i].video_id;
                  this.HighlightUploadVideoDetails.category_code =
                    offenseDataList[i].category_code;
                  this.HighlightUploadVideoDetails.ipost_tv =
                    offenseDataList[i].ipost_tv;
                  this.HighlightUploadVideoDetails.video_title =
                    offenseDataList[i].video_title;
                  this.HighlightUploadVideoDetails.raw_file_name =
                    offenseDataList[i].raw_file_name;
                  this.HighlightUploadVideoDetails.transcoded_file_name =
                    offenseDataList[i].transcoded_file_name;
                  this.HighlightUploadVideoDetails.fileName =
                    offenseDataList[i].raw_file_name;
                  this.HighlightUploadVideoDetails.fileSource =
                    offenseDataList[i].url;
                  this.HighlightUploadVideoDetails.fileDescription =
                    offenseDataList[i].video_title;
                  //this.HighlightUploadVideoDetails.title = offenseDataList[i].title != null ? this.convertDate(new Date(new Date(offenseDataList[i].created_date) + " UTC")) : null // offenseDataList[i].title;
                  this.HighlightUploadVideoDetails.title =
                    offenseDataList[i].title != null
                      ? this.convertDate(
                        new Date(offenseDataList[i].created_date + "Z")
                      )
                      : null; // offenseDataList[i].title;
                  this.HighlightUploadVideoDetails.created_date =
                    offenseDataList[i].created_date;
                  if (i % 2 == 0) {
                    this.HighlightUploadVideoDetails.fileRowColor = "#353535";
                  }
                  this.offenseVideoLockerList.push(
                    this.HighlightUploadVideoDetails
                  );
                }
              } else {
                this.isOffenseDataFound = false;
                this.offenseVideoContainerHeight = "35px";
              }
              if (defenseDataList != null && defenseDataList.length > 0) {
                this.isDefenseDataFound = true;
                if (this.platform.is("ios") || this.platform.is("android")) {
                  this.defenseVideoContainerHeight = "360px";
                } else {
                  if (defenseDataList.length == 1) {
                    if (isIEOrEdge != true) {
                      this.defenseVideoContainerHeight = "400px";
                    } else {
                      this.defenseVideoContainerHeight = "600px";
                    }
                  } else {
                    if (isIEOrEdge != true) {
                      this.defenseVideoContainerHeight = "400px";
                    } else {
                      this.defenseVideoContainerHeight = "700px";
                    }
                  }
                }
                for (var j = 0; j < defenseDataList.length; j++) {
                  this.HighlightUploadVideoDetails =
                    {} as IHighlightUploadVideoDetails;
                  this.HighlightUploadVideoDetails.athlete_id =
                    defenseDataList[j].athlete_id;
                  this.HighlightUploadVideoDetails.video_id =
                    defenseDataList[j].video_id;
                  this.HighlightUploadVideoDetails.category_code =
                    defenseDataList[j].category_code;
                  this.HighlightUploadVideoDetails.ipost_tv =
                    defenseDataList[j].ipost_tv;
                  this.HighlightUploadVideoDetails.video_title =
                    defenseDataList[j].video_title;
                  this.HighlightUploadVideoDetails.raw_file_name =
                    defenseDataList[j].raw_file_name;
                  this.HighlightUploadVideoDetails.transcoded_file_name =
                    defenseDataList[j].transcoded_file_name;
                  this.HighlightUploadVideoDetails.fileName =
                    defenseDataList[j].raw_file_name;
                  this.HighlightUploadVideoDetails.fileSource =
                    defenseDataList[j].url;
                  this.HighlightUploadVideoDetails.fileDescription =
                    defenseDataList[j].video_title;
                  //   this.HighlightUploadVideoDetails.title =  defenseDataList[j].title != null ? this.convertDate(new Date(new Date(defenseDataList[j].created_date) + " UTC")) : null // defenseDataList[j].title;
                  this.HighlightUploadVideoDetails.title =
                    defenseDataList[j].title != null
                      ? this.convertDate(
                        new Date(defenseDataList[j].created_date + "Z")
                      )
                      : null; // defenseDataList[j].title;
                  this.HighlightUploadVideoDetails.created_date =
                    defenseDataList[j].created_date;
                  if (j % 2 == 0) {
                    this.HighlightUploadVideoDetails.fileRowColor = "#353535";
                  }
                  this.defenseVideoLockerList.push(
                    this.HighlightUploadVideoDetails
                  );
                }
              } else {
                this.isDefenseDataFound = false;
                this.defenseVideoContainerHeight = "35px";
              }
              if (trainingDataList != null && trainingDataList.length > 0) {
                this.isTrainingDataFound = true;
                if (this.platform.is("ios") || this.platform.is("android")) {
                  this.trainingVideoContainerHeight = "360px";
                } else {
                  if (trainingDataList.length == 1) {
                    if (isIEOrEdge != true) {
                      this.trainingVideoContainerHeight = "400px";
                    } else {
                      this.trainingVideoContainerHeight = "600px";
                    }
                  } else {
                    if (isIEOrEdge != true) {
                      this.trainingVideoContainerHeight = "400px";
                    } else {
                      this.trainingVideoContainerHeight = "700px";
                    }
                  }
                }
                for (var k = 0; k < trainingDataList.length; k++) {
                  this.HighlightUploadVideoDetails =
                    {} as IHighlightUploadVideoDetails;
                  this.HighlightUploadVideoDetails.athlete_id =
                    trainingDataList[k].athlete_id;
                  this.HighlightUploadVideoDetails.video_id =
                    trainingDataList[k].video_id;
                  this.HighlightUploadVideoDetails.category_code =
                    trainingDataList[k].category_code;
                  this.HighlightUploadVideoDetails.ipost_tv =
                    trainingDataList[k].ipost_tv;
                  this.HighlightUploadVideoDetails.video_title =
                    trainingDataList[k].video_title;
                  this.HighlightUploadVideoDetails.raw_file_name =
                    trainingDataList[k].raw_file_name;
                  this.HighlightUploadVideoDetails.transcoded_file_name =
                    trainingDataList[k].transcoded_file_name;
                  this.HighlightUploadVideoDetails.fileName =
                    trainingDataList[k].raw_file_name;
                  this.HighlightUploadVideoDetails.fileSource =
                    trainingDataList[k].url;
                  this.HighlightUploadVideoDetails.fileDescription =
                    trainingDataList[k].video_title;
                  //this.HighlightUploadVideoDetails.title = trainingDataList[k].title != null ? this.convertDate(new Date(new Date(trainingDataList[k].created_date) + " UTC")) : null ;// trainingDataList[k].title;
                  this.HighlightUploadVideoDetails.title =
                    trainingDataList[k].title != null
                      ? this.convertDate(
                        new Date(trainingDataList[k].created_date + "Z")
                      )
                      : null; // trainingDataList[k].title;
                  this.HighlightUploadVideoDetails.created_date =
                    trainingDataList[k].created_date;
                  if (k % 2 == 0) {
                    this.HighlightUploadVideoDetails.fileRowColor = "#353535";
                  }
                  this.trainingVideoLockerList.push(
                    this.HighlightUploadVideoDetails
                  );
                }
              } else {
                this.isTrainingDataFound = false;
                this.trainingVideoContainerHeight = "35px";
              }
              if (worksDataList != null && worksDataList.length > 0) {
                this.isWorksDataFound = true;
                if (this.platform.is("ios") || this.platform.is("android")) {
                  this.worksVideoContainerHeight = "360px";
                } else {
                  if (worksDataList.length == 1) {
                    if (isIEOrEdge != true) {
                      this.worksVideoContainerHeight = "400px";
                    } else {
                      this.worksVideoContainerHeight = "600px";
                    }
                  } else {
                    if (isIEOrEdge != true) {
                      this.worksVideoContainerHeight = "400px";
                    } else {
                      this.worksVideoContainerHeight = "700px";
                    }
                  }
                }
                for (var l = 0; l < worksDataList.length; l++) {
                  this.HighlightUploadVideoDetails =
                    {} as IHighlightUploadVideoDetails;
                  this.HighlightUploadVideoDetails.athlete_id =
                    worksDataList[l].athlete_id;
                  this.HighlightUploadVideoDetails.video_id =
                    worksDataList[l].video_id;
                  this.HighlightUploadVideoDetails.category_code =
                    worksDataList[l].category_code;
                  this.HighlightUploadVideoDetails.ipost_tv =
                    worksDataList[l].ipost_tv;
                  this.HighlightUploadVideoDetails.video_title =
                    worksDataList[l].video_title;
                  this.HighlightUploadVideoDetails.raw_file_name =
                    worksDataList[l].raw_file_name;
                  this.HighlightUploadVideoDetails.transcoded_file_name =
                    worksDataList[l].transcoded_file_name;
                  this.HighlightUploadVideoDetails.fileName =
                    worksDataList[l].raw_file_name;
                  this.HighlightUploadVideoDetails.fileSource =
                    worksDataList[l].url;
                  this.HighlightUploadVideoDetails.fileDescription =
                    worksDataList[l].video_title;
                  // this.HighlightUploadVideoDetails.title = worksDataList[l].title != null ? this.convertDate(new Date(new Date(worksDataList[l].created_date) + " UTC")) : null ; // worksDataList[l].title;
                  this.HighlightUploadVideoDetails.title =
                    worksDataList[l].title != null
                      ? this.convertDate(
                        new Date(worksDataList[l].created_date + "Z")
                      )
                      : null; // worksDataList[l].title;
                  this.HighlightUploadVideoDetails.created_date =
                    worksDataList[l].created_date;
                  if (l % 2 == 0) {
                    this.HighlightUploadVideoDetails.fileRowColor = "#353535";
                  }
                  this.worksVideoLockerList.push(
                    this.HighlightUploadVideoDetails
                  );
                }
              } else {
                this.isWorksDataFound = false;
                this.worksVideoContainerHeight = "35px";
              }
            }
          },
            (error) => {
              this.spinner.hide();
            });
      }
    } catch (e) { }
  }

  OnChangeSeason(args: any) {
    if (args.isInteracted) {
      if (args.value != null) {
        this.minDate =
          "09/01/" + (+new Date(args.value).getFullYear() - 1).toString();
        this.maxDate = "08/31/" + new Date(args.value).getFullYear();
        this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      } else {
        this.minDate = new Date("09/01/" + new Date().getFullYear().toString());
        this.maxDate = new Date(
          "08/31/" + (new Date().getFullYear() + 1).toString()
        );
        this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
        if (new Date() >= this.minDate && new Date() <= this.maxDate) {
          this.seasonYear = new Date(
            "02/01/" + (new Date().getFullYear() + 1).toString()
          );
        } else {
          this.seasonYear = new Date(
            "02/01/" + new Date().getFullYear().toString()
          );
          this.minDate = new Date(
            "09/01/" + (new Date().getFullYear() - 1).toString()
          );
          this.maxDate = new Date(
            "08/31/" + new Date().getFullYear().toString()
          );
          this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
        }
        this.seasonPickerObj.focusOut();
      }
    }
  }

  ResetFilters() {
    this.minDate = new Date("09/01/" + new Date().getFullYear().toString());
    this.maxDate = new Date(
      "08/31/" + (new Date().getFullYear() + 1).toString()
    );
    this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
    if (new Date() >= this.minDate && new Date() <= this.maxDate) {
      this.seasonYear = new Date(
        "02/01/" + (new Date().getFullYear() + 1).toString()
      );
    } else {
      this.seasonYear = new Date(
        "02/01/" + new Date().getFullYear().toString()
      );
      this.minDate = new Date(
        "09/01/" + (new Date().getFullYear() - 1).toString()
      );
      this.maxDate = new Date("08/31/" + new Date().getFullYear().toString());
      this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
    }
    this.opponentFilterValue = "All";
    this.teamFilterValue = "All";
    this.pitcherFilterValue = "All";
    this.leagueFilterValue = "All";
  }

  updateActive(args: any) {
    //document.getElementById("profile-page").scrollIntoView();
    //document.getElementById("athleteyear_input").focus();
  }

  daterangeChange(args: any) {
    if (args != null && args.isInteracted && args.value == null) {
      this.minDate =
        "09/01/" + (+this.seasonPickerObj.value.getFullYear() - 1).toString();
      this.maxDate =
        "08/31/" + this.seasonPickerObj.value.getFullYear().toString();
      this.gameDay = [new Date(this.minDate), new Date(this.maxDate)];
      this.gameDayDateRange.focusOut();
    }
  }
  openDatePicker(e: any) {
    let select = window.getSelection() as any;
    select.removeAllRanges();

    setTimeout(() => {
      // let popupElement: any = document.getElementsByClassName('e-daterangepicker e-popup e-control e-device e-popup-open')[0]
      // popupElement.style.left = "25px";
    }, 50);
  }
  backButtonAction() {
    window.history.back();
  }

  async openDropdown(args: any, myEvent: any, synced: any) {
    let data = {
      details: args,
      synced: synced,
    };
    let modal = await this.popOverCtrl.create({
      component: ContextMenuPage,
      componentProps: data,
      event: myEvent,
      cssClass: "content-popover",
    });
    await modal.present();
  }

  OnFilterTypeOpen(e: any) {
    for (
      let i = 0;
      i < this.filterTypeObj.list.querySelectorAll("li").length;
      i++
    ) {
      if (
        this.filterTypeObj.list
          .querySelectorAll("li")
        [i].getAttribute("data-value")
          .toString() != "OFFENSE"
      ) {
        this.filterTypeObj.list.querySelectorAll("li")[i].style.opacity = "0.5";
        this.filterTypeObj.list.querySelectorAll("li")[i].style.pointerEvents =
          "none";
      }
    }
  }

  convertDate(date: any) {
    if (date != null) {
      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        monthNames[monthIndex] + "," + " " + day + " " + year + " " + strTime
      );
    }
  }
  async OpenPublicShare() {
    let data = {
      url: this.httpProvider.PublishedURL + "publicShare/",
      encryptedId: this.athleteDetails.athlete_id + "Ip",
      team:
        this.teamFilterValue == null
          ? "*"
          : this.teamFilterValue == "All"
            ? "*"
            : this.teamFilterValue,
      opponent:
        this.opponentFilterValue == null
          ? "*"
          : this.opponentFilterValue == "All"
            ? "*"
            : this.opponentFilterValue,
      league:
        this.leagueFilterValue == null
          ? "*"
          : this.leagueFilterValue == "All"
            ? "*"
            : this.leagueFilterValue,
      rlValue:
        this.pitcherFilterValue == null
          ? "*"
          : this.pitcherFilterValue == "All"
            ? "*"
            : this.pitcherFilterValue == "Versus RHP"
              ? "R"
              : "L",
      startDate:
        this.gameDayDateRange != undefined
          ? this.gameDayDateRange.startDate.getFullYear().toString() +
          "-" +
          (this.gameDayDateRange.startDate.getMonth() + 1).toString() +
          "-" +
          this.gameDayDateRange.startDate.getDate().toString()
          : this.minDate.getFullYear().toString() + "-09-01",
      endDate:
        this.gameDayDateRange != undefined
          ? this.gameDayDateRange.endDate.getFullYear().toString() +
          "-" +
          (this.gameDayDateRange.endDate.getMonth() + 1).toString() +
          "-" +
          this.gameDayDateRange.endDate.getDate().toString()
          : this.maxDate.getFullYear().toString() + "-08-31",
    };
    if (
      this.subscriptionPackageCode != 4 &&
      this.subscriptionPackageCode != null &&
      this.subscriptionPackageCode != undefined
    ) {
      let modal = await this.modalCtrl.create({
        component: ShareInfoModelPage,
        componentProps: data,
        cssClass: "share-info-model",
      });
      await modal.present();

    } else if (this.subscriptionPackageCode == 4) {
      this.httpProvider.GetEncryptedAthleteId(data).subscribe(
        async (athletedata) => {
          if (athletedata != null && athletedata.body != null) {
            var result = athletedata.body;
            let data: any = [result.url, result.encryptedId];
            let modal = await this.modalCtrl.create({
              component: ShareCopyUrlComponent,
              componentProps: data,
              cssClass: "share-info-model",
            });
            await modal.present();
          }
        },
        (error) => { }
      );
    }
  }
  videoBackButton(video: any) {
    this.videoPlayer["player"].controlBar.children_[8].trigger("click");
  }

  openPopover(event: any) {
    if (event != null && event != undefined && event != "" && event.srcElement != undefined && event.srcElement != null && event.srcElement.offsetWidth != undefined && event.srcElement.offsetWidth != null && event.srcElement.offsetWidth != 0) {
      setTimeout(() => {
        let popoverElement: any = document.getElementsByClassName('viewprofile-popover');
        if(popoverElement != null && popoverElement != undefined && popoverElement.length > 0){
        popoverElement[0].style.setProperty('--width', event.srcElement.offsetWidth + 'px');
        }
      }, 50);
    }
  }

  observeScreenSize() {
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Small])
      .subscribe((state) => {
        this.isMobileDevice = state.matches;
        console.log("Mobiledevice:",this.isMobileDevice);
      });
  }

  ionViewDidEnter() {}

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    (this.seasonPickerObj as any).hide();
    (this.gameDayDateRange as any).hide();
  }
}
