import { Component, NgZone, OnInit } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { App } from "@capacitor/app";
import {
  Platform,
  AlertController,
  MenuController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { HttpProvider } from "src/app/providers/http-provider.service";
import { AccountService } from "./providers/account-service.service";
import { NetworkProvider } from "./providers/network.service";
import { IAutoSync, IPublicShare } from "./interfaces/account-interface";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { AlertService } from "./providers/alert-service.service";
import { SpinnerService } from "./providers/spinner-service.service";
import { ToasterService } from "./providers/toaster-service.service";
import { DataSharingService } from "./providers/data-sharing.service";
import { ShareCopyUrlComponent } from "./share-copy-url/share-copy-url.page";
import { ViewProfilePage } from "./view-profile/view-profile.page";

export interface PageInterface {
  title: string;
  name: string;
  component: any;
  icon: string;
  logsOut?: boolean;
  index?: number;
  tabName?: string;
  tabComponent?: any;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  isLogin: boolean = true;
  isCoach: boolean = false;
  activeElement: string = "";

  orgDetail: any = {
    organization_name: "",
    organization_logo: "",
  };

  userDetail: any = {
    FirstName: "",
    LastName: "",
    OrganizationLogo: "",
  };

  organizationList: any;
  RegistrationToken: string = "";

  accountId: any;
  accomplishment: any;
  hidepage: any;
  isPackage: any;
  settingspage: any;
  url: any;
  userName: any;
  sync: boolean = false;
  athleteId: any;
  athleteDetailsForPublicShare: any;
  autoSyncDetails: IAutoSync = {
    id: 0,
    account_id: 0,
    auto_sync: 0,
  };

  publicShare: IPublicShare = {
    url: null,
    encryptedId: null,
    team: null,
    opponent: null,
    league: null,
    rlValue: null,
    startDate: null,
    endDate: null,
  };

  constructor(
    public platform: Platform,
    private router: Router,
    private zone: NgZone,
    private menu: MenuController,
    private httpProvider: HttpProvider,
    private alert: ToasterService,
    private modalCtrl: ModalController,
    private dataSharingService: DataSharingService,
    private accountService: AccountService,
    private network: NetworkProvider,
    public navCtrl: NavController,
    private socialSharing: SocialSharing,
    private alertCtrl: AlertController,
    private spinner: SpinnerService,
    private toaster: ToasterService
  ) {
    this.userName = localStorage.getItem("loggedonuser");
    this.isPackage =
      localStorage.getItem("ispackDisable") != null
        ? JSON.parse(localStorage.getItem("ispackDisable") || "")
        : "";
    this.hidepage =
      localStorage.getItem("hideMenuPage") != null
        ? JSON.parse(localStorage.getItem("hideMenuPage") || "")
        : "";
    this.settingspage =
      localStorage.getItem("accountSettingsPage") != null
        ? JSON.parse(localStorage.getItem("accountSettingsPage") || "")
        : "";
    this.athleteDetailsForPublicShare =
      localStorage.getItem("athleteDetailsForShare") != null
        ? JSON.parse(localStorage.getItem("athleteDetailsForShare") || "")
        : "";

    this.dataSharingService.isSubscribed.subscribe((value) => {
      this.isPackage =
        localStorage.getItem("ispackDisable") != null
          ? JSON.parse(localStorage.getItem("ispackDisable") || "")
          : "";
      this.hidepage =
        localStorage.getItem("hideMenuPage") != null
          ? JSON.parse(localStorage.getItem("hideMenuPage") || "")
          : "";
      this.settingspage =
        localStorage.getItem("accountSettingsPage") != null
          ? JSON.parse(localStorage.getItem("accountSettingsPage") || "")
          : "";
    });

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        if (event.url == "/package") {
          this.activeElement = "Feature";
        } else if (event.url == "/profile/view") {
          this.activeElement = "View";
        } else if (event.url == "/settings") {
          this.activeElement = "Settings";
        } else if (event.url == "/profile/edit") {
          this.activeElement = "Update";
        } else {
          this.activeElement = "";
        }
      }
    });
  }

  async closeAlertIfExist() {
    try {
      const element = await this.alertCtrl.getTop();
      if (element) {
        element.dismiss();
        return;
      }
    } catch (error) {}
  }

  RedirectedToDefaultPage() {
    this.router.navigate(["/"]);
  }

  ngOnInit() {
    var hasLoggedIn = this.accountService.hasLoggedIn();
    var currentUrl = window.location.href;
    if (currentUrl.indexOf("resetpassword") != -1) {
      var resetPasswordkey = currentUrl.split("key=")[1];
      let navigationExtras: NavigationExtras = {
        state: { resetPasswordkey: resetPasswordkey },
      };
      this.router.navigate(["resetpassword"], navigationExtras);
    } else if (currentUrl.indexOf("TAPmailactivation") != -1) {
      var key = currentUrl.split("TAPmailactivation/");
      let navigationExtras: NavigationExtras = {
        state: { key: key },
      };
      this.router.navigate(["TAPmailactivation"], navigationExtras);
    } else if (currentUrl.indexOf("mailactivation") != -1) {
      var activationCode = currentUrl.split("mailactivation/")[1];
      let navigationExtras: NavigationExtras = {
        state: { activationCode: activationCode },
      };
      this.router.navigate(["mailactivation"], navigationExtras);
    } else if (currentUrl.indexOf("publicShare") != -1) {
      var encryptedId = currentUrl.split("publicShare/")[1];
      let navigationExtras: NavigationExtras = {
        state: { encryptedId: encryptedId },
      };
      this.router.navigate(["publicShare"], navigationExtras);
    }
  }

  async openPage(view: string) {
    if (view == "Update") {
      localStorage.setItem("selectedMenu", "Update");
      this.activeElement = view;
      console.log(localStorage.getItem("athleteIdForFS"));

      let data = {
        athelteId: localStorage.getItem("athleteIdForFS"),
        accountId: this.accountService.getAccountId(),
        profileImageSource: "",
        pageName: "view-profile",
        enableEdit: true,
      };
      let navigationExtras: NavigationExtras = {
        state: data,
      };
      this.router.navigate(["profile/edit"], {
        replaceUrl: true,
        ...navigationExtras,
      });
    } else if (view == "View") {
      localStorage.setItem("selectedMenu", "View");
      this.activeElement = view;
      let navigationExtras: NavigationExtras = {
        state: { enableEdit: false },
      };
      this.router.navigate(["profile/view"], {
        replaceUrl: true,
        ...navigationExtras,
      });
    } else if (view == "Feature") {
      localStorage.setItem("selectedMenu", "Feature");
      this.url = window.location.href;
      this.router.navigate(["package"], { replaceUrl: true });
      this.activeElement = view;
    } else if (view == "Home") {
      localStorage.setItem("selectedMenu", "Home");
      this.url = window.location.href;
      this.router.navigate(["features"], { replaceUrl: true });
      this.activeElement = view;
    } else if (view == "Logout") {
      localStorage.setItem("selectedMenu", "");
      let logoutConfirmation = this.alertCtrl.create({
        header: "Alert",
        message: "Do you want to Logout?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              this.modalCtrl.dismiss();
            },
          },
          {
            text: "Ok",
            handler: () => {
              localStorage.removeItem("accountId");
              localStorage.removeItem("accounIdStore");
              localStorage.removeItem("storedURL");
              localStorage.removeItem("AuthToken");
              localStorage.removeItem("SportsFocusCodes");
              localStorage.removeItem("athleteIdForUpdateprofile");
              localStorage.removeItem("password");
              localStorage.removeItem("usernameForAuthorization");
              localStorage.removeItem("athleteNames");
              localStorage.removeItem("athleteIdForFS");
              localStorage.removeItem("athleteNameForFS");
              this.activeElement = "";
              this.accountService.logout();
              this.router.navigate(["login"], { replaceUrl: true });
            },
          },
        ],
      });
      (await logoutConfirmation).present();
    } else if (view == "Settings") {
      localStorage.setItem("selectedMenu", "Settings");
      this.router.navigate(["settings"]);
      this.activeElement = view;
    } else if (view == "fileselector") {
      this.router.navigate(["gallery"]);
      this.activeElement = view;
    } else if (view == "publicShare") {
      this.publicShare = {
        url: this.httpProvider.PublishedURL + "publicShare/",
        encryptedId: this.athleteDetailsForPublicShare.athlete_id + "Ip",
        team: null,
        opponent: null,
        league: null,
        rlValue: null,
        startDate: null,
        endDate: null,
      };
      this.httpProvider.GetEncryptedAthleteId(this.publicShare).subscribe(
        async (athletedata) => {
          if (athletedata != null && athletedata.body != null) {
            var result = athletedata.body;
            let data: any = [result.url, result.encryptedId];
            let modal = await this.modalCtrl.create({
              component: ShareCopyUrlComponent,
              componentProps: data,
              cssClass: "share-info-model",
            });
            await modal.present();
          }
        },
        (error) => {}
      );
    } else if (view == "gametap") {
      var ath_id = this.athleteDetailsForPublicShare.athlete_id;
      // QA
      // const url = "https://qa.ipostsports.net/gametap/?ath_id=" + ath_id;
      // PROD
      const url = "https://gametap.ipostsports.net/?ath_id=" + ath_id;
      window.open(url, "_blank");
    } else if ((view = "Delete")) {
      this.deleteAccount();
    }

    this.menu.close();
  }

  async deleteAccount() {
    const alert = await this.alertCtrl.create({
      message:
        "Once the account is deleted, all data will be permanently deleted. Do you want to proceed?",
      cssClass: "alertMessageStyle",
      buttons: [
        { text: "NO", cssClass: "alertCancel" },
        {
          text: "YES",
          handler: this.deleteAccountProceed.bind(this),
          cssClass: "alertOk",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }

  deleteAccountProceed() {
    this.spinner.show("Please wait...");
    this.httpProvider.deletePlayer().subscribe(
      async (result) => {
        this.spinner.hide();
        if (result != null && result.body != null) {
          var resultData = result.body;
          if (resultData.IsSuccess) {
            this.toaster.showSuccessToast(resultData.Message);
            this.activeElement = "";
            this.accountService.logout();
            this.router.navigate(["login"]);
          }
        }
      },
      async (error: any) => {
        this.spinner.hide();
        if (error != null && error.error != null) {
          var errorValue = error.error;
          if (errorValue != null) {
            this.toaster.showFailureToast(errorValue.Message);
            this.activeElement = "";
            this.accountService.logout();
            this.router.navigate(["login"]);
          }
        }
      }
    );
  }
}
