import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {
  constructor(public modalCtrl: ModalController) {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }
}
