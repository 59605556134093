// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  text-align: center;
  font-size: 11px;
  font-style: italic;
  color: white;
  background: #BE1E2D;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/home-footer/home-footer.component.scss","webpack://./../../../IPostSports%20Website/git/Ipostsports-web/src/app/home-footer/home-footer.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,OAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;ACAJ","sourcesContent":["\n  .footer {\n    text-align: center;\n    font-size: 11px;\n    font-style: italic;\n    color: white;\n    background: #BE1E2D;\n    left: 0;\n    bottom: 0;\n    position: absolute;\n    z-index: 10;\n    display: block;\n    width: 100%;\n  }",".footer {\n  text-align: center;\n  font-size: 11px;\n  font-style: italic;\n  color: white;\n  background: #BE1E2D;\n  left: 0;\n  bottom: 0;\n  position: absolute;\n  z-index: 10;\n  display: block;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
