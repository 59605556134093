import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-commonlayout',
  templateUrl: './commonlayout.component.html',
  styleUrls: ['./commonlayout.component.scss'],
})
export class CommonlayoutComponent implements OnInit {

  isMobile: boolean = false;
  constructor(private platform: Platform, private router: Router) { 
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  ngOnInit() {}

  onHomeIconClick() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  openMenu(){
    const toolbar = document.querySelector('ion-toolbar') as any;
    const shadowRoot = toolbar.shadowRoot || toolbar.attachShadow({ mode: 'open' });
    const toolbarContainer = shadowRoot.querySelector('.toolbar-container');
    toolbarContainer.style.setProperty('box-shadow', 'none'); 
    toolbarContainer.style.border = 'none';
    toolbarContainer.style.padding = '0px';
    toolbarContainer.style.background = 'white';
  }
}
